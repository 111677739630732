import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/base/loadable.page';

import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { Note } from 'src/app/entities/note';
import { NotesService } from 'src/app/services/CRUD/logic-traders/notes.service';
import { Instance } from 'src/app/entities/instance';

export interface CreateNoteComponentData {
  instance: Instance;
  author: string;
}

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
})
export class CreateNoteComponent implements OnInit, LoadableComponent {
  public form: FormGroup;
  public isLoading: boolean = false;
  public instance: Instance;

  constructor(
    public dialogRef: MatDialogRef<CreateNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateNoteComponentData,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    private notes$: NotesService
  ) {}

  ngOnInit() {
    this.instance = this.data.instance;
    this.form = this.formBuilder.group({
      author: [this.data.author, Validators.required],
      message: [null, Validators.required],
    });
  }

  /* istanbul ignore next */
  addInstance() {
    if (this.form.invalid) {
      return false;
    }

    this.setIsLoading(true);
    this.dialogRef.disableClose = true;

    const data: Partial<Note> = {
      message: this.message.value,
      author: this.author.value,
      instance: this.instance['@id'],
    };

    this.notes$.create(data, 'admin').subscribe(
      (resp) => {
        this.snackbar.open('ADDED_NOTE_OK');
        this.close(resp);
      },
      (err) => {
        this.snackbar.open(err.message || err.detail);
        this.setIsLoading(false);
        this.dialogRef.disableClose = false;
      }
    );
  }

  get author() {
    return this.form.get('author');
  }

  get message() {
    return this.form.get('message');
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val?: any) {
    this.dialogRef.close(val);
  }
}
