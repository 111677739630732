import { Currency } from './currency';

export interface Money {
  amount: number;
  created_at: string;
  currency: Currency;
  id: string;
  updated_at: string;
  canonical: string;
  representation: string;
}

export class Money implements Money {
  amount: number;
  created_at: string;
  currency: Currency;
  id: string;
  updated_at: string;
  canonical: string;
  representation: string;

  /**
   *
   * @returns The inverted value
   * @example
   * ```js
   * money1.amount;       // 100
   * money1.toInverted(); // -100
   * ```
   */
  toInverted() {
    const scalar = this.currency.getScalar();
    const scaledAmount = this.amount / scalar;

    // Invert the value
    return -1 * scaledAmount;
  }

  /**
   *
   * @returns The inverted value
   * @example
   * ```js
   * money1.amount;       // 100
   * money1.toInverted(); // -100
   * ```
   */
  static toInverted(currency: Currency, amount: number) {
    const scalar = currency.getScalar();
    const scaledAmount = amount / scalar;

    // Invert the value
    return -1 * scaledAmount;
  }

  /* istanbul ignore next */
  static fromJson(obj: Partial<Money>): Money {
    const money = new Money();
    money.id = obj.id;
    money.amount = Number(obj.amount);

    if (obj.currency instanceof Currency) {
      money.currency = obj.currency;
    } else {
      money.currency = Currency.fromJson(obj.currency);
    }

    money.updated_at = obj.updated_at;
    money.created_at = obj.created_at;
    money.canonical = obj.canonical;
    money.representation = obj.representation;
    return money;
  }
}
