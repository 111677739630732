<mat-card class="no-padding">
    <qbit-table-header title="STRATEGIES" [options]={search:false}> </qbit-table-header>
    <mat-grid-list cols="2" rowHeight="80px">
        <mat-grid-tile *ngFor="let strategy of strategies">
            <h4 class="subtitle" style="margin-top: 0; padding-top: 0;margin-bottom:10px">{{strategy.name}}</h4>
            <p class="text-mid" style="margin-top: 0; padding-top: 0; max-width: 50%">{{strategy.description}}</p>

            <code class="abs-right-center">
                <div class="flex-row center padd-h-10 padd-v-10">
                    <img src="../../../assets/icons/{{strategy.exchange}}.svg">
                    <p class="marg-left-10 no-margin text-mid">{{strategy.exchange}}</p>
                </div>
                <div style="width: 80px">
                    <app-risk-indicator tooltip="Risk" color="warn" [value]="strategy.indicators.risk * 100"></app-risk-indicator>
                    <app-risk-indicator tooltip="Profit" color="accent" [value]="strategy.indicators.profit * 100"></app-risk-indicator>
                </div>
            </code>
        </mat-grid-tile>
    </mat-grid-list>
</mat-card>