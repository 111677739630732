import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, DEFAULT_CONFIG } from '@qbitartifacts/caste-client-ng';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';
import { Currency } from '../entities/currency';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends BaseService {
  constructor(http: HttpClient, private auth: AuthService) {
    super(http, {
      ...DEFAULT_CONFIG,
      url: environment.url,
    });
  }

  protected getToken() {
    return this.auth.session && this.auth.session.token;
  }

  public getAllStatistics() {
    return this.get('/account_statistics');
  }

  public getStatisticsForAccount(id: string) {
    return this.get(`/account_statistics`, { account_id: id });
  }

  public getAdminStatistics() {
    return this.get('/admin/system_statistics');
  }
}
