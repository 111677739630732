/* istanbul ignore file */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import {
  CasteAccountsService,
  CasteAuthConfig,
  CASTE_AUTH_CONFIG,
  DEFAULT_CONFIG,
} from '@qbitartifacts/caste-client-ng';
import { UserType } from '@qbitartifacts/caste-client-ng/lib/types';
import { RequestCacheService } from '@qbitartifacts/qbit-kit-ng';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { AccountDetailsService } from '../CRUD/logic-traders/account_details.service';

const getFirstItemOrEmpty = (res) =>
  res.data && res.data.length ? res.data[0] : {};

@Injectable({
  providedIn: 'root',
})
export class AccountsService extends CasteAccountsService {
  constructor(
    @Inject(CASTE_AUTH_CONFIG) config: CasteAuthConfig,
    http: HttpClient,
    private cache: RequestCacheService,
    private details$: AccountDetailsService
  ) {
    super(
      {
        ...DEFAULT_CONFIG,
        baseHeaders: {
          accept: 'application/ld+json',
        },
        ...config,
      },
      http
    );
  }

  listAll(
    params?: {
      [key: string]: string;
    },
    userType?: UserType
  ) {
    return super.listAll(params, userType).pipe(
      map((resp: any) => {
        resp.data = resp.data.map((el) => {
          el.details = this.details$
            .listAll({ account_id: el.id })
            .pipe(map(getFirstItemOrEmpty));
          return el;
        });
        return resp;
      })
    );
  }

  getOne(id: string, userType: UserType = 'user') {
    const url = `/${userType}/${this.endpoint}/${id}`;
    const cached = this.cache.get(url);

    return cached
      ? of(cached)
      : super.getOne(id, userType).pipe(
          map((resp: any) => {
            resp.details = this.details$
              .listAll({ account_id: resp.id })
              .pipe(map(getFirstItemOrEmpty));
            return resp;
          }),
          tap((event) => {
            if (event instanceof HttpResponse || event['@id']) {
              this.cache.set(url, event);
            }
          })
        );
  }
}
