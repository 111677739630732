import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from 'src/app/entities/order';
import { DialogsService } from 'src/app/services/dialogs.service';
import { OrdersService } from 'src/app/services/CRUD/logic-traders/orders.service';

import { MatTableDataSource } from '@angular/material/table';
import { Currency } from 'src/app/entities/currency';
import { PermissionAdmin } from '@qbitartifacts/caste-client-ng';
import {
  DeleteDialogStatus,
  QSnackBar,
  QTableListHeaderOptions,
} from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
})
export class OrdersListComponent implements OnInit {
  public displayedColumns: string[] = ['amount', 'price', 'updated', 'options'];
  public addPermission = PermissionAdmin;
  public tableOptions: QTableListHeaderOptions = {
    showBreadcrumbs: false,
  };

  @Input() allowAdd = false;
  @Input() orders: Order[] = [];
  @Input() currency: Currency;
  @Input() instance: any;
  @Input() showAddOrder = false;
  @Input() disableActions = false;

  @Output() listChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  toggleOrderForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public dialogs$: DialogsService,
    public orders$: OrdersService,
    public snackbar: QSnackBar
  ) {}

  public dataSource: MatTableDataSource<Order>;

  ngOnInit() {
    this.setData(this.orders);
  }

  setData(data) {
    this.orders = data;
    this.dataSource = new MatTableDataSource(data);
  }

  /* istanbul ignore next */
  public openRemoveConfirmation(id: string) {
    this.dialogs$
      .openConfirmDelete()
      .afterClosed()
      .subscribe((resp) => {
        if (resp === DeleteDialogStatus.DELETE) {
          this.removeItem(id);
        }
      });
  }

  /* istanbul ignore next */
  public removeItem(id: string) {
    this.orders$.remove(id).subscribe(
      (resp) => {
        this.snackbar.open('REMOVED_ITEM');
        this.listChanged.emit({ id, type: 'removed' });
      },
      (error) => {
        this.snackbar.open(error.message || error.detail);
      }
    );
  }

  /* istanbul ignore next */
  public onToggleAddOrder() {
    this.showAddOrder = !this.showAddOrder;
    this.toggleOrderForm.emit(this.showAddOrder);
  }

  /* istanbul ignore next */
  public closeAddOrder() {
    this.showAddOrder = false;
    this.toggleOrderForm.emit(this.showAddOrder);
  }
}
