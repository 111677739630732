<mat-card [class.mat-elevation-z0]="!hasShadow" *ngIf="!isStacked" (mouseenter)="showPercent = true"
    (mouseleave)="showPercent = false">
    <div class="aggregate flex-row space-between" [ngClass]="{'compact': compact, 'default': !compact}">
        <div class="flex-row row center">
            <mat-icon *ngIf="icon" class="mat-icon material-icons mat-icon-small marg-right-10 col-{{iconColor}}">
                {{icon}}
            </mat-icon>
            <span class="aggregate-title col-{{titleColor}} text-mid">{{title|translate}}</span>
        </div>

        <span *ngIf="showPercent" style="font-size: 18px;" class="aggregate-value col-{{valueColor}}" class="mat-button-menu">
            {{(percent || 0) | number }} %</span>

        <span *ngIf="!showPercent && showDecimals" style="font-size: 18px;" class="aggregate-value col-{{valueColor}}"
            class="mat-button-menu">
            <b>{{symbol}}</b> {{(value || 0) | number: decimalPipeOptions}}</span>

        <span *ngIf="!showPercent && !showDecimals" style="font-size: 18px;" class="aggregate-value col-{{valueColor}}"
            class="mat-button-menu">
            <b>{{symbol}}</b> {{(value || 0) | number }}</span>
    </div>
</mat-card>

<mat-card [class.mat-elevation-z0]="!hasShadow" *ngIf="isStacked" (mouseenter)="showPercent = true"
    (mouseleave)="showPercent = false">
    <div class="aggregate stacked" [ngClass]="{'compact': compact, 'default': !compact}">
        <div class="flex-row center">
            <mat-icon *ngIf="icon" class="mat-icon material-icons mat-icon-small marg-right-10 col-{{iconColor}}">
                {{icon}}
            </mat-icon>
            <span class="aggregate-title col-{{titleColor}} text-mid">{{title|translate}}</span>
        </div>

        <span *ngIf="showPercent" style="font-size: 18px;" class="aggregate-value col-{{valueColor}}" class="mat-button-menu">
            {{(percent || 0) | number }} %</span>

        <span *ngIf="!showPercent && showDecimals" style="font-size: 18px;" class="aggregate-value col-{{valueColor}}"
            class="mat-button-menu">
            <b>{{symbol}}</b> {{(value || 0) | number: decimalPipeOptions}}</span>

        <span *ngIf="!showPercent && !showDecimals" style="font-size: 18px;" class="aggregate-value col-{{valueColor}}"
            class="mat-button-menu">
            <b>{{symbol}}</b> {{(value || 0) | number }}</span>
    </div>
</mat-card>