<form class="padd-20" [formGroup]="orderForm" (ngSubmit)="addOrder()">
    <div class="row">
        <div class="col-lg-12">
            <h4 class="title-lg marg-top-10">
                {{'NEW_ORDER' | translate}}
            </h4>
            <p class="text-mutted marg-bottom-15">{{'NEW_ORDER_DESC'| translate}}</p>

            <mat-form-field>
                <mat-label>{{'AMOUNT' | translate}}</mat-label>
                <input type="number" id="amount" matInput placeholder="Enter your amount" formControlName="amount">
                <mat-error *ngIf="amount.invalid && (amount.dirty || amount.touched) && amount.errors.required">
                    {{'AMOUNT_REQUIRED' | translate}}
                </mat-error>
                <mat-error *ngIf="amount.invalid && (amount.dirty || amount.touched) && amount.errors.bitmexAmount">
                    {{'BITMEX_AMOUNT_ERROR' | translate}}
                </mat-error>
            </mat-form-field>

            <div class="row">
                <div class="col-lg-8">
                    <mat-form-field>
                        <mat-label>{{'PRICE' | translate}}</mat-label>
                        <input type="number" id="price" matInput placeholder="Enter your price" formControlName="price">
                    </mat-form-field>
                </div>
                <div class="col-lg-4">
                    <mat-button-toggle-group [ngModel]="priceType" (ngModelChange)="changePriceType($event)"
                        [ngModelOptions]="{standalone: true}" aria-label="Price Type">
                        <mat-button-toggle *ngFor="let type of priceTypes" [value]="type" class="text-small">
                            {{type|translate}}</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>

            <div class="flex-row center v space-between">
                <qbit-loadable-button [id]="'createInstance'" [disabled]="orderForm.invalid || isLoading" [loading]="isLoading">
                    {{(isLoading ? 'ADDING': 'ADD') |translate}}
                </qbit-loadable-button>

                <button mat-button class="marg-top-20 marg-left-10" (click)="close();">{{'CANCEL' | translate}}</button>
            </div>
        </div>
    </div>
</form>