import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/base/loadable.page';

import { BillingService } from 'src/app/services/CRUD/logic-traders/billing.service';
import { BILL_STATUSES } from 'src/app/entities/bill';
import {
  CasteUserService,
  PermissionAdmin,
} from '@qbitartifacts/caste-client-ng';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-create-bill',
  templateUrl: './create-bill.component.html',
  styleUrls: ['./create-bill.component.scss'],
})
export class CreateBillComponent implements OnInit, LoadableComponent {
  public billForm: FormGroup;
  public isLoading: boolean;
  public currency = null;
  public instance;
  public calculate = true;

  public item: any = {};
  public isEdit = false;
  public changeAccountPermission = PermissionAdmin;
  public isAdmin = false;
  public STATUSES = BILL_STATUSES;
  public status;

  /* istanbul ignore next */
  constructor(
    public dialogRef: MatDialogRef<CreateBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    private billing$: BillingService,
    public user$: CasteUserService
  ) {
    this.isEdit = data.isEdit;
    
    if (this.isEdit) {
      this.item = data.item;
      this.currency = data.item.currency || data.item.instance.currency;
      this.instance = data.item.instance.id;
      this.status = this.item.status;
      this.calculate = false;
    }

    console.log(data);
    this.isAdmin = user$.isAdmin();
  }

  /* istanbul ignore next */
  private getData() {
    const data: any = {
      name: this.name.value,
      period_start: new Date(this.period_start.value).toISOString(),
      period_end: new Date(this.period_end.value).toISOString(),
      calculate: this.calculate,
    };

    if (
      this.amount.dirty &&
      this.amount.value != null &&
      this.amount.value != undefined
    ) {
      data.applied_fee = this.fee.value;
      data.currency =
        this.currency['@id'] || `/public/currencies/${this.currency.id}`;
      data.amount = this.amount.value;
    }

    if (this.instance) {
      data.instance = this.instance ? this.instance['@id'] : null;
    }

    if(this.status){
      data.status = this.status ? this.status : null;
    }
    return data;
  }

  /* istanbul ignore next */
  private getAction(data) {
    if (this.isEdit) {
      data.status = this.status;
      return this.billing$.update(this.item.id, data, 'admin');
    } else {
      return this.billing$.create(data, 'admin');
    }
  }

  /* istanbul ignore next */
  addBill() {
    if (this.billForm.invalid) {
      return false;
    }

    this.setIsLoading(true);
    this.dialogRef.disableClose = true;

    const data = this.getData();
    this.getAction(data).subscribe(
      (resp) => {
        this.snackbar.open(this.isEdit ? 'EDITED_ITEM_OK' : 'ADDED_ITEM_OK');
        this.close(true);
      },
      (err) => {
        this.snackbar.open(err.message || err.detail);
        this.dialogRef.disableClose = false;
        this.setIsLoading(false);
      }
    );
  }

  /* istanbul ignore next */
  ngOnInit() {
    const groupData = {
      amount: [
        {
          value: this.item.amount ? this.item.amount.amount : null,
          disabled: true,
        },
      ],
      name: [this.item.name ? this.item.name : null, Validators.required],
      period_end: [
        this.item.period_end ? this.item.period_end : null,
        Validators.required,
      ],
      period_start: [
        this.item.period_start ? this.item.period_start : null,
        Validators.required,
      ],
      fee: [{ value: null, disabled: true }],
    };

    this.billForm = this.formBuilder.group(groupData);
  }

  /* istanbul ignore next */
  amountChanged($evt) {
    if ($evt != null && $evt != undefined) {
      this.fee.enable();
    } else {
      this.fee.disable();
    }
  }

  /* istanbul ignore next */
  calculateChanged($event) {
    if (this.calculate) {
      this.amount.disable();
      this.fee.disable();
    } else {
      this.amount.enable();
      this.fee.enable();
    }
  }

  /* istanbul ignore next */
  get amount() {
    return this.billForm.get('amount');
  }

  get name() {
    return this.billForm.get('name');
  }

  get fee() {
    return this.billForm.get('fee');
  }

  get period_end() {
    return this.billForm.get('period_end');
  }

  get period_start() {
    return this.billForm.get('period_start');
  }

  /* istanbul ignore next */
  selectInstance($event) {
    this.instance = $event;
  }

  selectStatus($event){
    this.status = $event;
  }

  /* istanbul ignore next */
  currencyChanged($event) {
    this.currency = $event;
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
