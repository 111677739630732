<div class="row">
    <div class="col-lg-2 col-md-4 col-sm-6 no-padding">
        <app-currency-aggregate [stacked]="true" [compact]="true" [hasShadow]="false" title="TOTAL_BALANCE"
            [amount]="balance" [color]="'blue'" [currency]="currencies$.selectedCurrency">
        </app-currency-aggregate>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 no-padding">
        <app-currency-aggregate [stacked]="true" [compact]="true" [hasShadow]="false" title="FUNDS" [amount]="funding"
            [color]="'blue'" [currency]="currencies$.selectedCurrency">
        </app-currency-aggregate>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 no-padding">
        <app-currency-aggregate [stacked]="true" [compact]="true" [hasShadow]="false" title="TOTAL_PROFIT"
            [amount]="totalEarnings" [color]="'blue'" [currency]="currencies$.selectedCurrency">
        </app-currency-aggregate>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 no-padding">
        <app-aggregate-percent [stacked]="true" [compact]="true" [hasShadow]="false" title="YEARLY_BENEFIT"
            [value]="yearProfit" [percent]="yearProfitPercent" [valueColor]="yearProfitPercent < 0 ? 'red' :'green'"
            [symbol]="currencies$.selectedCurrency.symbol" [decimals]="currencies$.selectedCurrency.decimal_points">
        </app-aggregate-percent>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 no-padding">
        <app-aggregate-percent [stacked]="true" [compact]="true" [hasShadow]="false" title="MONTHLY_BENEFIT"
            [value]="monthProfit" [percent]="monthProfitPercent" [valueColor]="monthProfitPercent < 0 ? 'red' :'green'"
            [symbol]="currencies$.selectedCurrency.symbol" [decimals]="currencies$.selectedCurrency.decimal_points">
        </app-aggregate-percent>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 no-padding">
        <app-aggregate-percent [stacked]="true" [compact]="true" [hasShadow]="false" title="DAILY_BENEFIT"
            [value]="dayProfit" [percent]="dayProfitPercent" [valueColor]="dayProfitPercent < 0 ? 'red' :'green'"
            [symbol]="currencies$.selectedCurrency.symbol" [decimals]="currencies$.selectedCurrency.decimal_points">
        </app-aggregate-percent>
    </div>
</div>