import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-aggregate-percent',
  templateUrl: './aggregate-with-percent.component.html',
  styleUrls: ['./aggregate-with-percent.component.scss'],
})
export class AggregateWithPercentComponent {
  @Input() size: 'big' | 'md' | 'sm' = 'big';
  @Input() icon: string;
  @Input() title: string;
  @Input() value: string | number;
  @Input() percent: string | number;
  @Input() symbol: string;
  @Input() decimals = 2;
  @Input() showPercent = false;
  @Input() showDecimals = true;
  @Input() hasShadow = true;
  @Input() compact = false;
  @Input('stacked') isStacked = false;

  @Input() titleColor = 'gray';
  @Input() valueColor = 'blue';
  @Input() iconColor = 'gray';

  get decimalPipeOptions() {
    return `0.${this.decimals || 2}-${this.decimals || 2}`;
  }
}
