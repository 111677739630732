<qbit-dialog-header title="{{title | translate}}" (click)="close()">
</qbit-dialog-header>

<div class="bootstrap-wrapper" *ngIf="!reviewStrategy">
    <form class="padd-20" [formGroup]="strategyDetailsForm" (ngSubmit)="onSubmit()"
        style="overflow-y: auto; max-height: 80vh">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-top-10">
                    {{title | translate}}
                </h4>
                <p class="text-mutted marg-bottom-20">{{'CREATE_STRATEGY_DESC' | translate}}</p>

                <mat-accordion>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'DETAILS'|translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="bootstrap-wrapper">
                            <div class="row">

                                <div class="col-lg-11">
                                    <app-currency-form-field [required]="true" [control]="currency">
                                    </app-currency-form-field>
                                </div>

                                <div class="col-lg-5 col-md-11 col-sm-11">
                                    <mat-form-field (keyup.enter)="onSubmit()">
                                        <mat-label>{{'NAME' | translate}}</mat-label>
                                        <input required id="name" matInput placeholder="Enter your name"
                                            formControlName="name">
                                        <mat-error *ngIf="name.invalid && (name.dirty) && name.errors.required">
                                            {{'NAME_REQUIRED' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-5 col-md-11 col-sm-11">
                                    <mat-form-field>
                                        <mat-label>{{'EXCHANGE' | translate}}</mat-label>
                                        <mat-select required formControlName="exchange">
                                            <mat-option *ngFor="let exchange of exchanges" [value]="exchange">
                                                {{exchange}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-11">
                                    <mat-form-field (keyup.enter)="onSubmit()">
                                        <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
                                        <textarea id="description" matInput placeholder="Enter a description"
                                            formControlName="description"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'INDICATORS'|translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="bootstrap-wrapper">
                            <div class="row">
                                <div class="col-lg-5">
                                    <mat-form-field>
                                        <mat-label>{{'PROFIT' | translate}}</mat-label>
                                        <input type="number" min="0" max="1" step="0.01" id="profit" matInput
                                            placeholder="Enter the profit" formControlName="profit"
                                            (input)="profitChanged()">
                                        <mat-error *ngIf="profit.invalid && profit.errors.required">
                                            {{'PROFIT_REQUIRED' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-5">
                                    <mat-form-field (keyup.enter)="onSubmit()">
                                        <mat-label>{{'RISK' | translate}}</mat-label>
                                        <input id="risk" min="0" max="1" step="0.01" matInput
                                            placeholder="Enter the risk" formControlName="risk" (input)="riskChanged()">
                                        <mat-error *ngIf="risk.invalid && risk.errors.required">
                                            {{'RISK_REQUIRED' | translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{'PARAMETERS'|translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row">
                            <div class="col-lg-11">
                                <mat-form-field>
                                    <mat-label>{{'DIRECTORY' | translate}}</mat-label>
                                    <input id="directory" matInput placeholder="Enter a directory"
                                        formControlName="directory">
                                </mat-form-field>
                            </div>

                            <div class="col-lg-11">
                                <mat-form-field>
                                    <mat-label>{{'TV_SYMBOL' | translate}}</mat-label>
                                    <input id="tv_symbol" matInput placeholder="Enter a trading view symbol"
                                        formControlName="tv_symbol">
                                </mat-form-field>
                            </div>

                            <div class="col-lg-11">
                                <mat-label>{{'ENV' | translate}}</mat-label>

                                <div class="row">
                                    <div class="col-lg-5">
                                        <p class="text-mutted marg-bottom-20">{{'KEY' | translate}}</p>
                                    </div>
                                    <div class="col-lg-5">
                                        <p class="text-mutted marg-bottom-20">{{'VALUE' | translate}}</p>
                                    </div>
                                </div>

                                <div *ngFor="let keyValue of env; let i = index"
                                    class="bootstrap-wrapper marg-bottom-10">
                                    <div class="row">
                                        <div class="col-lg-5">
                                            <input class="search-input" placeholder="{{'KEY' | translate}}"
                                                [value]="keyValue.key" autofocus="true" (change)="updateKey(i, $event)">
                                        </div>
                                        <div class="col-lg-5">
                                            <div class="flex-row" style="align-items: center">
                                                <input class="search-input" placeholder="{{'VALUE' | translate}}"
                                                    [value]="keyValue.value" (focus)="addNewRow()"
                                                    (change)="updateValue(i, $event)">

                                                <button mat-icon-button class="col-error small-icon-button"
                                                    (click)="removeEnv(i)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <div class="flex-row padd-v-15 padd-right-15" style="align-items: center; justify-content: flex-end">
                    <div class="marg-left-10 marg-right-15">
                        <qbit-loadable-button [id]="'createStrategy'"
                            [disabled]="strategyDetailsForm.invalid || (!strategyDetailsForm.dirty && !envTouched) || !currency"
                            [loading]="isLoading">
                            {{'REVIEW' | translate}}
                        </qbit-loadable-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="bootstrap-wrapper" *ngIf="reviewStrategy">
    <div class="padd-20" style="overflow-y: auto; max-height: 80vh">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-top-10">
                    {{'REVIEW_CHANGES' | translate}}
                </h4>
                <p class="text-mutted marg-bottom-20 col-warning">{{'REVIEW_STRATEGY_DESC' | translate}}</p>
            </div>
        </div>

        <!-- Basic -->
        <div class="row">
            <div class="col-lg-12">
                <p class="title-md marg-bottom-15">
                    {{'DETAILS'|translate}}
                </p>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="padd-left-10">
                    <span class="text-subtitle-dark">{{'NAME' | translate}}
                        <code *ngIf="name.touched"
                            class="marg-left-10 text-ultra-small col-blue">{{'EDITED'|translate}}</code>
                    </span>
                    <p class="text-mid">{{name.value}}</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="padd-left-10">
                    <span class="text-subtitle-dark">{{'EXCHANGE' | translate}}
                        <code *ngIf="exchange.touched"
                            class="marg-left-10 text-ultra-small col-blue">{{'EDITED'|translate}}</code>
                    </span>
                    <p class="text-mid">{{exchange.value}}</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="padd-left-10">
                    <span class="text-subtitle-dark">{{'DESCRIPTION' | translate}}
                        <code *ngIf="description.touched"
                            class="marg-left-10 text-ultra-small col-blue">{{'EDITED'|translate}}</code>
                    </span>
                    <p class="text-mid">{{description.value}}</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="padd-left-10">
                    <span class="text-subtitle-dark">{{'CURRENCY' | translate}}
                        <code *ngIf="currency.touched"
                            class="marg-left-10 text-ultra-small col-blue">{{'EDITED'|translate}}</code>
                    </span>
                    <p class="text-mid">({{currency.value.symbol}}) {{currency.value.name}}</p>
                </div>
            </div>
        </div>

        <!-- indicators -->
        <div class="row" *ngIf="risk.value !=null && profit.value != null">
            <div class="col-lg-12">
                <p class="title-md marg-bottom-15">
                    {{'INDICATORS'|translate}}
                </p>
            </div>

            <div class="col-lg-6">
                <div class="padd-left-10">
                    <span class="text-subtitle-dark">{{'PROFIT' | translate}}
                        <code *ngIf="profit.touched"
                            class="marg-left-10 text-ultra-small col-blue">{{'EDITED'|translate}}</code>
                    </span>
                    <p class="text-mid"><code>{{profit.value}}</code></p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="padd-left-10">
                    <span class="text-subtitle-dark">{{'RISK' | translate}}
                        <code *ngIf="risk.touched"
                            class="marg-left-10 text-ultra-small col-blue">{{'EDITED'|translate}}</code>
                    </span>
                    <p class="text-mid"><code>{{risk.value}}</code></p>
                </div>
            </div>
        </div>

        <!-- parameters -->
        <div class="row">
            <div class="col-lg-12">
                <p class="title-md marg-bottom-15">
                    {{'Parameters'|translate}}
                </p>
            </div>

            <div class="col-lg-12">
                <div class="padd-left-10">
                    <span class="text-subtitle-dark">{{'DIRECTORY' | translate}}
                        <code *ngIf="directory.touched"
                            class="marg-left-10 text-ultra-small col-blue">{{'EDITED'|translate}}</code>
                    </span>
                    <p class="text-mid">{{directory.value}}</p>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="padd-left-10">
                    <span class="text-subtitle-dark">{{'TV_SYMBOL' | translate}}
                        <code *ngIf="tv_symbol.touched"
                            class="marg-left-10 text-ultra-small col-blue">{{'EDITED'|translate}}</code>
                    </span>
                    <p class="text-mid">{{tv_symbol.value}}</p>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="padd-left-10">
                    <span class="text-subtitle-dark">{{'ENV' | translate}}
                        <code *ngIf="envTouched"
                            class="marg-left-10 text-ultra-small col-blue">{{'EDITED'|translate}}</code>
                    </span>
                    <div *ngFor="let keyValue of env" style="overflow-y: auto; max-height: 200px; overflow-x: hidden">
                        <div class="row">
                            <div class="col-lg-5">
                                <p class="text-mid marg-bottom-10">{{keyValue.key}}</p>
                            </div>
                            <div class="col-lg-5">
                                <p class="text-mid marg-bottom-10">{{keyValue.value}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="position: sticky; bottom: 0" class="bordered-top">
        <div class="flex-row padd-v-15 padd-right-15" style="align-items: center; justify-content: flex-end">
            <button mat-button (click)="cancelReview()">
                {{'BACK'|translate}}
            </button>
            <div class="marg-left-10 marg-right-15">
                <qbit-loadable-button [id]="'addStrategy'" [loading]="isLoading" (click)="proceed()">
                    {{(isLoading ? 'SAVING': 'SAVE') |translate}}
                </qbit-loadable-button>
            </div>
        </div>
    </div>
</div>