import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateStrategyComponent } from '../create-strategy/create-strategy.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountDetailsService } from 'src/app/services/CRUD/logic-traders/account_details.service';

import { AuthService } from 'src/app/services/auth.service';
import { CasteUserService } from '@qbitartifacts/caste-client-ng';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-ask-account-details',
  templateUrl: './ask-account-details.component.html',
  styleUrls: ['./ask-account-details.component.scss'],
})
export class AskAccountDetailsComponent implements OnInit {
  public title: string = 'Account Details';
  public detailsForm: FormGroup;
  public isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CreateStrategyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public accountDetails: AccountDetailsService,
    public user$: CasteUserService,
    private snackbar: QSnackBar,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.detailsForm = this.formBuilder.group({
      code: ['', Validators.required],
    });
  }

  /* istanbul ignore next */
  logout() {
    this.close();
    this.auth.logout();
  }

  /* istanbul ignore next */
  addDetails() {
    this.isLoading = true;
    this.accountDetails
      .create({
        account_id: this.user$.getAccountId(),
        reference: this.code.value,
      })
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.snackbar.open('SAVED_CORRECTLY');
          this.close();
        },
        (err) => {
          this.isLoading = false;
          this.snackbar.open(err.message || err.description);
        }
      );
  }

  get code() {
    return this.detailsForm.get('code');
  }

  /* istanbul ignore next */
  close(val = false) {
    this.dialogRef.close(val);
  }
}
