<qbit-dialog-header title="{{'EDIT_INSTANCE'|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="instanceDetailsForm" (ngSubmit)="editInstance()" *ngIf="itemData">
        <div class="row">
            <div class="col-lg-12">
                <ng-container *ngIf="app$.isImpersonating">
                    <app-impersonation-banner></app-impersonation-banner>
                </ng-container>

                <h4 class="title-lg marg-top-10">
                    {{'EDIT_INSTANCE'|translate}}
                </h4>
                <p class="text-mutted marg-bottom-15">{{'EDIT_INSTANCE_DESC'|translate}}</p>

                <ng-container *ngIf="forceAccountSelectorForAdmin">
                    <div *appPermissions="changeAccountPermission">
                        <sa-admin-account-selector [disabled]="app$.isImpersonating && !forceAccountSelectorForAdmin"
                            (itemChange)="accountSelected($event);" [item]="itemData.account_id">
                        </sa-admin-account-selector>
                    </div>
                </ng-container>

                <div *ngIf="forceAccountSelectorForAdmin || isTrader || isAdmin || isPro">
                    <app-strategy-selector [selectFirstByDefault]="false"
                        [strategy]="itemData.strategy ? itemData.strategy.id: null"
                        (strategyChange)="strategyChanged($event)"></app-strategy-selector>
                </div>

                <ng-container *ngIf="forceAccountSelectorForAdmin">
                    <div *appPermissions="changeAccountPermission">
                        <app-runner-selector *ngIf="itemData.runner | asyncp as runner" [runner]="runner ? runner.id: null"
                            (runnerChange)="runnerChanged($event)">
                        </app-runner-selector>
                    </div>
                </ng-container>

                <mat-form-field *ngIf="isInvestor">
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input id="name" matInput placeholder="Enter your name" formControlName="name">
                    <mat-error *ngIf="name.invalid && (name.dirty) && name.errors.required">
                        {{'NAME_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div *ngIf="forceAccountSelectorForAdmin || isTrader || isAdmin || isPro">
                    <mat-form-field>
                        <mat-label>{{'CONTROL'|translate}}</mat-label>
                        <mat-select [formControl]="control">
                            <mat-option *ngFor="let control of controls" [value]="control">
                                {{control}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="forceAccountSelectorForAdmin || isAdmin || isSupport">
                    <mat-form-field>
                        <mat-label>{{'STATUS'|translate}}</mat-label>
                        <mat-select [formControl]="status">
                            <mat-option *ngFor="let status of statuses" [value]="status">
                                <span class="col-{{status}}">{{status}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="forceAccountSelectorForAdmin || isAdmin || isSupport">
                    <mat-form-field>
                        <mat-label>{{'DISCOUNT' | translate}}</mat-label>
                        <input type="number" min="0" max="1" id="discount" matInput placeholder="Enter a discount"
                            formControlName="discount">
                    </mat-form-field>
                </div>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'editInstance'" [disabled]="instanceDetailsForm.invalid"
                        [loading]="isLoading">
                        {{(isLoading ? 'SAVING': 'SAVE') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>