import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-risk-indicator',
  templateUrl: './risk-indicator.component.html',
  styleUrls: ['./risk-indicator.component.scss'],
})
export class RiskIndicatorComponent {
  @Input() value = 0;
  @Input() color = 'primary';
  @Input() tooltip = 'primary';
}
