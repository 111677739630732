import { LOCALES } from './config/consts';
export function getLocale(navigator: { languages: readonly string[] }) {
  const validLocales = ['es', 'en'];

  return validLocales.includes(navigator.languages[0])
    ? navigator.languages[0]
    : LOCALES.default;
}

export function leverageToLogarithmicScale(x) {
  return 100 - 100 * Math.pow(2, -x);
}
