<!-- TODO: add canClose=false when available in kit -->
<qbit-dialog-header title="{{'ACCOUNT_DETAILS'|translate}}"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="detailsForm" (ngSubmit)="addDetails()">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-top-10 marg-bottom-15">
                    {{'FILL_DETAILS'|translate}}
                </h4>

                <mat-form-field>
                    <mat-label>{{'TELEGRAM_CODE' | translate}}</mat-label>
                    <input id="code" matInput placeholder="Enter your code" formControlName="code">
                    <mat-error *ngIf="code.invalid && (code.dirty) && code.errors.required">
                        {{'TELEGRAM_CODE_REQUIRED' | translate}}
                    </mat-error>
                    <mat-hint>
                        {{'TELEGRAM_CODE_HINT'| translate}}
                    </mat-hint>
                </mat-form-field>


                <div class="flex-row end marg-top-15">
                    <button mat-button (click)="logout()">
                        {{'BACK'|translate}}
                    </button>
                    <button mat-button [id]="'createStrategy'" [disabled]="detailsForm.invalid">
                        {{(isLoading ? 'SAVING': 'SAVE') |translate}}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>