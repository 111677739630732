import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/base/loadable.page';

import { RunnersService } from 'src/app/services/CRUD/logic-traders/runners.service';
import { IRunner, Runner } from 'src/app/entities/runner.ent';
import { PermissionAdmin } from '@qbitartifacts/caste-client-ng';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-create-runner',
  templateUrl: './create-runner.component.html',
  styleUrls: ['./create-runner.component.scss'],
})
export class CreateRunnerComponent implements OnInit, LoadableComponent {
  public runnerForm: FormGroup;
  public isLoading: boolean;
  public changeAccountPermission = PermissionAdmin;
  public isEdit = false;
  public item: IRunner = {
    name: null,
    capacity: 20,
    status: Runner.STATUS_STOPPED,
  };
  public statuses = Runner.STATUSES;

  constructor(
    public dialogRef: MatDialogRef<CreateRunnerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    private runners$: RunnersService
  ) {
    /* istanbul ignore next */
    if (data.isEdit) {
      this.isEdit = true;
      if (data && data.item) {
        this.item = data.item;
      }
    }
  }

  /* istanbul ignore next */
  private getData() {
    const data = {
      name: this.name.value,
      capacity: this.capacity.value,
      status: this.status.value,
    };


    return data;
  }

  /* istanbul ignore next */
  private getAction(data) {
    if (this.isEdit) {
      return this.runners$.update(this.item.id, data, 'admin');
    } else {
      return this.runners$.create(data, 'admin');
    }
  }

  /* istanbul ignore next */
  addRunner() {
    if (this.runnerForm.invalid) {
      return false;
    }

    this.setIsLoading(true);
    this.dialogRef.disableClose = true;

    const data = this.getData();
    const action = this.getAction(data);

    action.subscribe(
      (resp) => {
        this.snackbar.open(this.isEdit ? 'UPDATED_ITEM_OK' : 'ADDED_ITEM_OK');
        this.close(true);
      },
      (err) => {
        this.snackbar.open(err.message || err.detail);
        this.dialogRef.disableClose = false;
        this.setIsLoading(false);
      }
    );
  }

  /* istanbul ignore next */
  ngOnInit() {
    this.runnerForm = this.formBuilder.group({
      name: [this.item.name, Validators.required],
      capacity: [this.item.capacity, Validators.required],
      status: [this.item.status, Validators.required],
    });
  }

  get name() {
    return this.runnerForm.get('name');
  }

  get capacity() {
    return this.runnerForm.get('capacity');
  }

  get status() {
    return this.runnerForm.get('status');
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
