<qbit-dialog-header title="{{'CREATE_INSTANCE'|translate}}" (click)="close()"></qbit-dialog-header>


<div class="bootstrap-wrapper">
    <mat-horizontal-stepper #stepper [linear]="true">
        <mat-step [stepControl]="instanceDetailsForm1">
            <ng-template matStepLabel>{{'NAME'|translate}}</ng-template>

            <form [formGroup]="instanceDetailsForm1">
                <mat-form-field>
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input id="name" matInput placeholder="Enter your name" formControlName="name" required>
                    <mat-error *ngIf="name.invalid && (name.dirty) && name.errors.required">
                        {{'NAME_REQUIRED' | translate}}
                    </mat-error>
                    <mat-hint>
                        {{'NAME_HINT' | translate}}
                    </mat-hint>
                </mat-form-field>
                <div class="marg-top-15">
                    <button mat-button matStepperNext
                        [disabled]="instanceDetailsForm1.invalid">{{'NEXT' | translate}}</button>
                </div>
            </form>
        </mat-step>

        <mat-step [stepControl]="instanceDetailsForm2">
            <ng-template matStepLabel>{{'STRATEGY'|translate}}</ng-template>
            <div>
                <app-strategy-selector (strategyChange)="selectStrategy($event)" [strategy]="strategy">
                </app-strategy-selector>
            </div>
            <div class="marg-top-15">
                <button mat-button matStepperPrevious>{{'BACK' | translate}}</button>
                <button mat-button matStepperNext
                    [disabled]="instanceDetailsForm1.invalid">{{'NEXT' | translate}}</button>
            </div>
        </mat-step>

        <mat-step [stepControl]="instanceDetailsForm3">
            <ng-template matStepLabel>{{'CREDENTIALS'|translate}}</ng-template>
            <form [formGroup]="instanceDetailsForm3">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label>{{'ACCESS_KEY' | translate}}</mat-label>
                            <input id="key" matInput placeholder="Enter exchange access key" formControlName="accessKey"
                                required>
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field>
                            <mat-label>{{'ACCESS_SECRET' | translate}}</mat-label>
                            <input id="secret" matInput placeholder="Enter exchange access secret"
                                formControlName="accessSecret" required>
                        </mat-form-field>
                    </div>
                    <div class="col-12" style="margin: 15px 0">
                        <mat-accordion>
                            <mat-expansion-panel class="mat-elevation-z0">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{'ADVANCED_OPTIONS' | translate}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="testnet">Testnet
                                </mat-checkbox>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div class="flex-row space-between marg-top-15">
                    <div>
                        <button mat-button matStepperPrevious>{{'BACK' | translate}}</button>
                        <button mat-button matStepperNext [disabled]="isLoading || instanceDetailsForm3.invalid"
                            (click)="addInstance()">{{'ADD' | translate}}</button>
                    </div>

                    <div>
                        <a href="https://logictraders.com/faq" class="text-mutted underline" target="_blank">Help</a>
                    </div>
                </div>
            </form>
        </mat-step>
    </mat-horizontal-stepper>
</div>