import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BaseService,
  CasteAuthConfig,
  CASTE_AUTH_CONFIG,
  DEFAULT_CONFIG,
} from '@qbitartifacts/caste-client-ng';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InstancesHistoryService extends BaseService {
  constructor(
    http: HttpClient,
    @Inject(CASTE_AUTH_CONFIG) config: CasteAuthConfig
  ) {
    super(http, { ...DEFAULT_CONFIG, ...config, url: environment.url });
  }
  protected getToken(): string {
    const token = localStorage.getItem(this.opts.tokenStorageKey);
    return token;
  }

  public getTradeHistory(id: string) {
    return this.get<any>(`/admin/instances/${id}/trade_history`, {}).pipe(
      map((resp) => {
        return {
          data: resp,
          total: resp.length,
        };
      })
    );
  }

  public getOrderHistory(id: string) {
    return this.get<any>(`/admin/instances/${id}/order_history`, {}).pipe(
      map((resp) => {
        return {
          data: resp,
          total: resp.length,
        };
      })
    );
  }
}
