import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/base/loadable.page';

import { ApiKeysService } from 'src/app/services/CRUD/logic-traders/api-keys.service';
import { PermissionAdmin } from '@qbitartifacts/caste-client-ng';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-create-api-keys',
  templateUrl: './create-api-keys.component.html',
  styleUrls: ['./create-api-keys.component.scss'],
})
export class CreateApiKeysComponent implements OnInit, LoadableComponent {
  public keysForm: FormGroup;
  public isLoading: boolean;
  public account = null;
  public currency = null;
  public changeAccountPermission = PermissionAdmin;

  constructor(
    public dialogRef: MatDialogRef<CreateApiKeysComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    private apiKeys$: ApiKeysService
  ) {}

  /* istanbul ignore next */
  addKeys() {
    if (this.keysForm.invalid) {
      return false;
    }

    this.setIsLoading(true);
    this.dialogRef.disableClose = true;

    this.apiKeys$.create({}, 'admin').subscribe(
      (resp) => {
        this.snackbar.open('ADDED_ITEM_OK');
        this.close(true);
      },
      (err) => {
        this.snackbar.open(err.message || err.detail);
        this.dialogRef.disableClose = false;
        this.setIsLoading(false);
      }
    );
  }

  ngOnInit() {
    this.keysForm = this.formBuilder.group({
      amount: [null, Validators.required],
      name: [null, Validators.required],
    });
  }

  get name() {
    return this.keysForm.get('name');
  }

  /* istanbul ignore next */
  accountSelected($event) {
    this.account = $event;
  }

  /* istanbul ignore next */
  currencyChanged($event) {
    this.currency = $event;
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
