import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { AuthService } from './auth.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, public snackbar: QSnackBar) {}

  /* istanbul ignore next*/
  onAuthError() {
    this.authService.logout();
    this.snackbar.open('Unauthorized');
  }

  /* istanbul ignore next*/
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.onAuthError();
            return throwError(err);
          } else {
            return throwError(err);
          }
        }
      })
    );
  }
}
