import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogsService } from 'src/app/services/dialogs.service';

@Component({
  selector: 'table-settings-button',
  templateUrl: './table-settings-button.component.html',
})
export class TableSettingsDialogButton {
  @Input() tableId;
  @Output() settingsChanged: EventEmitter<any> = new EventEmitter();

  constructor(public dialogs: DialogsService) {}

  ngOnInit() {
    if (!this.tableId)
      throw Error(
        '[TableSettingsDialogButton] required tableId to be passed in'
      );
  }

  openSettings() {
    this.dialogs
      .openTableSettings({ id: this.tableId })
      .afterClosed()
      .subscribe((resp) => {
        console.log('settingsChanged button')
        this.settingsChanged.emit();
      });
  }
}
