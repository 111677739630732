<div class="centered">
    <button mat-button [matMenuTriggerFor]="menu" [disabled]="disabled" aria-label="Menu for changing instance control">
        <div class="flex-row center h v" style="align-items: center;">
            <span>{{control}}</span>
            <mat-icon style="width: 18px; height: 18px">arrow_drop_down</mat-icon>
        </div>
    </button>
    <mat-menu #menu="matMenu">
        <menu style="line-height: 30px !important;height: 30px !important;padding-bottom: 0;" mat-menu-item
            *ngFor="let control of controls" (click)="selectionChange($event, control)">
            {{control}}
        </menu>
    </mat-menu>
</div>