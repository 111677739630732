import { IInstance } from './instance';
import { IStrategy } from './strategy';

export interface IRunner {
  affinity?: IStrategy;
  capacity?: number;
  created_at?: string;
  id?: string;
  instances?: IInstance[];
  name?: string;
  status?: string;
  updated_at?: string;
  version?: any;
}

export class Runner {
  public static STATUS_RUNNING = 'running';
  public static STATUS_STOPPED = 'stopped';

  public static STATUSES = [Runner.STATUS_RUNNING, Runner.STATUS_STOPPED];
}
