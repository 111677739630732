<mat-card class="no-padding">
    <qbit-table-header title="INSTANCES" [options]="tableOptions" [isLoading]="isLoading">
        <qbit-add-button (add)="addInstance()" *ngIf="isInvestor || forceAddInstanceForAdmin"></qbit-add-button>

        <table-settings-button (settingsChanged)="settingsChanged()" [tableId]="tableId"></table-settings-button>
        <button mat-icon-button (click)="onSearch(searchParams)">
            <mat-icon>refresh</mat-icon>
        </button>
    </qbit-table-header>

    <qbit-table-filters *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        (filtersChanged)="searchParamsChanged($event)" [filters]="searchParams"></qbit-table-filters>

    <table mat-table [dataSource]="dataSource" matSort class="hoverable" (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" [trackBy]="trackById">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NAME'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="leverage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'LEVERAGE'|translate}} </th>
            <td mat-cell *matCellDef="let row" title="{{row.leverage}}"> <span>{{row.leverage}}</span></td>
        </ng-container>

        <ng-container matColumnDef="external_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'EXTERNAL_ID'|translate}} </th>
            <td mat-cell *matCellDef="let row" title="{{row.external_id}}"> <span>{{row.external_id}}</span></td>
        </ng-container>

        <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="account"> {{'ACCOUNT'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <mat-chip-list *ngIf="row.account | asyncp as account">
                    <mat-chip routerLink="/admin/accounts/{{row.account_id}}"
                        class="mat-chip mat-primary mat-standard-chip">
                        {{account.name}}
                    </mat-chip>
                </mat-chip-list>
            </td>
        </ng-container>

        <ng-container matColumnDef="orders">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="orders"> {{'ORDERS'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <span>B: {{row.buyorders}}, S: {{row.sellorders}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> {{'STATUS'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <span class="col-{{row.status}}"
                    matTooltip="{{row.status == 'errored' ? row.status_text: ''}}">{{row.status}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="control">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="control"> {{'CONTROL'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.control}} </td>
        </ng-container>

        <ng-container matColumnDef="strategy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="strategy"> {{'STRATEGY'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <mat-chip-list *ngIf="row.strategy | asyncp as strategy">
                    <mat-chip [routerLink]="getStrategyLink()" [queryParams]="{ id: strategy.id }"
                        class="mat-chip mat-primary mat-standard-chip">
                        {{strategy.name | translate}}
                    </mat-chip>
                </mat-chip-list>
            </td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
                <app-instance-actions (onAction)="onAction($event)" [instance]="row" [isInvestor]="isInvestor"
                    [isAdmin]="isAdmin" [isTrader]="isTrader" [isPro]="isPro">
                </app-instance-actions>
            </td>
        </ng-container>

        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="{{row.id}}">
        </tr>
    </table>

    <qbit-list-loading *ngIf="isLoading && !hasData"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>

    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>