<qbit-dialog-header title="{{'TRADES_HISTORY'|translate}}" (close)="close()">

</qbit-dialog-header>

<mat-tab-group mat-align-tabs="start">
    <mat-tab label="{{'ORDERS'|translate}}">
        <div style="max-height: 450px; overflow-y: auto;">
            <app-instance-orders-list [instanceId]="data.instanceId"></app-instance-orders-list>
        </div>
    </mat-tab>

    <mat-tab label="{{'TRADES'|translate}}">
        <div style="max-height: 450px; overflow-y: auto;">
            <app-instance-trades-list [instanceId]="data.instanceId"></app-instance-trades-list>
        </div>
    </mat-tab>
</mat-tab-group>