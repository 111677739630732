<mat-form-field>
    <mat-label>Choose a runner</mat-label>
    <mat-select [ngModel]="runner" (ngModelChange)="selectRunner($event)">
        <mat-select-trigger>
            {{runner ? runner.name : 'None'}}
        </mat-select-trigger>

        <mat-option *ngFor="let runner of runners" [value]="runner" style="height: auto!important">
            <div class="flex-row column padd-v-10">
                <span class="marg-bottom-10 text-small">{{runner.name}} </span>
            </div>
        </mat-option>
    </mat-select>
</mat-form-field>