import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CasteUserService } from '@qbitartifacts/caste-client-ng';
import { QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';
import { PageBaseComponent } from 'src/app/base/page.base';
import { AuthedGuard } from 'src/app/guards/authed.guard';
import { QEventsService } from 'src/app/services/events.service';

@Component({
  selector: 'app-admin-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class AdminConfigurationComponent extends PageBaseComponent implements OnInit {
  static guards: any[] = [AuthedGuard];
  public title: string = 'CONFIGURATION';

  constructor(
    public title$: Title,
    public translate$: TranslateService,
    public events: QEventsService,
    public user$: CasteUserService,
    public breadcrumbs$: QBreadcrumbsService
  ) {
    super(title$, translate$, events, breadcrumbs$, user$);
  }

  ngOnInit(): void {
  }

}
