import { SerializableEntity } from '../base/serializable-entity.base';
import { Exchange, IExchange } from './exchange';
import { Note } from './note';
import { Order } from './order';
import { IRunner } from './runner.ent';
import { Strategy } from './strategy';

export interface IInstance {
  id?: string;
  name: string;
  description: string;
  strategy: Strategy;
  alias?: string;
  control?: string;
  exchange?: IExchange | Exchange;
  balance?: number;
  benefit?: number;
  status?: string;
  state?: any;
  runner?: IRunner;
  orders?: Order[];
  notes?: Note[];
  positions?: any[];
  updated_at?: string;
  status_text?: string;
  discount?: string;

  credentials?: { [key: string]: any };
  config?: { [key: string]: any };
}

export class Instance implements SerializableEntity, IInstance {
  static readonly STATUS_NEW = 'new';
  static readonly STATUS_RUNNING = 'running';
  static readonly STATUS_STOPPED = 'stopped';
  static readonly STATUS_ERRORED = 'errored';
  static readonly STATUSES = [
    Instance.STATUS_NEW,
    Instance.STATUS_RUNNING,
    Instance.STATUS_STOPPED,
    Instance.STATUS_ERRORED,
  ];

  static readonly CONTROL_MANUAL = 'manual';
  static readonly CONTROL_AUTO = 'automatic';
  static readonly CONTROLS = [Instance.CONTROL_MANUAL, Instance.CONTROL_AUTO];

  iri: string;
  id: string;
  name: string;
  description: string;
  strategy: Strategy;
  alias: string;
  exchange: IExchange | Exchange;
  balance: number;
  benefit: number;
  runner?: IRunner;
  updated_at: string;
  status: string;
  status_text: string;
  state: any;
  discount: string;
  credentials: { [key: string]: any };
  config: { [key: string]: any };
  orders?: Order[];
  notes?: Note[];
  positions?: any[];
  control?: string = Instance.CONTROL_MANUAL;

  /* istanbul ignore next */
  static fromJson(obj: IInstance): Instance {
    const instance = new Instance();
    instance.id = obj.id;
    instance.iri = obj['@id'];
    instance.alias = obj.alias;
    instance.exchange = obj.exchange;
    instance.balance = obj.balance;
    instance.benefit = obj.benefit;
    instance.strategy = obj.strategy;
    instance.runner = obj.runner;
    instance.status = obj.status;
    instance.status_text = obj.status_text;
    instance.state = obj.state;
    instance.config = obj.config;
    instance.updated_at = obj.updated_at;
    instance.name = obj.name;
    instance.credentials = obj.credentials;
    instance.description = obj.description;
    instance.orders = obj.orders;
    instance.orders = obj.orders;
    instance.control = obj.control;
    instance.discount = obj.discount;
    instance.notes = (obj.notes ?? []).map((el) => Note.fromJson(el));
    instance.positions = [...(obj.positions ?? [])];
    return instance;
  }

  /* istanbul ignore next */
  get isAutomatic(): boolean {
    return this.control === Instance.CONTROL_AUTO;
  }

  /* istanbul ignore next */
  get isManual(): boolean {
    return this.control === Instance.CONTROL_AUTO;
  }

  /* istanbul ignore next */
  get isNew(): boolean {
    return this.status === Instance.STATUS_NEW;
  }

  /* istanbul ignore next */
  fromJson(obj: IInstance): Instance {
    return Instance.fromJson(obj);
  }

  /* istanbul ignore next */
  toJson(): IInstance {
    return {
      alias: this.alias,
      name: this.name,
      description: this.description,
      strategy: this.strategy,
      exchange: this.exchange,
      balance: this.balance,
      benefit: this.benefit,
      status: this.status,
      updated_at: this.updated_at,
      credentials: this.credentials,
      config: this.config,
      control: this.control,
      discount: this.discount,
    };
  }
}
