import { Component, Input, OnInit } from '@angular/core';
import { Currency } from 'src/app/entities/currency';

@Component({
  selector: 'app-currency-aggregate',
  templateUrl: './currency-aggregate.component.html',
  styleUrls: ['./currency-aggregate.component.scss'],
})
export class CurrencyAggregateComponent {
  @Input() title = '';
  @Input() amount = 0;
  @Input() color = 'blue';
  @Input() currency: Currency;
  @Input() hasShadow = true;
  @Input() compact = false;
  @Input() stacked = false;
  @Input() size: 'xlg' | 'lg' | 'md' | 'sm';

  get value() {
    return this.amount;
  }
}
