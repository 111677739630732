import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeleteDialogStatus, QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { Instance } from 'src/app/entities/instance';
import { InstancesService } from 'src/app/services/CRUD/logic-traders/instances.service';
import { DialogsService } from 'src/app/services/dialogs.service';

export type ActionType = 'edit' | 'delete' | 'start' | 'stop' | 'error';
export interface Action {
  type: ActionType;
  instance: Instance;
  error?: any;
}

@Component({
  selector: 'app-instance-actions',
  templateUrl: './instance-actions.component.html',
})
export class InstanceActionsComponent {
  @Input() instance: Instance;
  @Input() isTrader = false;
  @Input() isInvestor = false;
  @Input() isAdmin = false;
  @Input() isPro = false;

  isNew = false;

  @Input() disabled = false;
  @Input() editDisabled = false;
  @Input() deleteDisabled = false;
  @Input() startStopDisabled = false;
  @Input() loading = false;

  @Output() onAction: EventEmitter<Action> = new EventEmitter();
  @Output() loadStart: EventEmitter<void> = new EventEmitter();
  @Output() loadEnd: EventEmitter<void> = new EventEmitter();

  constructor(
    private instances$: InstancesService,
    public snackbar$: QSnackBar,
    public dialogs$: DialogsService
  ) {}

  /* istanbul ignore next */
  private startLoading() {
    this.loadStart.emit();
    this.disabled = true;
  }

  /* istanbul ignore next */
  private endLoading() {
    this.loadEnd.emit();
    this.disabled = false;
  }

  /* istanbul ignore next */
  private emitAction(type: ActionType, error?) {
    this.onAction.emit({ type, error, instance: this.instance });
    this.endLoading();
  }

  /* istanbul ignore next */
  public onEdit() {
    this.dialogs$
      .openEditInstance({
        id: this.instance.id,
        forceAccountSelectorForAdmin: this.isAdmin,
      })
      .afterClosed()
      .subscribe((resp) => {
        this.onAction.emit({ type: 'edit', instance: this.instance });
      });
  }

  public showState() {
    this.dialogs$.openShowJson({
      json: this.instance.state,
      title: 'INSTANCE_STATE'
    });
  }

  public showTradeList(){
    this.dialogs$.openTradeList({
      instanceId: this.instance.id,
    });
  }

  /* istanbul ignore next */
  public edit(data) {
    this.startLoading();
    return this.instances$.update(this.instance.id, data);
  }

  /* istanbul ignore next */
  public onDelete() {
    this.dialogs$
      .openConfirmDelete()
      .afterClosed()
      .subscribe((result) => {
        if (result == DeleteDialogStatus.DELETE) {
          this.instances$.remove(this.instance.id).subscribe((resp) => {
            this.emitAction('delete');
            this.snackbar$.open('INSTANCE_DELETED');
          }, this.onError.bind(this));
        }
      });
  }

  /* istanbul ignore next */
  public onStart() {
    this.edit({ status: Instance.STATUS_RUNNING }).subscribe((resp) => {
      this.emitAction('start');
      this.snackbar$.open('INSTANCE_STARTED');
    }, this.onError.bind(this));
  }

  /* istanbul ignore next */
  public onStop() {
    this.edit({ status: Instance.STATUS_STOPPED }).subscribe((resp) => {
      this.emitAction('stop');
      this.snackbar$.open('INSTANCE_STOPPED');
    }, this.onError.bind(this));
  }

  /* istanbul ignore next */
  private onError(error) {
    this.emitAction('error', error);
    this.snackbar$.open(error.message);
    this.endLoading();
  }
}
