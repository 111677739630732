import { DialogsService } from './../../services/dialogs.service';
import {
  AfterContentInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { AuthedGuard } from 'src/app/guards/authed.guard';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ItemPageBaseComponent } from 'src/app/base/item.page.base';
import { ActivatedRoute, Router } from '@angular/router';
import { InstancesService } from 'src/app/services/CRUD/logic-traders/instances.service';
import { StrategiesService } from 'src/app/services/CRUD/logic-traders/strategies.service';
import { mapInstanceStatistics } from 'src/app/pipes/map-statistics';
import { Strategy } from 'src/app/entities/strategy';
import { DashboardStatsChartComponent } from 'src/app/components/dashboard-stats-chart/dashboard-stats-chart.component';
import { CurrenciesService } from 'src/app/services/CRUD/logic-traders/currencies.service';
import { Currency } from 'src/app/entities/currency';
import { Subject } from 'rxjs';
import { QEventsService } from 'src/app/services/events.service';
import { AppService } from 'src/app/services/app.service';
import { OrdersService } from 'src/app/services/CRUD/logic-traders/orders.service';
import { OrdersListComponent } from 'src/app/components/lists/orders-list/orders-list.component';
import { PositionsListComponent } from 'src/app/components/lists/positions-list/positions-list.component';
import { RiskChart } from 'src/app/components/risk-chart/risk-chart.component';

import { Position } from 'src/app/entities/position';
import { CasteUserService } from '@qbitartifacts/caste-client-ng';
import { createTimer, QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';
import { localeMap } from 'src/app/config/locales';
import { ConfigurationService } from 'src/app/services/CRUD/logic-traders/configuration.service';
import { Instance } from 'src/app/entities/instance';

declare const TradingView;

@Component({
  selector: 'app-instance',
  templateUrl: './instance.component.html',
  styleUrls: ['./instance.component.scss'],
})
export class InstanceComponent
  extends ItemPageBaseComponent
  implements AfterContentInit, OnDestroy, OnInit
{
  static guards: any[] = [AuthedGuard];
  public title = 'INSTANCE';

  @ViewChild('riskChart', { static: false }) riskChart: RiskChart;

  @ViewChild('chart', { static: false })
  public chart: DashboardStatsChartComponent;

  @ViewChild('ordersList', { static: false })
  public ordersComponent: OrdersListComponent;

  @ViewChild('positionsList', { static: false })
  public positionsComponent: PositionsListComponent;

  public isLoadingInstance = false;

  public showAddOrder = false;
  public orders: any[] = [];
  public positions: Position[] = [];
  public strategy: Strategy = {} as Strategy;
  public itemData: any = {
    current_day_profit: 0,
    current_month_profit: 0,
    current_year_profit: 0,
    total_balance: 0,
  };
  public currency: Currency;
  public account: any;

  private stopPolling = new Subject();

  public isTrader = false;
  public isInvestor = false;
  public isAdmin = false;
  public isStatusNew = false;

  tvChart: any;
  isSupport: boolean;
  isPro: boolean;

  grafanaUrlObservable: Promise<string>;
  grafanaUrl: string;
  hasGrafanaUrl = false;

  private _update = false;
  grafanaChartUrl: any;

  constructor(
    public title$: Title,
    public translate$: TranslateService,
    public route: ActivatedRoute,
    public router: Router,
    public dialogs: DialogsService,
    public instances$: InstancesService,
    public strategies$: StrategiesService,
    public currencies$: CurrenciesService,
    public orders$: OrdersService,
    public events: QEventsService,
    public app$: AppService,
    public user$: CasteUserService,
    public breadcrumbs$: QBreadcrumbsService,
    public configuration$: ConfigurationService
  ) {
    super(title$, translate$, route, events, user$, breadcrumbs$);
    this.events
      .on(CurrenciesService.CURRENCY_CHANGE)
      .subscribe(this.onChange.bind(this));
  }

  /* istanbul ignore next */
  ngOnInit() {
    this.setupPermissions();
  }

  public setupPermissions() {
    this.isInvestor = this.user$.isInvestor();
    this.isTrader = this.user$.isTrader();
    this.isAdmin = this.user$.isAdmin();
    this.isSupport = this.user$.isSupport();
    this.isPro = this.user$.isPro();
  }

  /* istanbul ignore next */
  public onChange() {
    this.setupPermissions();
    this.getInstance().subscribe({
      next: (resp) => this.onGotInstance(resp),
    });
  }

  /* istanbul ignore next */
  public ordersChanged() {
    this._update = true;
    this.onChange();
  }

  /* istanbul ignore next */
  public getInstanceData() {
    createTimer(this.getInstance.bind(this), this.stopPolling).subscribe({
      next: (resp) => this.onGotInstance(resp),
    });
  }

  /* istanbul ignore next */
  public getInstance() {
    this.isLoadingInstance = true;

    return this.instances$.getOne(this.itemId).pipe(
      mapInstanceStatistics,
    );
  }

  /* istanbul ignore next */
  public onGotInstance(resp) {
    this.itemData = resp;
    this.strategy = resp.strategy;
    this.isLoadingInstance = false;
    this.breadcrumbs$.editCurrent(this.itemData.name);
    this.account = this.account ?? resp.account;
    this.isStatusNew = this.itemData.status === Instance.STATUS_NEW;

    const newGrafanaChartUrl = this.isAdmin
      ? this.itemData.admin_graphics_url
      : this.itemData.graphics_url;

    if (this.grafanaChartUrl != newGrafanaChartUrl) {
      this.grafanaChartUrl = newGrafanaChartUrl;
      this.hasGrafanaUrl = !!this.grafanaChartUrl;
      this.grafanaUrl = this.grafanaChartUrl;
    }

    this.createTVChart(this.strategy.parameters.tv_symbol);

    setTimeout(() => {
      if (!this.positions.length || !this.orders.length || this._update) {
        this.positions = resp.positions;
        this.orders = resp.orders;
        this.updateLists();
        this._update = false;
      }
    });
  }

  /* istanbul ignore next */
  public onAction($event) {
    switch ($event.type) {
      case 'delete':
        return this.router.navigate(['/instances']);
    }
    this.onChange();
  }

  /* istanbul ignore next */
  public updateLists() {
    if (this.ordersComponent) {
      this.ordersComponent.setData(this.orders);
    }
    if (this.positionsComponent) {
      this.positionsComponent.setData(this.positions);
    }
  }

  /* istanbul ignore next */
  public toggleOrderForm($event) {
    this.showAddOrder = $event;
  }

  /* istanbul ignore next */
  public closeAddOrder() {
    this.showAddOrder = false;
    this.ordersChanged();
  }

  /* istanbul ignore next */
  public createTVChart(symbol: string) {
    if ((this.isTrader || this.isAdmin) && !this.tvChart) {
      setTimeout(() => {
        try {
          // tslint:disable-next-line: no-unused-expression
          this.tvChart = new TradingView.widget(
            {
              autosize: true,
              symbol: symbol ?? 'BITMEX:XBTUSD',
              interval: 'D',
              timezone: 'Etc/UTC',
              theme: this.app$.darkThemeEnabled ? 'dark' : 'light',
              style: '1',
              locale: this.app$.lang,
              toolbar_bg: '#f1f3f6',
              enable_publishing: false,
              allow_symbol_change: true,
              container_id: 'tradingview_491c3',
            },
            100
          );
        } catch (error) {
          console.log('Trading view not available');
        }
      }, 100);
    }
  }

  /* istanbul ignore next */
  public ngAfterContentInit() {
    this.currency = this.currencies$.getCurrency('BTC');
    this.getInstanceData();
  }

  ngOnDestroy() {
    this.stopPolling.next();
    this.events.off(CurrenciesService.CURRENCY_CHANGE);
    this.events.off('account:changed');
    try {
      if (this.tvChart) this.tvChart.remove();
    } catch (error) {}
  }
}
