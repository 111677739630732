import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/base/loadable.page';

import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { ConfigurationService } from 'src/app/services/CRUD/logic-traders/configuration.service';
import { IConfiguration } from 'src/app/entities/configuration.ent';

@Component({
  selector: 'app-create-configuration',
  templateUrl: './create-configuration.component.html',
  styleUrls: ['./create-configuration.component.scss'],
})
export class CreateConfigurationComponent implements OnInit, LoadableComponent {
  public form: FormGroup;
  public isLoading: boolean;
  public isEdit: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CreateConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfiguration,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    private service$: ConfigurationService
  ) {
    console.log('data', data);
    this.isEdit = data != null;
  }

  /* istanbul ignore next */
  add() {
    if (this.form.invalid) {
      return false;
    }

    this.setIsLoading(true);
    this.dialogRef.disableClose = true;

    const newData: Partial<IConfiguration> = {
      name: this.name.value,
      enabled: this.enabled.value,
      graphics_admin_dashboard_url: this.graphics_admin_dashboard_url.value,
      graphics_admin_instance_url: this.graphics_admin_instance_url.value,
      graphics_user_account_url: this.graphics_user_account_url.value,
      graphics_user_instance_url: this.graphics_user_instance_url.value,
    };

    const actionObservable = this.isEdit
      ? this.service$.update(this.data.id, newData, 'admin')
      : this.service$.create(newData, 'admin');

    actionObservable.subscribe(
      (resp) => {
        this.snackbar.open('ADDED_CONFIGURATION_OK');
        this.close(true);
      },
      (err) => {
        this.snackbar.open(err.message || err.detail);
        this.setIsLoading(false);
        this.dialogRef.disableClose = false;
      }
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.isEdit ? this.data.name : null, Validators.required],
      enabled: [this.isEdit ? this.data.enabled : false],
      graphics_admin_dashboard_url: [
        this.isEdit ? this.data.graphics_admin_dashboard_url : null,
        Validators.required,
      ],
      graphics_admin_instance_url: [
        this.isEdit ? this.data.graphics_admin_instance_url : null,
        Validators.required,
      ],
      graphics_user_account_url: [
        this.isEdit ? this.data.graphics_user_account_url : null,
        Validators.required,
      ],
      graphics_user_instance_url: [
        this.isEdit ? this.data.graphics_user_instance_url : null,
        Validators.required,
      ],
    });
  }

  get name() {
    return this.form.get('name');
  }
  get enabled() {
    return this.form.get('enabled');
  }

  get graphics_admin_dashboard_url() {
    return this.form.get('graphics_admin_dashboard_url');
  }

  get graphics_admin_instance_url() {
    return this.form.get('graphics_admin_instance_url');
  }

  get graphics_user_account_url() {
    return this.form.get('graphics_user_account_url');
  }

  get graphics_user_instance_url() {
    return this.form.get('graphics_user_instance_url');
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
