import { Injectable } from '@angular/core';
import {
  CanActivate,
  UrlTree,
  Router,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CasteUserService, PermissionAdmin } from '@qbitartifacts/caste-client-ng';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(
    private user$: CasteUserService,
    public auth$: AuthService,
    public router: Router,
  ) {}

  canActivate(): boolean | UrlTree {
    const hasPermission = PermissionAdmin.canActivate(this.user$.user, this.user$);

    if (hasPermission) {
      return true;
    } else {
      return this.router.parseUrl('dashboard');
    }
  }
}
