import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { QEventsService } from 'src/app/services/events.service';
import { CurrenciesService } from 'src/app/services/CRUD/logic-traders/currencies.service';

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss'],
})
export class CurrencySelectorComponent {
  @Input() public currentCurrency: any = null;
  @Input() public disabled: boolean = false;
  @Output() public currencyChange: EventEmitter<string> = new EventEmitter();
  @ViewChild('search', { static: false }) public searchElement: ElementRef;

  public query: string;

  constructor(
    public currencies$: CurrenciesService,
    public events: QEventsService
  ) {
    this.currentCurrency = this.currencies$.selectedCurrency;
  }

  /* istanbul ignore next */
  selectCurrency(currency) {
    this.currentCurrency = currency;
    this.currencyChange.emit(currency);
    this.currencies$.selectCurrency(this.currentCurrency);
    this.events.fire(CurrenciesService.CURRENCY_CHANGE, currency);
  }
}
