import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Position } from 'src/app/entities/position';
import { OrdersService } from 'src/app/services/CRUD/logic-traders/orders.service';
import { DialogsService } from 'src/app/services/dialogs.service';

import { Currency } from 'src/app/entities/currency';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionAdmin } from '@qbitartifacts/caste-client-ng';
import { QSnackBar, QTableListHeaderOptions } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-positions-list',
  templateUrl: './positions-list.component.html',
  styleUrls: ['./positions-list.component.scss'],
})
export class PositionsListComponent implements OnInit {
  public displayedColumns: string[] = [
    'amount',
    'price',
    'leverage',
    'liquidation',
    'options',
  ];

  public addPermission = PermissionAdmin;
  @Input() allowAdd = false;
  @Input() positions: Position[] = [];
  @Input() currency: Currency;
  @Input() instance: any;
  @Input() disableActions = false;

  @Output() listChanged: EventEmitter<any> = new EventEmitter<any>();

  public dataSource: MatTableDataSource<Position>;
  public tableOptions: QTableListHeaderOptions = {
    showBreadcrumbs: false,
  };

  constructor(
    public dialogs$: DialogsService,
    public snackbar: QSnackBar,
    public orders$: OrdersService
  ) {}

  ngOnInit() {
    this.setData(this.positions);
  }

  /* istanbul ignore next */
  public openRemoveConfirmation(position: Position) {
    this.dialogs$
      .openClosePosition({
        position: position,
        instance: this.instance,
      })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.onItemRemoved(position);
        }
      });
  }

  /* istanbul ignore next */
  public onItemRemoved(position: Position) {
    this.listChanged.emit({ id: position.id, type: 'removed' });
  }

  setData(data) {
    this.positions = data;
    this.dataSource = new MatTableDataSource(data);
  }
}
