import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grafana-iframe',
  templateUrl: './grafana-iframe.component.html',
  styleUrls: ['./grafana-iframe.component.scss'],
})
export class GrafanaIframeComponent implements OnInit {
  @ViewChild('iframe') iframe: ElementRef;
  @Input() url: string;

  constructor(public sanitizer: DomSanitizer, private ref: ChangeDetectorRef) {}

  ngOnInit() {
    // this.grafanaService
    //   .get(this.url, this.auth.session && this.auth.session.token)
    //   .subscribe((blob) => (this.iframe.nativeElement.src = blob));
  }

  ngAfterViewInit() {
    this.ref.detach();
  }

  ngOnChanges(changes: SimpleChanges) {
    var urlChanges = changes['url'];
    if (urlChanges.firstChange) return;
    if (urlChanges.currentValue === urlChanges.previousValue) return;

    this.ref.reattach();
    this.ref.detectChanges();
    this.ref.detach();
  }
}
