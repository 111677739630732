<div class="header"></div>
<div class="page-centered">
    <div class="content" id="signup-form" *ngIf="!signUpSuccess">
        <mat-card class="padd-v-20 padd-h-15">
            <h4 class="text-title align-center">
                {{'SINGUP'|translate}}
            </h4>
            <p class="text-light align-center marg-bottom-20">
                {{'WELCOME'|translate:({title: environment.brand.title})}}
            </p>

            <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()" autocomplete="new-password">
                <div class="centered">
                    <div>
                        <mat-form-field appearance="legacy">
                            <mat-label>{{'EMAIL' | translate}}</mat-label>
                            <input id="email" type="email" matInput placeholder="Enter your email"
                                formControlName="email" autocomplete="new-password">
                            <mat-icon matSuffix>email</mat-icon>

                            <mat-error *ngIf="email.invalid && (email.dirty) && email.errors.required">
                                {{'EMAIL_REQUIRED' | translate}}
                            </mat-error>
                            <mat-error *ngIf="email.invalid && (email.dirty) && email.errors.email">
                                {{'EMAIL_ERROR' | translate}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="legacy" (keyup.enter)="onSubmit()">
                            <mat-label>{{'PASSWORD' | translate}}</mat-label>
                            <input id="password" matInput [type]="hidePass ? 'password' : 'text'"
                                formControlName="password" autocomplete="new-password">
                            <button mat-icon-button type="button" matSuffix (click)="hidePass = !hidePass"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass">
                                <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="password.invalid && (password.dirty) && password.errors.required">
                                {{'PASSWORD_REQUIRED' | translate}}
                            </mat-error>
                        </mat-form-field>

                        <mat-error class="marg-top-15"
                            *ngIf="signUpForm.invalid && signUpForm.errors && signUpForm.errors.invalidCredentials">
                            <span class="text-small">{{signUpForm.errors.invalidCredentials | translate}}</span>
                        </mat-error>
                    </div>

                    <qbit-loadable-button [id]="'signup'" [disabled]="signUpForm.invalid" [loading]="isLoading">
                        {{(isLoading ? 'SINGING_UP': 'SINGUP') | translate}}
                    </qbit-loadable-button>

                    <p class="text-subtitle">{{'OR'|translate}}</p>

                    <button mat-button color="primary" routerLink="/login">
                        {{'LOGIN'|translate}}
                    </button>
                </div>
            </form>
        </mat-card>
    </div>

    <div class="content" id="signup-success" *ngIf="signUpSuccess === true">
        <mat-card class="padd-v-20 padd-h-15">
            <div class="centered">
                <h4 class="text-title align-center col-green" id="signup-success-title">
                    {{'SIGNUP_SUCCESS'|translate}}
                </h4>
                <p class="text-light align-center marg-bottom-20">
                    {{'WELCOME'|translate:({title: environment.brand.title})}}
                </p>
                <p class="text-light align-center">
                     {{'SIGNUP_SUCCESS_DESC'|translate}}
                </p>

                <button mat-button color="primary" routerLink="/login">
                    {{'LOGIN'|translate}}
                </button>
            </div>
        </mat-card>
    </div>

    <div class="float-top-right">
        <qbit-lang-selector></qbit-lang-selector>
    </div>
</div>