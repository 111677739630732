<qbit-dialog-header title="{{'ADD_NOTE' | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="form" (ngSubmit)="addInstance()">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg">
                    {{'ADD_NOTE' | translate}}
                </h4>
                <p class="text-mutted marg-bottom-15 marg-top-10"
                    [innerHTML]="'ADD_NOTE_DESC' | translate: ({ instanceName: instance.name })"></p>

                <mat-form-field>
                    <mat-label>{{'NOTE_AUTHOR' | translate}}</mat-label>
                    <input type="text" id="author" matInput placeholder="{{'NOTE_AUTHOR' | translate}}"
                        formControlName="author">
                    <mat-error *ngIf="author.invalid && (author.dirty) && author.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'NOTE_MESSAGE' | translate}}</mat-label>
                    <input type="text" id="message" matInput placeholder="{{'NOTE_MESSAGE' | translate}}"
                        formControlName="message">
                    <mat-error *ngIf="message.invalid && (message.dirty) && message.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'createInstance'" [disabled]="form.invalid || isLoading"
                        [loading]="isLoading">
                        {{(isLoading ? 'ADDING': 'ADD') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>