import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CrudBaseService } from '../../../base/crud.base.service';
import { AuthService } from '../../auth.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { AccountsService } from '../../overrides/accounts.service';
import { CasteUserService } from '@qbitartifacts/caste-client-ng';
import { Currency } from 'src/app/entities/currency';

export interface CreateInstanceData {
  name: string;
  description: string;
  control: string;
  strategy: string;
  credentials: object;
  account_id: string;
  positions?: any[];
  orders?: any[];
  runner_id?: string;
  status?: string;
  dicount?: number;
}

@Injectable({
  providedIn: 'root',
})
export class InstancesService extends CrudBaseService<CreateInstanceData> {
  constructor(
    http: HttpClient,
    auth: AuthService,
    private accountsService: AccountsService,
    private user$: CasteUserService
  ) {
    super(
      {
        endpoint: 'instances',
      },
      http,
      auth
    );
  }

  /* istanbul ignore next */
  public listAll(
    params?: { [key: string]: string },
    userType?: string
  ): Observable<any> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}`
    );

    return this.get<any>(path, params).pipe(
      map((resp) => {
        for (let item of resp.data) {
          this.mapInstance(item);
        }
        return resp;
      })
    );
  }

  public getTradeHistory(id: string, userType: string = 'admin') {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}/${id}/trade_history`
    );

    return this.get<any>(path, {});
  }

  public getOrderHistory(id: string, userType: string = 'admin') {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}/${id}/order_history`
    );

    return this.get<any>(path, {});
  }

  public getOne(id: string, userType?: string) {
    return super.getOne(id, userType).pipe(
      map((res) => {
        this.mapInstance(res);
        return res;
      })
    );
  }

  /*
   * @overrides update, and injects correct user type, without the need to specify it
   */
  /* istanbul ignore next */
  public update(
    id: string,
    data: Partial<CreateInstanceData>,
    userType?: string
  ): Observable<CreateInstanceData> {
    return super.update(
      id,
      data,
      userType || InstancesService.getUserTypeForRequest(this.user$)
    );
  }

  /* istanbul ignore next */
  public static getUserTypeForRequest(user$: CasteUserService) {
    if (user$.isAdmin()) {
      return null;
    }
    if (user$.isPro()) {
      return 'pro';
    }
    if (user$.isTrader()) {
      return 'trader';
    }

    return null;
  }

  private mapInstance(item) {
    item.account = this.accountsService.getOne(item.account_id, 'user');
    item.currency = Currency.fromJson(item.currency);

    const buyorders = item.orders.filter(
      (el) => (el.amount ? el.amount.amount : 0) > 0
    ).length;
    const sellorders = item.orders.filter(
      (el) => (el.amount ? el.amount.amount : 0) < 0
    ).length;

    item.buyorders = buyorders;
    item.sellorders = sellorders;

    const positions = [...item.positions];

    // Get leverage
    const position = positions.pop();
    item.leverage = position ? position.leverage : 0;
  }
}
