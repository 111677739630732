import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { CrudBaseService } from 'src/app/base/crud.base.service';
import {
  Configuration,
  IConfiguration,
} from 'src/app/entities/configuration.ent';
import { AuthService } from '../../auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService extends CrudBaseService<IConfiguration> {
  public currentConfiguration: Configuration = Configuration.fromJson({
    name: '',
    enabled: false,
    graphics_admin_dashboard_url: '',
    graphics_admin_instance_url: '',
    graphics_user_account_url: '',
    graphics_user_instance_url: '',
    graphics_admin_kraken_instance_url: '',
    graphics_admin_bitmex_instance_url: '',
    graphics_user_kraken_instance_url: '',
    graphics_user_bitmex_instance_url: '',
  });

  constructor(http: HttpClient, auth: AuthService) {
    super(
      {
        endpoint: 'configurations',
      },
      http,
      auth
    );
  }

  getFirstActive(): Observable<Configuration> {
    return this.listAll({}, 'admin').pipe(
      map((res: any) => {
        return (res.data || []).find((item) => item.enabled);
      }),
      map((data) => Configuration.fromJson(data)),
      tap((data) => {
        this.currentConfiguration = data;
      })
    );
  }
}
