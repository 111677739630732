<div class="bootstrap-wrapper">
    <div *ngIf="hasGrafanaUrl;" style="height: calc(100vh - 130px)">
        <app-grafana-iframe [url]="grafanaChartUrl"></app-grafana-iframe>
    </div>

    <mat-card *ngIf="!hasGrafanaUrl;" style="height: calc(100vh - 130px)">
        <div class="centered h v" style="height: 100%">
            <mat-icon>info</mat-icon>
            {{'NO_DATA_YET'|translate}}
        </div>
    </mat-card>
</div>