import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadableComponent } from 'src/app/base/loadable.page';
import { OrdersService } from 'src/app/services/CRUD/logic-traders/orders.service';
import { Position } from 'src/app/entities/position';

import { Instance } from 'src/app/entities/instance';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Money } from 'src/app/entities/money.ent';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { Currency } from 'src/app/entities/currency';

export interface ClosePositionDialogData {
  position: Position;
  instance: Instance;
}

@Component({
  selector: 'app-close-position',
  templateUrl: './close-position.component.html',
})
export class ClosePositionDialog implements LoadableComponent {
  public isLoading: boolean;
  public position: Position;
  public instance: Instance;
  public form: FormGroup;
  public limitClose: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ClosePositionDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: ClosePositionDialogData,
    public orders$: OrdersService,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder
  ) {
    this.position = data.position;
    this.instance = data.instance;
    console.log('this.position', this.position);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      price: [{ value: null, disabled: true }],
    });
  }

  /* istanbul ignore next */
  private getDefaultPrice() {
    const position = this.position;
    const priceMoney = Money.fromJson(position.price);
    let defaultPrice = priceMoney.currency.addDecimalPoints(
      priceMoney.currency.calculateDecimals(position.price.amount)
    );

    if (position.amount.amount > 0) defaultPrice += 1;
    if (position.amount.amount < 0) defaultPrice -= 1;

    return defaultPrice;
  }

  /* istanbul ignore next */
  limitChanged($evt) {
    if (this.limitClose) {
      this.price.enable();
      this.price.setValue(this.getDefaultPrice());
    } else {
      this.price.disable();
    }
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  /* istanbul ignore next */
  closePosition() {
    this.setIsLoading(true);

    const currency = Currency.fromJson(this.position.amount.currency);
    const invertedAmount = Money.toInverted(
      currency,
      this.position.amount.amount
    );

    this.orders$
      .create({
        amount: invertedAmount,
        price: this.price.value,
        instance: `/instances/${this.instance}`,
      } as any)
      .subscribe(
        (resp) => {
          this.snackbar.open('CLOSED_POSITION');
          this.close(true);
        },
        (error) => {
          this.setIsLoading(false);
          this.snackbar.open(error.message || error.detail);
        }
      );
  }

  /* istanbul ignore next */
  get price() {
    return this.form.get('price');
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
