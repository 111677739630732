<mat-form-field>
    <mat-label>{{ title | translate }}</mat-label>
    <mat-select [ngModel]="strategy" (ngModelChange)="selectStrategy($event)">
        <mat-select-trigger>
            {{strategy ? strategy.name : 'None'}}
        </mat-select-trigger>

        <mat-option *ngIf="isNullable" [value]="null">
            {{'NONE' | translate}}
        </mat-option>

        <mat-option *ngFor="let strategy of strategies" [value]="strategy" style="height: auto!important">
            <div class="flex-row column padd-v-10">
                <span class="marg-bottom-10 text-small">{{strategy.name}} <code
                        class="text-mutted">@ {{strategy.exchange}}</code></span>
                <span class="text-mutted">{{strategy.description}}</span>
            </div>
        </mat-option>
    </mat-select>
</mat-form-field>