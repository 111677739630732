import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BillingService } from 'src/app/services/CRUD/logic-traders/billing.service';

export interface TradeListData {
  instanceId: any;
}

@Component({
  selector: 'app-trades-list',
  templateUrl: './trades-list.component.html',
})
export class TradeListComponent {
  public title: string = 'Trades';

  constructor(
    public dialogRef: MatDialogRef<TradeListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TradeListData,
    public billing$: BillingService
  ) {}

  /* istanbul ignore next */
  close(val = false) {
    this.dialogRef.close(val);
  }
}
