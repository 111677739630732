export interface INote {
  author: string;
  instance: string;
  message: string;
  updated_at?: string;
  created_at?: string;
}

export class Note implements INote {
  author: string;
  instance: string;
  message: string;
  updated_at?: string;
  created_at?: string;

  toJson?(obj: any): { [k: string]: any } {
    return {};
  }

  static fromJson(obj: INote): Note {
    const note = new Note();
    note.author = obj.author;
    note.instance = obj.instance;
    note.message = obj.message;
    note.created_at = obj.created_at;
    note.updated_at = obj.updated_at;
    return note;
  }
}
