import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Component, AfterContentInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PublicGuard } from './../../guards/public.guard';
import { PageBaseComponent } from 'src/app/base/page.base';
import { LoadablePageComponent } from 'src/app/base/loadable.page';
import { TranslateService } from '@ngx-translate/core';
import {
  CasteAuthService,
  CasteUserService,
  LoginResponse,
} from '@qbitartifacts/caste-client-ng';
import { Session } from 'src/app/entities/session';
import { QEventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';
import { QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent
  extends PageBaseComponent
  implements AfterContentInit, LoadablePageComponent {
  static guards: any[] = [PublicGuard];
  public title = 'Login';
  public hidePass = true;
  public isLoading = false;

  public loginForm: FormGroup;
  public submitted = false;
  public environment = environment;

  constructor(
    title: Title,
    private formBuilder: FormBuilder,
    public translate$: TranslateService,
    public auth$: AuthService,
    public router: Router,
    public casteAuth: CasteAuthService,
    public events: QEventsService,
    public user$: CasteUserService,
    public breadcrumbs$: QBreadcrumbsService
  ) {
    super(title, translate$, events, breadcrumbs$, user$);
  }

  public ngAfterContentInit() {
    super.ngAfterContentInit();

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  /* istanbul ignore next */
  public setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  /* istanbul ignore next */
  public onSubmit(): boolean {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return false;
    }

    this.setIsLoading(true);

    this.casteAuth
      .signIn({
        username: this.username.value,
        password: this.password.value,
      })
      .subscribe({
        next: this.loginSuccess.bind(this),
        error: this.loginError.bind(this),
      });

    return true;
  }

  /* istanbul ignore next */
  private async loginSuccess(resp: LoginResponse) {
    this.setIsLoading(false);

    const expireDateTimestampSec = new Date(resp.exp * 1000);
    const session = new Session().fromJson({
      token: resp.token,
      expireDate: expireDateTimestampSec,
    });

    const tokenData = CasteAuthService.decodeToken(session.token);

    this.casteAuth.saveToken(session.token);
    this.user$.setUserFromTokenData(tokenData);
    this.user$.setApplication(tokenData.application);

    // This must be executed after setting all data, it executes an event
    this.auth$.setSession(session);

    if (this.auth$.redirectUrl) {
      this.router.navigate([this.auth$.redirectUrl]);
      this.auth$.redirectUrl = null;
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  /* istanbul ignore next */
  private loginError(error) {
    this.setIsLoading(false);
    this.loginForm.setErrors({
      invalidCredentials: error.message,
    });
  }
}
