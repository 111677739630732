import { Bill } from './../entities/bill';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateInstanceComponent } from '../dialogs/instance/create-instance/create-instance.component';
import { CreateStrategyComponent } from '../dialogs/create-strategy/create-strategy.component';
import {
  ConfirmationDialogData,
  DeleteConfirmationComponent,
} from '../dialogs/delete-confirmation/delete-confirmation.component';
import { CreateBillComponent } from '../dialogs/create-bill/create-bill.component';
import { CreateOrderComponent } from '../dialogs/create-order/create-order.component';
import { CreateCurrencyComponent } from '../dialogs/create-currency/create-currency.component';
import { EditInstanceComponent } from '../dialogs/instance/edit-instance/edit-instance.component';
import { CreateRunnerComponent } from '../dialogs/runner/create-runner/create-runner.component';
import { CreateApiKeysComponent } from '../dialogs/create-api-keys/create-api-keys.component';
import { BillDetailsComponent } from '../dialogs/bill-details/bill-details.component';
import { AskAccountDetailsComponent } from '../dialogs/ask-account-details/ask-account-details.component';
import { ChangePasswordComponent } from '../dialogs/change-password/change-password.component';
import {
  ClosePositionDialog,
  ClosePositionDialogData,
} from '../dialogs/close-position/close-position.component';
import { Strategy } from '../entities/strategy';
import { Account } from '@qbitartifacts/caste-client-ng';
import { Instance } from '../entities/instance';
import {
  TableSettingsDialog,
  TableSettingsDialogData,
} from '../dialogs/table-settings/table-settings.component';
import { ShowJsonDialog } from '../dialogs/show-json/show-json.component';
import {
  TradeListComponent,
  TradeListData,
} from '../dialogs/trades-list/trades-list.component';
import { IConfiguration } from '../entities/configuration.ent';
import { CreateConfigurationComponent } from '../dialogs/create-configuration/create-configuration.component';
import { CreateNoteComponent, CreateNoteComponentData } from '../dialogs/create-note/create-note.component';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(public dialog: MatDialog) {}

  openDialog<T = any>(
    component,
    data = {},
    options: MatDialogConfig = { width: '60%' }
  ) {
    return this.dialog.open<T>(component, {
      ...options,
      data,
    });
  }

  /* istanbul ignore next */
  openConfirmation(
    data: Partial<ConfirmationDialogData>,
    options?: Partial<MatDialogConfig>
  ) {
    return this.openDialog(DeleteConfirmationComponent, data, {
      ...options,
      width: '400px',
    });
  }

  /* istanbul ignore next */
  openConfirmDelete(options?: Partial<MatDialogConfig>) {
    return this.openDialog(
      DeleteConfirmationComponent,
      {},
      { ...options, width: '400px' }
    );
  }

  /* istanbul ignore next */
  openCreateInstance(data?: { forceAccountSelectorForAdmin: boolean }) {
    return this.openDialog<CreateInstanceComponent>(
      CreateInstanceComponent,
      data,
      { width: '40%' }
    );
  }

  /* istanbul ignore next */
  openCreateStrategy(data: any = {}) {
    return this.openDialog<CreateStrategyComponent>(
      CreateStrategyComponent,
      data,
      { width: '40%' }
    );
  }

  /* istanbul ignore next */
  openEditStrategy({ strategy }: { strategy: Strategy }) {
    return this.openCreateStrategy({
      item: strategy,
      isEdit: true,
    });
  }

  /* istanbul ignore next */
  openCreateBill(data: any = {}, options: MatDialogConfig = { width: '40%' }) {
    return this.openDialog<CreateBillComponent>(
      CreateBillComponent,
      data,
      options
    );
  }

  /* istanbul ignore next */
  openViewBill(data: Bill, options: MatDialogConfig = { width: '35%' }) {
    return this.openDialog<BillDetailsComponent>(
      BillDetailsComponent,
      data,
      options
    );
  }

  /* istanbul ignore next */
  openCreateApiKeys() {
    return this.openDialog<CreateApiKeysComponent>(
      CreateApiKeysComponent,
      {},
      { width: '40%' }
    );
  }

  /* istanbul ignore next */
  openCreateOrder(data: { instanceId: string; instance: Instance }) {
    return this.openDialog<CreateOrderComponent>(CreateOrderComponent, data, {
      width: '40%',
    });
  }

  /* istanbul ignore next */
  openCreateNote(data: CreateNoteComponentData) {
    return this.openDialog<CreateNoteComponent>(
      CreateNoteComponent,
      data,
      { width: '40%' }
    );
  }

  /* istanbul ignore next */
  openCreateAccountDetails(data: any = {}) {
    return this.openDialog<AskAccountDetailsComponent>(
      AskAccountDetailsComponent,
      data,
      {
        width: '40%',
        disableClose: true,
      }
    );
  }

  /* istanbul ignore next */
  openCreateCurrency(data: any = {}) {
    return this.openDialog<CreateCurrencyComponent>(
      CreateCurrencyComponent,
      data,
      {
        width: '40%',
      }
    );
  }

  /* istanbul ignore next */
  openEditInstance(data: {
    id: string;
    forceAccountSelectorForAdmin?: boolean;
  }) {
    return this.openDialog<EditInstanceComponent>(EditInstanceComponent, data, {
      width: '50%',
    });
  }

  /* istanbul ignore next */
  openCreateRunner(data?: any) {
    return this.openDialog<CreateRunnerComponent>(CreateRunnerComponent, data, {
      width: '40%',
    });
  }

  openCreateConfiguration(data: IConfiguration = null) {
    return this.openDialog<CreateConfigurationComponent>(
      CreateConfigurationComponent,
      data,
      {
        width: '40%',
      }
    );
  }

  /* istanbul ignore next */
  openClosePosition(data: ClosePositionDialogData) {
    return this.openDialog<ClosePositionDialog>(ClosePositionDialog, data, {
      width: '30%',
    });
  }

  openShowJson(data: { json: object; title?: string }) {
    return this.openDialog<ShowJsonDialog>(ShowJsonDialog, data);
  }

  openTradeList(data: TradeListData) {
    return this.openDialog<TradeListComponent>(TradeListComponent, data, {
      width: '80%',
    });
  }

  /* istanbul ignore next */
  openTableSettings(
    data: TableSettingsDialogData,
    options: MatDialogConfig = { width: '35%' }
  ) {
    return this.openDialog<TableSettingsDialog>(
      TableSettingsDialog,
      data,
      options
    );
  }

  /* istanbul ignore next */
  openChangePassword(data?: any) {
    return this.openDialog<ChangePasswordComponent>(
      ChangePasswordComponent,
      data,
      {
        width: '40%',
      }
    );
  }
}
