import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/base/loadable.page';
import { InstancesService } from 'src/app/services/CRUD/logic-traders/instances.service';

import { AppService } from 'src/app/services/app.service';
import { PermissionAdmin } from '@qbitartifacts/caste-client-ng';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-create-instance',
  templateUrl: './create-instance.component.html',
  styleUrls: ['./create-instance.component.scss'],
})
export class CreateInstanceComponent implements OnInit, LoadableComponent {
  public isLoading: boolean;
  public strategy;
  public testnet = false;
  public account = null;
  public runner = null;

  public changeAccountPermission = PermissionAdmin;
  public forceAccountSelectorForAdmin = false;
  public instanceDetailsForm1: FormGroup;
  public instanceDetailsForm2: FormGroup;
  public instanceDetailsForm3: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateInstanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    private instances$: InstancesService,
    public app$: AppService
  ) {
    this.forceAccountSelectorForAdmin = data.forceAccountSelectorForAdmin;
  }

  /* istanbul ignore next */
  addInstance() {
    if (
      this.instanceDetailsForm1.invalid ||
      this.instanceDetailsForm2.invalid
    ) {
      return false;
    }

    this.setIsLoading(true);
    this.dialogRef.disableClose = true;

    this.instances$
      .create({
        name: this.name.value,
        strategy: this.strategy['@id'] || `/strategies/${this.strategy.id}`,
        credentials: {
          apiKey: this.accessKey.value,
          secret: this.accessSecret.value,
          testnet: this.testnet,
        },
        account_id: this.account ? this.account.id : this.app$.getOwner(),
      })
      .subscribe(
        (resp) => {
          this.snackbar.open('ADDED_INSTANCE_OK');
          this.close(true);
        },
        (err) => {
          this.snackbar.open(err.message || err.detail);
          this.setIsLoading(false);
          this.dialogRef.disableClose = false;
        }
      );
  }

  ngOnInit() {
    this.instanceDetailsForm1 = this.formBuilder.group({
      name: ['', Validators.required],
    });
    this.instanceDetailsForm2 = this.formBuilder.group({
      strategy: ['', Validators.required],
    });
    this.instanceDetailsForm3 = this.formBuilder.group({
      accessKey: ['', Validators.required],
      accessSecret: ['', Validators.required],
    });
  }

  get name() {
    return this.instanceDetailsForm1.get('name');
  }

  get accessKey() {
    return this.instanceDetailsForm3.get('accessKey');
  }

  get accessSecret() {
    return this.instanceDetailsForm3.get('accessSecret');
  }

  /* istanbul ignore next */
  selectStrategy(strategy) {
    this.strategy = strategy;
    this.instanceDetailsForm2.setValue({ strategy });
  }

  /* istanbul ignore next */
  accountSelected($event) {
    this.account = $event;
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
