import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instance-details',
  templateUrl: './instance-details.component.html',
  styleUrls: ['./instance-details.component.scss']
})
export class InstanceDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
