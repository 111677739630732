import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrdersService } from 'src/app/services/CRUD/logic-traders/orders.service';
import { DialogsService } from 'src/app/services/dialogs.service';

import { Currency } from 'src/app/entities/currency';
import { environment } from 'src/environments/environment';
import { AccountPermission } from 'src/app/entities/account-permission.ent';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionAdmin } from '@qbitartifacts/caste-client-ng';
import { QSnackBar, QTableListHeaderOptions } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-permissions-list',
  templateUrl: './permissions-list.component.html',
  styleUrls: ['./permissions-list.component.scss'],
})
export class PermissionsListComponent implements OnInit {
  protected addPermission = PermissionAdmin;
  public displayedColumns: string[] = [
    'name',
    'grants',
    'created_at',
    'options',
  ];
  public dataSource: MatTableDataSource<AccountPermission>;
  public tableOptions: QTableListHeaderOptions = {};

  @Input() allowAdd = false;
  @Input() permissions: AccountPermission[] = [];
  @Input() currency: Currency;
  @Output() listChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    protected dialogs$: DialogsService,
    protected snackbar: QSnackBar,
    protected orders$: OrdersService
  ) {}

  ngOnInit() {
    this.setData(this.permissions);
  }

  public setData(data: AccountPermission[]) {
    this.permissions = data;
    this.dataSource = new MatTableDataSource(data);
  }

  /* istanbul ignore next */
  public createCasteLink(data: AccountPermission) {
    return `${environment.casteUrl}/users/${data.user.id}?realm=${environment.realm}`;
  }
}
