<qbit-table-header title="ORDERS" [options]="tableOptions">
    <qbit-add-button text="ADD" *ngIf="!showAddOrder && !disableActions" (add)="onToggleAddOrder()"></qbit-add-button>
    <qbit-add-button text="CANCEL" *ngIf="showAddOrder" [icon]="null" (add)="closeAddOrder()"></qbit-add-button>
</qbit-table-header>

<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> {{'AMOUNT'|translate}} </th>
        <td mat-cell *matCellDef="let row">
            {{row.amount && row.amount.representation}}
        </td>
    </ng-container>

    <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef> {{'PRICE'|translate}} </th>
        <td mat-cell *matCellDef="let row">
            {{row.price && row.price.representation}}
        </td>
    </ng-container>

    <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef> {{'UPDATED'|translate}} </th>
        <td mat-cell *matCellDef="let row"> <i matTooltip="{{row.updated_at}}">{{row.updated_at | date}}</i></td>
    </ng-container>

    <ng-container matColumnDef="options" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
            <button mat-icon-button class="col-error" *ngIf="!disableActions" (click)="openRemoveConfirmation(row.id)">
                <mat-icon color="warn" class="col-error mat-icon material-icons mat-icon-small">delete</mat-icon>
            </button>
        </td>
    </ng-container>


    <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
</table>

<div class="list-no-items" *ngIf="!orders.length">
    <p class="text-light">{{'NO_ITEMS'|translate}}</p>
</div>