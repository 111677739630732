<mat-card class="no-padding">
    <qbit-table-header title="API_KEYS" [options]="tableOptions" [isLoading]="isLoading">
        <div *ngIf="allowAdd">
            <qbit-add-button *appPermissions="addPermission" (add)="addKeys()"></qbit-add-button>
        </div>

        <table-settings-button (settingsChanged)="settingsChanged()" [tableId]="tableId"></table-settings-button>
        <button mat-icon-button (click)="onSearch(searchParams)">
            <mat-icon>refresh</mat-icon>
        </button>
    </qbit-table-header>

    <table mat-table [dataSource]="dataSource" matSort class="hoverable" (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" [trackBy]="trackById">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NAME'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'ACCOUNT'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <mat-chip-list>
                    <mat-chip routerLink="/accounts/{{row.account.id}}" class="mat-chip mat-primary mat-standard-chip">
                        {{row.account.name}}
                    </mat-chip>
                </mat-chip-list>
            </td>
        </ng-container>

        <ng-container matColumnDef="updated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'UPDATED'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <i matTooltip="{{row.updated_at | date:'long'}}">{{row.updated_at |
                    date}}</i></td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item options menu"
                    (click)="$event.preventDefault(); $event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="col-error" (click)="openRemoveConfirmation(row.id)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">delete</mat-icon>
                        <span>{{'REMOVE'|translate}}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>


        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <qbit-list-loading *ngIf="!hasData && isLoading"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</mat-card>