import { TrashItem } from './../../../entities/trash-items';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudBaseService } from '../../../base/crud.base.service';
import { AuthService } from '../../auth.service';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class TrashService extends CrudBaseService<TrashItem> {
  constructor(http: HttpClient, auth: AuthService) {
    super(
      {
        endpoint: 'trash',
      },
      http,
      auth
    );
  }

  public listAll(params?: { [key: string]: string }, userType?: string) {
    return super.listAll(params, userType).pipe(
      map((el: any) => {
        el.data = el.data.map(TrashItem.fromJson);
        return el;
      })
    );
  }
}
