import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  QEventsService,
  QSnackBar,
  QTableBase,
} from '@qbitartifacts/qbit-kit-ng';
import { TableSettingsService } from '../services/table-settings.service';

@Component({
  template: '',
})
export abstract class LogicTablePageBase<T> extends QTableBase<T> {
  abstract tableId: string;

  constructor(
    public snackbar: QSnackBar,
    public events: QEventsService,
    public router: Router,
    public route: ActivatedRoute,
    public settings: TableSettingsService
  ) {
    super(snackbar, events, router, route);
    this.initialSearch = true;
  }

  ngOnInit() {
    if (!this.tableId || this.tableId.length <= 0) {
      throw new Error(
        '[LogicTablePageBase] required "tableId" to not be empty'
      );
    }

    this.settingsChanged();
    super.ngOnInit();
  }

  public settingsChanged() {
    this.autoRefresh = this.settings.getSettingFor(this.tableId).refreshEnabled;
  }
}
