import { StrategiesService } from './../../../services/CRUD/logic-traders/strategies.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-strategy-selector',
  templateUrl: './strategy-selector.component.html',
  styleUrls: ['./strategy-selector.component.scss'],
})
export class StrategySelectorComponent implements OnInit {
  public strategies: any[] = [];
  @Input() public strategy: any = null;
  @Input() public selectFirstByDefault = true;
  @Input() public isNullable = false;
  @Input() public title = 'CHOOSE_STRATEGY';

  @Output() public strategyChange: EventEmitter<string> = new EventEmitter();
  @ViewChild('search', { static: false }) public searchElement: ElementRef;

  public query: string;

  constructor(public strategies$: StrategiesService) {}

  /* istanbul ignore next */
  selectStrategy(strategy) {
    this.strategy = strategy;
    this.strategyChange.emit(strategy);
  }

  /* istanbul ignore next */
  public setSelected() {
    if (typeof this.strategy === 'string') {
      const foundRunner = this.strategies.find((el) => el.id === this.strategy);
      this.selectStrategy(foundRunner);
    } else if (!this.strategy && this.selectFirstByDefault) {
      this.selectStrategy(this.strategies[0]);
    }
  }

  /* istanbul ignore next */
  ngOnInit() {
    this.search();
  }

  /* istanbul ignore next */
  public search(query?: string) {
    this.strategies$.listAll(null, 'public').subscribe(
      (strategies: any) => {
        this.strategies = strategies.data;
        this.setSelected();
      },
      (err) => {}
    );
  }

  /* istanbul ignore next */
  public setupDebouncedSearch(element) {
    fromEvent(element, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(250),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.search(text);
      });
  }
}
