import { Instance } from '../../../entities/instance';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/base/loadable.page';
import { InstancesService } from 'src/app/services/CRUD/logic-traders/instances.service';

import { AppService } from 'src/app/services/app.service';
import { IRunner } from 'src/app/entities/runner.ent';
import {
  CasteUserService,
  PermissionAdmin,
} from '@qbitartifacts/caste-client-ng';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-edit-instance',
  templateUrl: './edit-instance.component.html',
  styleUrls: ['./edit-instance.component.scss'],
})
export class EditInstanceComponent implements LoadableComponent {
  public instanceDetailsForm: FormGroup;
  public isLoading: boolean;
  public statuses: string[] = Instance.STATUSES;
  public controls: string[] = Instance.CONTROLS;

  public itemData: any;
  public account: any;
  public strategy: any;
  public runner: IRunner;
  public forceAccountSelectorForAdmin = false;

  public changeAccountPermission = PermissionAdmin;
  public changeStrategyPermission = PermissionAdmin;
  public changeControlPermission = PermissionAdmin;

  public isInvestor: boolean;
  public isTrader: boolean;
  public isAdmin: boolean;
  public isSupport: boolean;
  public isPro: boolean;

  constructor(
    public dialogRef: MatDialogRef<EditInstanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    private instances$: InstancesService,
    public app$: AppService,
    public user$: CasteUserService
  ) {
    this.forceAccountSelectorForAdmin = data.forceAccountSelectorForAdmin;
    this.getInstance();
  }

  ngOnInit() {
    this.setupPermissions();
  }

  public setupPermissions() {
    this.isInvestor = this.user$.isInvestor();
    this.isTrader = this.user$.isTrader();
    this.isAdmin = this.user$.isAdmin();
    this.isSupport = this.user$.isSupport();
    this.isPro = this.user$.isPro();
  }

  /* istanbul ignore next */
  getInstance() {
    this.instances$.getOne(this.data.id).subscribe((resp) => {
      this.itemData = resp;
      this.strategy = this.itemData.strategy;
      this.createForm();
    });
  }

  /* istanbul ignore next */
  editInstance() {
    if (this.instanceDetailsForm.invalid) {
      return false;
    }

    this.setIsLoading(true);
    this.dialogRef.disableClose = true;

    const data: any = {};

    if (this.name.dirty) {
      data.name = this.name.value;
    }
    if (this.status.dirty) {
      data.status = this.status.value;
    }
    if (this.status.dirty) {
      data.status = this.status.value;
    }
    if (this.control.dirty) {
      data.control = this.control.value;
    }
    if (this.account) {
      data.account_id = this.account.id;
    }
    if (this.runner) {
      data.runner = this.runner['@id'];
    }
    if (this.strategy) {
      data.strategy = this.strategy['@id'];
    }
    if (this.discount != null) {
      data.discount = this.discount.value;
    }

    this.instances$.update(this.data.id, data).subscribe(
      (resp) => {
        this.snackbar.open('EDITED_ITEM_OK');
        this.close(true);
      },
      (err) => {
        this.snackbar.open(err.message || err.detail);
        this.setIsLoading(false);
        this.dialogRef.disableClose = false;
      }
    );
  }

  /* istanbul ignore next */
  async createForm() {
    this.instanceDetailsForm = this.formBuilder.group({
      name: [this.itemData.name, Validators.required],
      status: [this.itemData.status, Validators.required],
      control: [this.itemData.control, Validators.required],
      discount: [this.itemData.discount],
    });
  }

  get name() {
    return this.instanceDetailsForm.get('name');
  }

  get status() {
    return this.instanceDetailsForm.get('status');
  }

  get control() {
    return this.instanceDetailsForm.get('control');
  }

  get discount() {
    return this.instanceDetailsForm.get('discount');
  }

  accountSelected($event) {
    this.account = $event;
  }

  strategyChanged($event) {
    this.strategy = $event;
  }

  runnerChanged($event) {
    this.runner = $event;
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
