import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';

import { AuthedGuard } from 'src/app/guards/authed.guard';
import { QEventsService } from 'src/app/services/events.service';
import { ItemPageBaseComponent } from 'src/app/base/item.page.base';
import { AccountsService } from 'src/app/services/overrides/accounts.service';
import { PermissionsListComponent } from 'src/app/components/lists/permissions-list/permissions-list.component';
import { DialogsService } from 'src/app/services/dialogs.service';
import { AppService } from 'src/app/services/app.service';

import { CasteUserService, Account } from '@qbitartifacts/caste-client-ng';
import { QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent extends ItemPageBaseComponent implements OnInit {
  static guards: any[] = [AuthedGuard];
  public title = 'ACCOUNT';

  @ViewChild('permissions', { static: false })
  public permissionsComponent: PermissionsListComponent;

  constructor(
    public title$: Title,
    public translate$: TranslateService,
    public accounts$: AccountsService,
    public route: ActivatedRoute,
    public router: Router,
    public events: QEventsService,
    public user$: CasteUserService,
    public breadcrumbs$: QBreadcrumbsService
  ) {
    super(title$, translate$, route, events, user$, breadcrumbs$);
  }

  ngOnInit() {
    this.getAccountData();
  }

  /* istanbul ignore next */
  public getAccountData() {
    this.accounts$
      .getOne(this.itemId)
      .pipe(map((el) => Account.fromJson(el)))
      .subscribe((resp) => {
        this.itemData = resp;

        setTimeout(() => {
          this.permissionsComponent.setData(this.itemData.permissions);
        });
      });
  }
}
