<button id="user-menu" mat-icon-button [matMenuTriggerFor]="menu" aria-label="User menu">
    <ngx-avatar size="40" [name]="user$.user ? user$.user.username: ''"></ngx-avatar>
</button>
<mat-menu #menu="matMenu">
    <div class="padd-h-20 padd-v-15 column center" *ngIf="user$.user">
        <h4 class="no-margin title">{{user$.user ? user$.user.username: ''}}</h4>
        <code *appPermissions="adminPermission" class="admin-badge">ADMIN</code>
    </div>
    <hr>
    <mat-checkbox class="marg-left-15" (change)="changeDarkTheme($event)" [checked]="app$.darkThemeEnabled">
        <span class="title">Dark Theme</span>
    </mat-checkbox>
    <hr>
    <button id="profile" routerLinkActive="active" routerLink="/profile" mat-menu-item aria-label="Goes to Profile">
        <mat-icon class="icon-small">face</mat-icon>
        <span>{{'PROFILE'|translate}}</span>
    </button>
    <button id="logout" mat-menu-item aria-label="Exits the app" (click)="logout()">
        <mat-icon class="icon-small">exit_to_app</mat-icon>
        <span>{{'LOGOUT'|translate}}</span>
    </button>
</mat-menu>