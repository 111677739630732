/* tslint:disable */
/* istanbul ignore file */
import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as Highcharts from 'highcharts';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from 'src/app/entities/currency';
import { leverageToLogarithmicScale } from 'src/app/utils';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let solidgauge = require('highcharts/modules/solid-gauge');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
solidgauge(Highcharts);

const formatters = {
  time: (val) => Highcharts.dateFormat('%e of %b %H:%M', val),
};

@Component({
  selector: 'risk-chart',
  styleUrls: ['./risk-chart.css'],
  templateUrl: './risk-chart.component.html',
})
export class RiskChart {
  public chartRisk_: Highcharts.Chart;

  @Input() public type = 'area';
  @Input() public height = '150px';
  @Input() public currency: Currency;
  @Input() public showBorder = false;
  @Input() public timeframes = RiskChart.DefaultTimeframes;
  @Input() public selectedTimeframe: any = RiskChart.DefaultTimeframes[0];
  @Input() public colors = RiskChart.DefaultColors;
  @Input() public labels = ['A', 'B'];
  @Input() public data = [];
  @Input() public twoAxis = false;
  @Output('changed')
  public changedTimeframe: EventEmitter<any> = new EventEmitter();

  public options: any = {};

  constructor(
    @Inject(DOCUMENT) public document,
    public translate: TranslateService
  ) {}

  private createChartOptions({ title = 'Risk Indicator', value, color }): any {
    return {
      chart: {
        type: 'solidgauge',
        height: this.height,
      },

      title: null,

      pane: {
        center: ['50%', '85%'],
        size: '100%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },

      exporting: {
        enabled: false,
      },

      tooltip: {
        borderColor: '#ccd6eb',
        borderRadius: 2,
        shadow: false,
        backgroundColor: '#ffffff',
        useHTML: true,
        formatter: function () {
          return '<b>Risk Indicator</b></br> ' + `<code>${this.y}%</code>`;
        },
      },
      colors: [color],

      // the value axis
      yAxis: {
        stops: [
          [0.1, '#66bb6a'], // green
          [0.5, '#ffca28'], // yellow
          [0.9, '#ef5350'], // red
        ],
        min: 0,
        max: 100,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        title: {
          // y: -70,
        },
        tickAmount: 2,
        labels: {
          y: 16,
        },
      },
      credits: {
        enabled: false,
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },

      series: [
        {
          name: title,
          data: [+value.toFixed(0)],
          dataLabels: {
            format:
              '<div style="text-align:center">' +
              '<span style="font-size:20px">{y}%</span><br/>' +
              '</div>',
          },
        },
      ],
    };
  }

  public update(risk: number) {
    this.chartRisk_ = Highcharts.chart(
      'risk',
      this.createChartOptions({
        title: 'Leverage',
        value: leverageToLogarithmicScale(risk),
        color: '#55BF3B',
      })
    );
    this.chartRisk_.legend.update({
      enabled: false,
    });
    this.chartRisk_.reflow();
  }
  public getSymbol() {
    return this.currency ? this.currency.symbol : '';
  }

  public getDecimals() {
    return this.currency ? this.currency.decimal_points : 2;
  }

  public ngOnDestroy() {
    (window as any).removeAllListeners('resize');
  }

  public selectTimeframe(timeframe) {
    this.selectedTimeframe = timeframe;
    this.changedTimeframe.emit(timeframe.value);
    try {
      this.chartRisk_.redraw();
    } catch (error) {}
  }

  static DefaultTimeframes = [
    {
      name: 'Last Year',
      value: 'year',
    },
    {
      name: 'Last Month',
      value: 'month',
    },
    {
      name: 'Last Day',
      value: 'day',
    },
  ];

  static DefaultColors = ['#0098db', '#9ed7f1'];
}
