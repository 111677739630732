import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { QEventsService } from 'src/app/services/events.service';
import { AppService } from 'src/app/services/app.service';
import { QSidemenuService } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  public environment = environment;

  constructor(
    public events: QEventsService,
    public app$: AppService,
    public sidemenuService: QSidemenuService
  ) {}

  public toggleSidemenu() {
    this.sidemenuService.toggle();
  }

  /* istanbul ignore next */
  public removeImpersonation() {
    this.app$.removeImpersonation();
    this.events.fire('account:changed');
  }
}
