import { SerializableEntity } from './../base/serializable-entity.base';
export interface ITrashItem {
  id?: string;
  payload?: any;
  created_at?: string;
  updated_at?: string;
  undelete?: boolean;
}

export type TrashItemPayload = {
  [k: string]: string;
  id: string;
  name?: string;
};

export class TrashItem implements ITrashItem {
  id?: string;
  payload?: TrashItemPayload;

  payloadType? = '---';
  payloadIri? = '---';
  payloadName? = '---';

  // tslint:disable-next-line: variable-name
  created_at?: string;
  // tslint:disable-next-line: variable-name
  updated_at?: string;
  undelete?: boolean;

  static fromJson(obj: ITrashItem): TrashItem {
    const trashItem = new TrashItem();

    trashItem.id = obj.id;
    trashItem.created_at = obj.created_at;
    trashItem.updated_at = obj.updated_at;
    trashItem.undelete = obj.undelete;

    if (obj.payload) {
      trashItem.payload = obj.payload;
      trashItem.payloadType = obj.payload['@type'];
      trashItem.payloadIri = obj.payload['@id'];
      trashItem.payloadName = obj.payload.name;
    }

    return trashItem;
  }

  toJson(): ITrashItem {
    return {
      created_at: this.created_at,
      updated_at: this.updated_at,
      undelete: this.undelete,
      payload: this.payload,
    };
  }
}
