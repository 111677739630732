<qbit-dialog-header title="{{isEdit ? 'EDIT_CONFIGURATION': 'NEW_CONFIGURATION' | translate}}" (click)="close()">
</qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="form" (ngSubmit)="add()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-mutted marg-bottom-15">
                    {{(isEdit ? 'EDIT_CONFIGURATION_DESC':'NEW_CONFIGURATION_DESC') | translate}}
                </p>

                <mat-form-field>
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input type="text" id="name" matInput placeholder="{{'ENTER_NAME'| translate}}"
                        formControlName="name">
                    <mat-error *ngIf="name.invalid  && name.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'graphics_admin_dashboard_url' | translate}}</mat-label>
                    <input type="text" id="graphics_admin_dashboard_url" matInput
                        placeholder="{{'ENTER_URL'| translate}}" formControlName="graphics_admin_dashboard_url">
                    <mat-error
                        *ngIf="graphics_admin_dashboard_url.invalid  && graphics_admin_dashboard_url.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'graphics_admin_instance_url' | translate}}</mat-label>
                    <input type="text" id="graphics_admin_instance_url" matInput
                        placeholder="{{'ENTER_URL'| translate}}" formControlName="graphics_admin_instance_url">
                    <mat-error
                        *ngIf="graphics_admin_instance_url.invalid  && graphics_admin_instance_url.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'graphics_user_account_url' | translate}}</mat-label>
                    <input type="text" id="graphics_user_account_url" matInput placeholder="{{'ENTER_URL'| translate}}"
                        formControlName="graphics_user_account_url">
                    <mat-error *ngIf="graphics_user_account_url.invalid  && graphics_user_account_url.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'graphics_user_instance_url' | translate}}</mat-label>
                    <input type="text" id="graphics_user_instance_url" matInput placeholder="{{'ENTER_URL'| translate}}"
                        formControlName="graphics_user_instance_url">
                    <mat-error *ngIf="graphics_user_instance_url.invalid && graphics_user_instance_url.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="marg-bottom-20">
                    <mat-checkbox formControlName="enabled">
                        {{'CONFIGURATION_ENABLED' | translate}}
                    </mat-checkbox>
                </div>

                <div class="flex-row end">
                    <qbit-loadable-button *ngIf="isEdit" [id]="'createInstance'"
                        [disabled]="form.pristine || form.invalid" [loading]="isLoading">
                        {{(isLoading ? 'EDITING': 'EDIT') |translate}}
                    </qbit-loadable-button>
                    <qbit-loadable-button *ngIf="!isEdit" [id]="'createInstance'"
                        [disabled]="form.pristine || form.invalid" [loading]="isLoading">
                        {{(isLoading ? 'ADDING': 'ADD') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>