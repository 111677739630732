<qbit-dialog-header title="{{(isEdit ? 'EDIT_RUNNER': 'NEW_RUNNER')|translate}}" (click)="close()"></qbit-dialog-header>


<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="runnerForm" (ngSubmit)="addRunner()">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-top-10 marg-bottom-10">
                    {{(isEdit ? 'EDIT_RUNNER': 'NEW_RUNNER')|translate}}
                </h4>

                <mat-form-field>
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input type="text" id="name" matInput placeholder="Enter a name" formControlName="name">
                    <mat-error *ngIf="name.invalid && (name.dirty) && name.errors.required">
                        {{'FIELD_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'CAPACITY' | translate}}</mat-label>
                    <input type="text" id="capacity" matInput placeholder="Max number of instances attached"
                        formControlName="capacity">
                    <mat-error *ngIf="capacity.invalid && (capacity.dirty) && capacity.errors.required">
                        {{'FIELD_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'STATUS' | translate}}</mat-label>
                    <mat-select [formControl]="status">
                        <mat-option *ngFor="let status_ of statuses" [value]="status_">
                            {{status_ | translate}}
                        </mat-option>
                    </mat-select>

                    <mat-error *ngIf="status.invalid && (status.dirty) && status.errors.required">
                        {{'FIELD_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="flex-row end" *ngIf="isEdit">
                    <qbit-loadable-button [id]="'createRunner'" [disabled]="runnerForm.invalid " [loading]="isLoading">
                        {{'EDIT' | translate}}
                    </qbit-loadable-button>
                </div>

                <div class="flex-row end" *ngIf="!isEdit">
                    <qbit-loadable-button [id]="'createRunner'" [disabled]="runnerForm.invalid" [loading]="isLoading">
                        {{'ADD' | translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>