<mat-card class="no-padding">
    <qbit-table-header title="BILL_HISTORY" [options]="tableOptions" [isLoading]="isLoading">
        <div *ngIf="allowAdd">
            <qbit-add-button *appPermissions="addPermission" (add)="addBill()"></qbit-add-button>
        </div>

        <table-settings-button (settingsChanged)="settingsChanged()" [tableId]="tableId"></table-settings-button>
        <button mat-icon-button (click)="onSearch(searchParams)">
            <mat-icon>refresh</mat-icon>
        </button>
    </qbit-table-header>

    <qbit-table-filters *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        (filtersChanged)="searchParamsChanged($event)" [filters]="searchParams"></qbit-table-filters>

    <table mat-table [dataSource]="dataSource" matSort class="hoverable" (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" [trackBy]="trackById">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NAME'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'AMOUNT'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <app-money-value [value]="row.amount" [onlyDecimals]="true" [localTransform]="false"></app-money-value>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STATUS'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <span class="status-{{row.status}}">{{row.status}}</span></td>
        </ng-container>

        <ng-container matColumnDef="instance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'INSTANCE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <mat-chip-list *ngIf="row.instance | asyncp as instance">
                    <mat-chip routerLink="/instances/{{instance.id}}" class="mat-chip mat-primary mat-standard-chip">
                        {{instance.name}}
                    </mat-chip>
                </mat-chip-list>
            </td>
        </ng-container>

        <ng-container matColumnDef="strategy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'STRATEGY'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                {{row.instance.strategy.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button *ngIf="isAdmin" [matMenuTriggerFor]="menu" aria-label="Item options menu"
                    (click)="$event.preventDefault(); $event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="isAdmin" (click)="$event.stopPropagation(); editBill(row)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">edit</mat-icon>
                        <span>{{'EDIT'|translate}}</span>
                    </button>
                    <button mat-menu-item *ngIf="isAdmin" class="col-error"
                        (click)="$event.stopPropagation(); openRemoveConfirmation(row.id)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">delete</mat-icon>
                        <span>{{'REMOVE'|translate}}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>


        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="viewBill(row)">
        </tr>
    </table>

    <qbit-list-loading *ngIf="isLoading"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>

    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>