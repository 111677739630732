<qbit-dialog-header title="{{(isEdit ? 'EDIT_BILL': 'NEW_BILL')|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="billForm" (ngSubmit)="addBill()">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-top-10">
                    {{(isEdit ? 'EDIT_BILL': 'NEW_BILL')|translate}}
                </h4>

                <div>
                    <app-instance-selector (instanceChange)="selectInstance($event)" [instance]="instance">
                    </app-instance-selector>
                </div>

                <mat-form-field>
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input id="name" matInput placeholder="Enter the bill name" formControlName="name" required>
                    <mat-error *ngIf="name.invalid && (name.dirty) && name.errors.required">
                        {{'NAME_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'AMOUNT' | translate}}</mat-label>
                    <input type="number" id="amount" matInput placeholder="Enter your amount" formControlName="amount"
                        (input)="amountChanged($event)">

                    <app-currency-selector [disabled]="calculate" matSuffix [currentCurrency]="currency"
                        (currencyChange)="currencyChanged($event)">
                    </app-currency-selector>
                    <mat-error *ngIf="amount.invalid && (amount.dirty) && amount.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'FEE' | translate}}</mat-label>
                    <input type="number" id="fee" matInput placeholder="Enter the bill fee" formControlName="fee">
                    <mat-error *ngIf="fee.invalid && (fee.dirty) && fee.errors.required">
                        {{'FEE_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div>
                    <mat-checkbox [(ngModel)]="calculate" (ngModelChange)="calculateChanged($event)"
                        [ngModelOptions]="{standalone: true}">
                        {{'CALCULATE_AMOUNT' | translate}}
                    </mat-checkbox>
                </div>

                <br>

                <mat-form-field appearance="fill">
                    <mat-label>{{'DATE_RANGE'| translate}}</mat-label>
                    <mat-date-range-input [rangePicker]="picker" required>
                        <input matStartDate formControlName="period_start" required
                            placeholder="{{'START_DATE'|translate}}">
                        <input matEndDate formControlName="period_end" required placeholder="{{'END_DATE'|translate}}">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                    <mat-error *ngIf="period_start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                    <mat-error *ngIf="period_end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="isAdmin">
                    <mat-label>{{'STATUS' | translate}}</mat-label>
                    <mat-select [(ngModel)]="status" [ngModelOptions]="{standalone: true}" (ngModelChange)="selectStatus($event)">
                        <mat-option *ngFor="let stat of STATUSES" [value]="stat">
                            {{stat}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'createInstance'" [disabled]="billForm.invalid" [loading]="isLoading">
                        {{(isLoading ? (isEdit ? 'EDITING': 'ADDING'): isEdit ? 'EDIT' :'ADD') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>