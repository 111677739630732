<div class="bootstrap-wrapper">
    <div class="padd-h-15 padd-v-15" style="min-width:200px">
        <div class="flex-row center h v">
            <ngx-avatar size="35" [name]="account ? account.name: ''"></ngx-avatar>
            <span class="marg-left-15 text-title">{{account.name}}</span>
        </div>

        <hr>

        <div class="row marg-bottom-10 marg-top-15">
            <div class="col-lg-6">
                <div class="flex-row center v h" style="height:100%">
                    <b class="text-small">{{'EXTERNAL_ID'|translate}}</b>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="flex-row center v h">
                    <button *ngIf="account.details | asyncp as details" mat-stroked-button ngxClipboard
                        [cbContent]="details.reference" (cbOnSuccess)="copied($event)">
                        <span class="text-small">{{details.reference || '----'}}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="row marg-bottom-10">
            <div class="col-6">
                <div class="flex-row center v h" style="height:100%">
                    <b class="text-small">{{'INSTANCES'|translate}}</b>
                </div>
            </div>
            <div class="col-6">
                <div class="flex-row center v h">
                    <button mat-stroked-button routerLink="/admin/instances" [queryParams]="{account_id: account.id}">
                        <span class="text-small">{{instanceCount || '----'}}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="row marg-bottom-20">
            <div class="col-6">
                <div class="flex-row center v h" style="height:100%">
                    <b class="text-small">{{'BILLS'|translate}}</b>
                </div>
            </div>
            <div class="col-6">
                <div class="flex-row center v h">
                    <button mat-stroked-button routerLink="/admin/billing" [queryParams]="{account_id: account.id}">
                        <span class="text-small">{{billCount || '----'}}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="flex-row center" style="justify-content: center">
            <app-impersonate-account [account]="account"></app-impersonate-account>
        </div>
    </div>
</div>