import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AppService } from 'src/app/services/app.service';
import { CasteUserService, PermissionAdmin } from '@qbitartifacts/caste-client-ng';

@Component({
  selector: 'app-user-avatar-menu',
  templateUrl: './user-avatar-menu.component.html',
  styleUrls: ['./user-avatar-menu.component.scss'],
})
export class UserAvatarMenuComponent implements OnInit {
  public adminPermission = PermissionAdmin;

  constructor(
    public user$: CasteUserService,
    public auth$: AuthService,
    public app$: AppService
  ) {}

  /* istanbul ignore next */
  public changeDarkTheme($event) {
    this.app$.setDarkThemeEnabled($event.checked);
  }

  ngOnInit() {}

  public logout() {
    this.auth$.logout();
  }
}
