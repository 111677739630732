import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bill } from 'src/app/entities/bill';
import { BillingService } from 'src/app/services/CRUD/logic-traders/billing.service';
import { CreateStrategyComponent } from '../create-strategy/create-strategy.component';

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.scss'],
})
export class BillDetailsComponent {
  public title: string = 'Bill';
  public bill: Bill;

  constructor(
    public dialogRef: MatDialogRef<CreateStrategyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Bill,
    public billing$: BillingService
  ) {
    this.bill = { ...data };
    this.bill.applied_fee = this.bill.applied_fee * 100;
  }

  /* istanbul ignore next */
  close(val = false) {
    this.dialogRef.close(val);
  }
}
