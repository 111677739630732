<mat-card class="no-padding">
    <qbit-table-header title="STRATEGIES" [options]="tableOptions" [isLoading]="isLoading">
        <qbit-add-button (add)="addStrategy()" *appPermissions="addPermission"></qbit-add-button>

        <table-settings-button (settingsChanged)="settingsChanged()" [tableId]="tableId"></table-settings-button>
        <button mat-icon-button (click)="onSearch(searchParams)">
            <mat-icon>refresh</mat-icon>
        </button>
    </qbit-table-header>

    <qbit-table-filters *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        (filtersChanged)="searchParamsChanged($event)" [filters]="searchParams"></qbit-table-filters>

    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" [trackBy]="trackById">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NAME'|translate}} </th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'DESCRIPTION' | translate}} </th>
            <td mat-cell *matCellDef="let row">{{row.description}}</td>
        </ng-container>

        <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CURRENCY' | translate}} </th>
            <td mat-cell *matCellDef="let row">
                <span *ngIf="row.currency">({{row.currency.symbol}}) {{row.currency.name}}</span>
            </td>
        </ng-container>
        
        <ng-container matColumnDef="updated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'UPDATED'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <i matTooltip="{{row.updated_at | date:'long'}}">{{row.updated_at |
                    date}}</i></td>
        </ng-container>

        <ng-container matColumnDef="indicators">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'RISK_BENEFIT_INDICATOR'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <div style="width: 80px">
                    <app-risk-indicator tooltip="Risk" color="warn" [value]="row.indicators.risk * 100">
                    </app-risk-indicator>
                    <app-risk-indicator tooltip="Profit" color="accent" [value]="row.indicators.profit * 100">
                    </app-risk-indicator>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <div class="flex-row">
                    <button mat-icon-button (click)="editStrategy(row)" matTooltip="{{'EDIT'|translate}}"
                        class="text-mid">
                        <mat-icon class="mat-icon material-icons mat-icon-small">edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="openRemoveConfirmation(row.id)" class="col-error"
                        matTooltip="{{'REMOVE'|translate}}">
                        <mat-icon class="col-error mat-icon material-icons mat-icon-small">delete</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <qbit-list-loading *ngIf="isLoading && !hasData"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>


    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>