<div class="bootstrap-wrapper">
    <div class="flex-row bordered-bottom padd-left-10 padd-bottom-10 padd-top-4 center space-between">
        <span class="text-title">{{'NOTES' | translate}} ({{(notes || []).length}})</span>
        <button (click)="addNote()" mat-icon-button class="icon-btn-small text-mid" style="width: 25px; height: 25px; margin: 0 8px 0 0">
            <mat-icon class="mat-icon-mid">add</mat-icon>
        </button>
    </div>
    <div class="row no-margin" style="max-height: 200px;overflow-y: auto;">
        <div class="col-lg-12">
            <div *ngFor="let note of notes" class="flex-column marg-v-8 bordered-bottom padd-bottom-8">
                <div class="flex-row space-between center v marg-bottom-8">
                    <span class="text-subtitle-light">{{note.author}}</span>
                    <span class="text-mutted" matTooltip="{{note.updated_at | date: 'long'}}">
                        {{note.updated_at | date : 'short'}}
                    </span>
                </div>

                <span class="text-mid">{{note.message}}</span>
            </div>
            <div *ngIf="!notes || notes.length<=0">
                <span class="text-mid">{{'NO_NOTES'|translate}}</span>
            </div>
        </div>
    </div>
</div>