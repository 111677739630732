import {
  InternalPermission,
  PermissionAdmin,
  PermissionSuperAdmin,
  PermissionSupportSidemenu,
  PermissionUser,
} from '@qbitartifacts/caste-client-ng';
import { QSidemenuItem } from '@qbitartifacts/qbit-kit-ng';
import { environment } from 'src/environments/environment';

export const SIDEMENU_ITEMS: QSidemenuItem[] = [
  {
    name: 'dashboard',
    icon: 'dashboard',
    label: 'DASHBOARD',
    route: '/dashboard',
    permission: PermissionUser,
  },
  {
    name: 'instances',
    icon: 'format_bold',
    label: 'INSTANCES',
    route: '/instances',
    permission: PermissionUser,
  },
  {
    name: 'strategies',
    icon: 'alt_route',
    label: 'STRATEGIES',
    route: '/strategies',
    permission: PermissionUser,
  },
  {
    name: 'billing',
    icon: 'credit_card',
    label: 'BILLING',
    route: '/billing',
    permission: PermissionUser,
  },

  // Admin items after separator
  {
    name: 'adminlabel',
    separator: true,
    label: 'ADMIN',
    permission: PermissionAdmin,
  },
  {
    name: 'admin_dashboard',
    icon: 'dashboard',
    label: 'DASHBOARD',
    route: '/admin/dashboard',
    permission: PermissionAdmin,
  },
  {
    name: 'admin_instances',
    icon: 'format_bold',
    label: 'INSTANCES',
    route: '/admin/instances',
    permission: PermissionAdmin,
  },
  {
    name: 'admin_strategies',
    icon: 'alt_route',
    label: 'STRATEGIES',
    route: '/admin/strategies',
    permission: PermissionAdmin,
  },
  {
    name: 'admin_runners',
    icon: 'play_arrow',
    label: 'RUNNERS',
    route: '/admin/runners',
    permission: PermissionAdmin,
  },
  {
    name: 'admin_billing',
    icon: 'credit_card',
    label: 'BILLING',
    route: '/admin/billing',
    permission: PermissionAdmin,
  },
  {
    name: 'admin_currencies',
    icon: 'attach_money',
    label: 'CURRENCIES',
    route: '/admin/currencies',
    permission: PermissionAdmin,
  },
  {
    name: 'admin_api_keys',
    icon: 'vpn_key',
    label: 'API_KEYS',
    route: '/admin/api_keys',
    permission: PermissionAdmin,
  },
  {
    name: 'admin_accounts',
    icon: 'group',
    label: 'ACCOUNTS',
    route: '/admin/accounts',
    permission: PermissionAdmin,
  },
  {
    name: 'trash',
    icon: 'delete',
    label: 'TRASH',
    route: '/admin/trash',
    permission: PermissionAdmin,
  },
  {
    name: 'configuration',
    icon: 'settings',
    label: 'CONFIGURATION',
    route: '/admin/config',
    permission: PermissionAdmin,
  },
  {
    name: 'caste_users',
    icon: 'person',
    label: 'USERS',
    route: environment.castePanelUrl + '/users?realm=' + environment.realm,
    isExternal: true,
    permission: PermissionAdmin,
  },
  // Support items
  {
    name: 'supportlabel',
    separator: true,
    label: 'SUPPORT',
    permission: PermissionSupportSidemenu,
  },
  {
    name: 'support_instances',
    icon: 'format_bold',
    label: 'INSTANCES',
    route: '/support/instances',
    permission: PermissionSupportSidemenu,
  },
  {
    name: 'support_billing',
    icon: 'credit_card',
    label: 'BILLING',
    route: '/support/billing',
    permission: PermissionSupportSidemenu,
  },

  // Versions
  {
    name: 'version_label',
    separator: true,
    label: 'VERSION',
    permission: PermissionUser,
  },
  {
    name: 'panel_version',
    keyValue: true,
    label: 'PANEL',
    value: 'v' + environment.version,
    permission: PermissionUser,
  },
];
