import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import {
  createTimer,
  QSidemenuService,
  QSnackBar,
} from '@qbitartifacts/qbit-kit-ng';

import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { AppService } from './services/app.service';
import { SessionCheckerService } from './services/session-checker.service';
import { IDLE_CHECK_ENABLED } from './config/consts';
import { QEventsService } from 'src/app/services/events.service';
import {
  CasteAuthService,
  CasteUserService,
  CasteUsersService,
  PermissionUser,
} from '@qbitartifacts/caste-client-ng';
import { CurrenciesService } from './services/CRUD/logic-traders/currencies.service';
import { AccountDetailsService } from './services/CRUD/logic-traders/account_details.service';
import { DialogsService } from './services/dialogs.service';
import { BillingService } from './services/CRUD/logic-traders/billing.service';
import { CrudBaseService } from './base/crud.base.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private hotkeysSubscriptions: Subscription[] = [];
  public title = environment.brand.title;
  public permissionUser = PermissionUser;
  public IDLE_CHECK_ENABLED = IDLE_CHECK_ENABLED;
  public activeRoute;
  public stopPolling = new Subject();
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    public user$: CasteUserService,
    public auth$: AuthService,
    public app$: AppService,
    public currencies$: CurrenciesService,
    public sessionChecker: SessionCheckerService,
    public events: QEventsService,
    public router: Router,
    public dialog: MatDialog,
    public location: Location,
    public casteAuth: CasteAuthService,
    public casteUsers: CasteUsersService,
    public accountDetails: AccountDetailsService,
    public dialogs: DialogsService,
    public snackbar: QSnackBar,
    public billing$: BillingService,
    public sidemenuService: QSidemenuService
  ) {
    this.setupEvents();
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    if (this.hotkeysSubscriptions && this.hotkeysSubscriptions.length) {
      this.hotkeysSubscriptions.forEach((sub) => sub.unsubscribe());
    }
    this.stopPolling.next();
  }

  ngOnInit() {
    /* istanbul ignore next */
    if (this.favIcon) this.favIcon.href = environment.brand.favicon;

    /* istanbul ignore next */
    if (this.auth$.isSessionSaved()) {
      this.recoverSessionAndUser();
    }

    // Setup locales and language
    this.app$.setUpLang();

    // Recover impersonated account
    /* istanbul ignore next */
    if (this.app$.hasSavedImpersonation()) {
      this.app$.recoverImpersonation();
      this.events.fire('account:changed');
    }
  }

  private setupEvents() {
    this.events.on(AuthService.LOGIN_EVENT).subscribe(async (resp) => {
      this.currencies$.recoverSelectedCurrency();
      await this.checkAccountDetails();

      this.checkBills();
      this.setupTimer();
      this.setApiVersion();
    });

    this.events.on(AuthService.LOGOUT_EVENT).subscribe((resp) => {
      this.auth$.removeSession();
      this.app$.removeImpersonation();
      this.user$.setUser(null);
      this.user$.setSelectedAccount(null);

      localStorage.removeItem('session');
      localStorage.removeItem('qbit:auth:token');
      localStorage.removeItem(SessionCheckerService.LS_IDLE_KEY);
      this.router.navigate(['/login']);
    });
  }

  /* istanbul ignore next */
  public updater() {
    return this.currencies$.loadAllCurrencies();
  }

  /* istanbul ignore next */
  public setupTimer() {
    createTimer(this.updater.bind(this), this.stopPolling).subscribe({
      next: (currencies) => {
        if (currencies.length <= 0) {
          this.snackbar.open('NO_CURRENCIES');
        }

        this.currencies$.currencies = currencies;
        this.currencies$.currenciesLoaded = true;
        this.currencies$.recoverSelectedCurrency();
        this.events.fire(CurrenciesService.CURRENCY_CHANGE);
      },
      error: (err) => {},
    });
  }

  /* istanbul ignore next */
  private recoverSessionAndUser() {
    const savedSession = this.auth$.recoverSession();
    if (savedSession && savedSession.isActive()) {
      const tokenData = CasteAuthService.decodeToken(savedSession.token);
      this.casteAuth.saveToken(savedSession.token);
      this.user$.setApplication(tokenData.application);
      this.user$.setUserFromTokenData(tokenData);

      // This must be executed after setting all data, it executes an event
      this.auth$.setSession(savedSession);
    } else {
      this.events.fire(AuthService.LOGOUT_EVENT);
    }
  }

  /* istanbul ignore next */
  private async checkAccountDetails() {
    // Check if app is bot4bit and ask for telegram code if not set already
    const isBot4Bit = this.user$.application.realm === 'bot4bit';
    const accountDetails = await this.getAccountDetails(
      this.user$.getAccountId()
    );
    const hasNoDetails = !accountDetails || accountDetails.length <= 0;
    const showAskForDetails = isBot4Bit && hasNoDetails;

    if (showAskForDetails) {
      this.dialogs.openCreateAccountDetails();
    }
  }

  /* istanbul ignore next */
  public getCurrentUser(id) {
    return this.casteUsers
      .getOne(id)
      .toPromise()
      .then((res) => res);
  }

  /* istanbul ignore next */
  public getAccountDetails(account_id) {
    return this.accountDetails
      .listAll({ account_id })
      .toPromise()
      .then((res: any) => res.data);
  }

  /* istanbul ignore next */
  checkBills() {
    CrudBaseService.getTotalFromRequest(
      this.billing$.listAll({
        status: 'unpaid',
        account_id: this.user$.getAccountId(),
      })
    ).subscribe((resp) => {
      if (resp > 0) {
        this.sidemenuService.updateItem('billing', { badge: resp });
      }
    });
  }

  /* istanbul ignore next */
  setApiVersion() {
    this.app$.getApiVersion().subscribe({
      next: (res) => {
        this.sidemenuService.addApiVersionItem(res.code);
      },
    });
  }
}
