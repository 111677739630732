import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Order } from 'src/app/entities/order';
import { AuthService } from 'src/app/services/auth.service';
import { CrudBaseService } from 'src/app/base/crud.base.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersService extends CrudBaseService<Partial<Order>> {
  constructor(http: HttpClient, auth: AuthService) {
    super(
      {
        endpoint: 'orders',
      },
      http,
      auth
    );
  }
}
