import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudBaseService, CRUD_ROLES } from '../../../base/crud.base.service';
import { AuthService } from '../../auth.service';
import { Currency } from 'src/app/entities/currency';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesService extends CrudBaseService<Currency> {
  constructor(http: HttpClient, auth: AuthService) {
    super(
      {
        endpoint: 'currencies',
      },
      http,
      auth
    );
  }

  static CURRENCY_CHANGE = 'currency:changed';
  public currencies: Currency[] = [];
  public selectedCurrency: Currency;
  public currenciesLoaded = false;

  /* istanbul ignore next */
  public loadAllCurrencies(role: string = CRUD_ROLES.public) {
    return this.listAll(null, role).pipe(
      map((el: any) => el.data),
      map((el) => el.map(Currency.fromJson))
    );
  }

  /* istanbul ignore next */
  public getCurrency(acronym: string) {
    if (this.currencies.length) {
      return this.currencies.find((el) => el.acronym === acronym);
    }
    return null;
  }

  /* istanbul ignore next */
  public selectCurrency(curr: Currency) {
    if (curr) {
      this.selectedCurrency = curr;
      localStorage.setItem('CURRENCY', JSON.stringify(this.selectedCurrency));
    }
  }

  public hasSavedCurrency() {
    return localStorage.getItem('CURRENCY') !== null;
  }

  /* istanbul ignore next */
  public recoverSelectedCurrency() {
    if (this.hasSavedCurrency()) {
      return this.selectCurrency(
        Currency.fromJson(JSON.parse(localStorage.getItem('CURRENCY')))
      );
    }

    this.selectCurrency(this.currencies[0]);
  }

  /* istanbul ignore next */
  public getTransformerForCurrency(currencyName: string | Currency) {
    const currency =
      typeof currencyName == 'string'
        ? this.getCurrency(currencyName)
        : currencyName;

    return (value) => {
      const currencySelected = this.selectedCurrency;
      
      if (currency.id == currencySelected.id) {
        return currency.format(value);
      }

      return currencySelected.to(currency.from(value));
    };
  }
}
