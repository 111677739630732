<div class="bootstrap-wrapper padd-v-20 padd-h-20 ">
    <div class="row">
        <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12" style="padding: 0">
            <h4>{{'USER'|translate}}</h4>
            <mat-card class="marg-bottom-15">
                <div class="centered" style="width: 100%;">
                    <ngx-avatar [name]="user$.user ? user$.user.username: ''"></ngx-avatar>
                    <h4 class="title-lg">{{user$.user ? user$.user.username: ''}}</h4>
                </div>

                <button (click)="recoverPassword()" mat-button class="abs-top-right"
                    color="primary">{{'CHANGE_PASSWORD'|translate}}</button>
            </mat-card>

            <h4>{{'ACCOUNTS'|translate}}</h4>
            <mat-card class="no-padding">
                <mat-tab-group mat-align-tabs="start">
                    <mat-tab style="height:100%" [label]="perm.account.name" *ngFor="let perm of permissions">
                        <div class="padd-15">
                            <app-account-manager [accountId]="perm.account.id"></app-account-manager>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card>
        </div>
    </div>
</div>