import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-aggregate',
  templateUrl: './aggregate.component.html',
  styleUrls: ['./aggregate.component.scss'],
})
export class AggregateComponent {
  @Input() size: 'xlg' | 'lg' | 'md' | 'sm' = 'md';
  @Input() icon: string;
  @Input() title: string;
  @Input() value: string | number;
  @Input() symbol: string;
  @Input() decimals = 2;
  @Input() showDecimals = true;
  @Input() hasShadow = true;
  @Input() compact = false;
  @Input() stacked = false;

  @Input() titleColor = 'gray';
  @Input() valueColor = 'gray';
  @Input() iconColor = 'gray';

  get decimalPipeOptions() {
    return `0.${this.decimals || 2}-${this.decimals || 2}`;
  }
}
