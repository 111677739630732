import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Currency } from 'src/app/entities/currency';
import { ChartTestData } from 'src/app/testing/mocks/chart.mock';
import { DashChart } from '../dash-chart/dash-chart.component';

@Component({
  selector: 'app-dashboard-stats-chart',
  templateUrl: './dashboard-stats-chart.component.html',
  styleUrls: ['./dashboard-stats-chart.component.scss'],
})
export class DashboardStatsChartComponent implements AfterViewInit {
  @ViewChild('txChartEarnings', { static: true })
  public txChartEarnings: DashChart;

  @Input() public statRange = 'last_30_days';
  @Input() public statType = 'profit_and_loss';
  @Input() public stats: any = {};
  @Input() public currency: Currency;

  ngAfterViewInit() {
    this.txChartEarnings.update(ChartTestData);
  }

  /* istanbul ignore next */
  updateChart() {
    this.txChartEarnings.update(this.stats[this.statType][this.statRange]);
  }

  /* istanbul ignore next */
  statsTypeChanged($event) {
    this.statType = $event.value;
    this.updateChart();
  }

  /* istanbul ignore next */
  chartRangeChanged($event) {
    this.statRange = $event.value;
    this.updateChart();
  }
}
