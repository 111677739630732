import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CasteUserService,
  PermissionUser
} from '@qbitartifacts/caste-client-ng';
import { QSnackBar, QTableListHeaderOptions } from '@qbitartifacts/qbit-kit-ng';
import { Observable } from 'rxjs/internal/Observable';
import { LoadableComponent } from 'src/app/base/loadable.page';
import { LogicTablePageBase } from 'src/app/base/logic.table-page.base';
import { IInstance } from 'src/app/entities/instance';
import { IStrategy } from 'src/app/entities/strategy';
import { AppService } from 'src/app/services/app.service';
import { InstancesService } from 'src/app/services/CRUD/logic-traders/instances.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { QEventsService } from 'src/app/services/events.service';
import { TableSettingsService } from 'src/app/services/table-settings.service';
import { Action } from '../../actions/instance-actions/instance-actions.component';

@Component({
  selector: 'app-instance-list',
  templateUrl: './instance-list.component.html',
  styleUrls: ['./instance-list.component.scss'],
})
export class InstanceListComponent
  extends LogicTablePageBase<IInstance>
  implements LoadableComponent, OnInit
{
  public tableId: string = 'table:instances';
  public displayedColumns: string[] = [
    'name',
    'strategy',
    'status',
    'orders',
    'leverage',
    'options',
  ];
  public searchColumns: string[] = [
    'name',
    'strategy',
    'status',
    'orders',
    'leverage',
    'options',
  ];
  public addPermission = PermissionUser;
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: false,
  };
  public isTrader = false;
  public isInvestor = false;
  public isAdmin = false;
  public isSupport = false;
  public isPro: boolean = false;

  public nameQuery = '';
  public filters = {};

  @Input() public showAccount = false;
  @Input() public forceAccountSelectorForAdmin = false;
  @Input() public forceAddInstanceForAdmin = false;

  constructor(
    public app$: AppService,
    public dialogs$: DialogsService,
    public events: QEventsService,
    public instances: InstancesService,
    public snackbar: QSnackBar,
    public user$: CasteUserService,
    public router: Router,
    public route: ActivatedRoute,
    public settings: TableSettingsService
  ) {
    super(snackbar, events, router, route, settings);
  }

  ngOnInit() {
    this.setupPermissions();
    this.displayedColumns = this.getColumns();
    this.searchColumns = this.getSearchColumns();
    super.ngOnInit();
  }

  /* istanbul ignore next */
  public onAccountChanged() {
    super.onAccountChanged();
    this.setupPermissions();
    this.displayedColumns = this.getColumns();
    this.searchColumns = this.getSearchColumns();
  }

  /* istanbul ignore next */
  public setupPermissions() {
    this.isInvestor = this.user$.isInvestor();
    this.isTrader = this.user$.isTrader();
    this.isAdmin = this.user$.isAdmin();
    this.isSupport = this.user$.isSupport();
    this.isPro = this.user$.isPro();
  }

  /* istanbul ignore next */
  public getRemoveItemObservable(id: string): Observable<any> {
    return this.instances.remove(id);
  }

  /* istanbul ignore next */
  public getSearchObservable(params) {
    return this.instances.listAll(params);
  }

  /* istanbul ignore next */
  addInstance() {
    /* istanbul ignore next */
    this.dialogs$
      .openCreateInstance({
        forceAccountSelectorForAdmin: this.forceAccountSelectorForAdmin,
      })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.query);
      });
  }

  /* istanbul ignore next */
  onAction($event: Action) {
    this.onSearch(this.searchParams);
  }

  public getColumns() {
    const columnMap = {
      name: true,
      strategy: true,
      account:
        this.showAccount && this.isTrader && !this.isAdmin && !this.isSupport,
      external_id: this.isAdmin || this.isSupport,
      status: true,
      control: this.isAdmin || this.isTrader,
      orders: this.isAdmin || this.isTrader,
      leverage: this.isAdmin || this.isTrader,
      options: true,
    };
    const columns = [];

    for (let key in columnMap) {
      const val = columnMap[key];
      if (val) {
        columns.push(key);
      }
    }
    return columns;
  }

  public getSearchColumns() {
    const columns = this.getColumns();
    const searchColumns = [];

    for (let col of columns) {
      searchColumns.push(col + '-search');
    }
    return searchColumns;
  }

  public getRemoveItemDialog(id: string): MatDialogRef<any, any> {
    return this.dialogs$.openConfirmDelete();
  }

  public getOwner(): string {
    return this.app$.getOwner();
  }

  public getStrategyLink(): string {
    return this.isAdmin ? `/admin/strategies`: `/strategies`;
  }
}
