<qbit-table-header title="PERMISSIONS" [options]="tableOptions"></qbit-table-header>

<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{'USER' | translate}} </th>
        <td mat-cell *matCellDef="let row">
            {{row.user.username}}
        </td>
    </ng-container>

    <ng-container matColumnDef="grants">
        <th mat-header-cell *matHeaderCellDef> {{'GRANTS' | translate}} </th>
        <td mat-cell *matCellDef="let row">
            <mat-chip-list>
                <mat-chip class="mat-chip mat-primary mat-standard-chip {{role.className}}"
                    *ngFor="let role of row.grants">{{role | translate}}</mat-chip>
            </mat-chip-list>
        </td>
    </ng-container>

    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef> {{'CREATED_AT'|translate}} </th>
        <td mat-cell *matCellDef="let row"> <i>{{row.created_at | date}}</i></td>
    </ng-container>

    <ng-container matColumnDef="options" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item options menu"
                (click)="$event.preventDefault(); $event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <a mat-menu-item [href]="createCasteLink(row)" target="_blank">
                    <mat-icon class="mat-icon material-icons mat-icon-small">launch</mat-icon>
                    <span>{{'VIEW_IN_CASTE'|translate}} </span>
                </a>
            </mat-menu>
        </td>
    </ng-container>

    <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
</table>

<div class="list-no-items" *ngIf="!permissions.length">
    <p class="text-light">{{'NO_ITEMS'|translate}}</p>
</div>