import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CasteUserService,
  PermissionAdmin
} from '@qbitartifacts/caste-client-ng';
import { QSnackBar, QTableListHeaderOptions } from '@qbitartifacts/qbit-kit-ng';
import { Observable } from 'rxjs/internal/Observable';
import { LoadableComponent } from 'src/app/base/loadable.page';
import { LogicTablePageBase } from 'src/app/base/logic.table-page.base';
import { IRunner } from 'src/app/entities/runner.ent';
import { AppService } from 'src/app/services/app.service';
import { RunnersService } from 'src/app/services/CRUD/logic-traders/runners.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { QEventsService } from 'src/app/services/events.service';
import { TableSettingsService } from 'src/app/services/table-settings.service';

@Component({
  selector: 'app-runners-list',
  templateUrl: './runners-list.component.html',
  styleUrls: ['./runners-list.component.scss'],
})
export class RunnersListComponent
  extends LogicTablePageBase<IRunner>
  implements LoadableComponent
{
  public tableId: string = 'table:runners';
  public displayedColumns: string[] = [
    'name',
    'status',
    'instance_count',
    'updated',
    'options',
  ];

  public addPermission = PermissionAdmin;
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: false,
  };

  @Input() allowAdd = false;

  constructor(
    public app$: AppService,
    public dialogs$: DialogsService,
    public events: QEventsService,
    public snackbar: QSnackBar,
    public runners$: RunnersService,
    public user$: CasteUserService,
    public router: Router,
    public route: ActivatedRoute,
    public settings: TableSettingsService
  ) {
    super(snackbar, events, router, route, settings);
  }

  /* istanbul ignore next */
  public getSearchObservable(params, userType: string) {
    return this.runners$.listAll(params, userType);
  }

  /* istanbul ignore next */
  public getRemoveItemObservable(id: string): Observable<any> {
    return this.runners$.remove(id, 'admin');
  }

  /* istanbul ignore next */
  public addRunner() {
    this.dialogs$
      .openCreateRunner()
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.query);
      });
  }

  /* istanbul ignore next */
  public editRunner(item: IRunner) {
    this.dialogs$
      .openCreateRunner({ isEdit: true, item })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.query);
      });
  }

  public getRemoveItemDialog(id: string): MatDialogRef<any, any> {
    return this.dialogs$.openConfirmDelete();
  }

  public getOwner(): string {
    return this.app$.getOwner();
  }
}
