<qbit-dialog-header title="{{'CLOSE_POSITION' | translate}}" (click)="close()"></qbit-dialog-header>


<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="form" (ngSubmit)="closePosition()">
        <div class="row">
            <div class="col-lg-12">
                <p class="text-small">
                    {{'CLOSE_POSITION_DESC'|translate}}
                </p>

                <div>
                    <mat-checkbox [(ngModel)]="limitClose" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="limitChanged($event)">
                        {{'LIMIT_CLOSE' | translate}}
                    </mat-checkbox>

                    <br>
                    <div class="padd-top-15">
                        <mat-form-field>
                            <mat-label>{{'PRICE' | translate}}</mat-label>
                            <input type="number" id="price" matInput placeholder="Enter the price"
                                formControlName="price">
                        </mat-form-field>
                    </div>
                </div>

                <div class="flex-row end">
                    <button mat-raised-button type="button" (click)="close()">
                        {{'CANCEL'| translate}}
                    </button>
                    <div class="padd-left-10">
                        <qbit-loadable-button [disabled]="form.invalid || isLoading" [loading]="isLoading">
                            {{(isLoading ? 'CLOSING': 'CLOSE')|translate}}
                        </qbit-loadable-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>