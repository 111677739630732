import { InstancesService } from 'src/app/services/CRUD/logic-traders/instances.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Instance } from 'src/app/entities/instance';

@Component({
  selector: 'app-instance-selector',
  templateUrl: './instance-selector.component.html',
  styleUrls: ['./instance-selector.component.scss'],
})
export class InstanceSelectorComponent implements OnInit {
  public instances: any[] = [];
  @Input() public instance: any = null;
  @Output() public instanceChange: EventEmitter<string> = new EventEmitter();
  @ViewChild('search', { static: false }) public searchElement: ElementRef;

  public query: string;

  constructor(public instances$: InstancesService) {}

  /* istanbul ignore next */
  selectInstance(instance) {
    this.instance = instance;
    this.instanceChange.emit(instance);
  }

  /* istanbul ignore next */
  public setSelected() {
    if (typeof this.instance === 'string') {
      const foundRunner = this.instances.find((el) => el.id === this.instance);
      this.selectInstance(foundRunner);
    }
    if (this.instance instanceof Instance) {
      const foundRunner = this.instances.find(
        (el) => el.id === this.instance.id
      );
      this.selectInstance(foundRunner);
    }
  }

  /* istanbul ignore next */
  ngOnInit() {
    this.search();
  }

  /* istanbul ignore next */
  public search(query?: string) {
    this.instances$.listAll(null, '').subscribe({
      next: (instances: any) => {
        this.instances = instances.data;
        this.setSelected();
      },
      error: (err) => {},
    });
  }

  /* istanbul ignore next */
  public setupDebouncedSearch(element) {
    fromEvent(element, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(250),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.search(text);
      });
  }
}
