import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from 'src/app/services/auth.service';
import { CrudBaseService } from 'src/app/base/crud.base.service';
import { Note } from 'src/app/entities/note';

@Injectable({
  providedIn: 'root',
})
export class NotesService extends CrudBaseService<Partial<Note>> {
  constructor(http: HttpClient, auth: AuthService) {
    super(
      {
        endpoint: 'notes',
      },
      http,
      auth
    );
  }
}
