import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/base/loadable.page';

import { OrdersService } from 'src/app/services/CRUD/logic-traders/orders.service';
import { Instance } from 'src/app/entities/instance';
import { PermissionAdmin } from '@qbitartifacts/caste-client-ng';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { bitmexAmountValidator } from 'src/app/validations/bitmex-amount';
import { Exchanges } from 'src/app/config/exchanges';

@Component({
  selector: 'app-create-order-form',
  templateUrl: './create-order.form.html',
})
export class CreateOrderFormComponent implements OnInit, LoadableComponent {
  public orderForm: FormGroup;
  public isLoading: boolean;
  public changeAccountPermission = PermissionAdmin;

  @Input() public instance: Instance;
  @Output() public closed: EventEmitter<any> = new EventEmitter<any>();

  public priceType = 'market';
  public priceTypes = ['market', 'limit'];

  constructor(
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    private orders$: OrdersService
  ) {}

  /* istanbul ignore next */
  addOrder() {
    if (this.orderForm.invalid) {
      return false;
    }

    this.setIsLoading(true);

    const orderData: any = {
      amount: this.amount.value,
      instance: `/instances/${this.instance.id}`,
    };
    if (this.priceType === 'limit') {
      orderData.price = this.price.value;
    }

    this.orders$.create(orderData).subscribe(
      (resp) => {
        this.snackbar.open('ADDED_ORDER_OK');
        this.close(true);
      },
      (err) => {
        this.snackbar.open(err.message || err.detail);
        this.setIsLoading(false);
      }
    );
  }

  /* istanbul ignore next */
  ngOnInit() {
    const isBitmex = this.instance.strategy.exchange == Exchanges.Bitmex;

    this.orderForm = this.formBuilder.group({
      amount: [
        null,
        Validators.compose([
          Validators.required,
          isBitmex ? bitmexAmountValidator() : null,
        ]),
      ],
      price: [null],
    });

    this.changePriceType('market');
  }

  /* istanbul ignore next */
  get amount() {
    return this.orderForm.get('amount');
  }

  /* istanbul ignore next */
  get price() {
    return this.orderForm.get('price');
  }

  /* istanbul ignore next */
  get isMarket() {
    return this.priceType === 'market';
  }

  /* istanbul ignore next */
  changePriceType(type: string) {
    this.priceType = type;

    if (this.priceType === 'market') {
      this.price.disable();
    } else {
      this.price.enable();
    }
  }

  /* istanbul ignore next */
  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  /* istanbul ignore next */
  close(val = false) {
    this.closed.emit(val);
  }
}
