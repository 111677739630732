import { AuthedGuard } from 'src/app/guards/authed.guard';
import { PageBaseComponent } from 'src/app/base/page.base';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { QEventsService } from 'src/app/services/events.service';
import { CasteUsersService } from '@qbitartifacts/caste-client-ng';
import { DialogsService } from 'src/app/services/dialogs.service';
import { CasteUserService } from '@qbitartifacts/caste-client-ng';
import { QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends PageBaseComponent {
  static guards: any[] = [AuthedGuard];

  public user: any;
  public permissions: any[];

  constructor(
    public title$: Title,
    public translate$: TranslateService,
    public events: QEventsService,
    public casteUsers: CasteUsersService,
    public dialogs: DialogsService,
    public user$: CasteUserService,
    public breadcrumbs$: QBreadcrumbsService
  ) {
    super(title$, translate$, events, breadcrumbs$, user$);
  }

  ngOnInit() {
    this.getUserInfo();
  }

  public getUserInfo() {
    this.casteUsers.getOne(this.user$.user.id).subscribe((resp) => {
      this.user = resp;
      this.permissions = resp.permissions;
    });
  }

  public recoverPassword() {
    this.dialogs.openChangePassword();
  }

  public title = 'PROFILE';
}
