<div class="flex-row">
    <button *ngIf="instance.notes" mat-icon-button [matMenuTriggerFor]="notesMenu"
        (click)="$event.stopPropagation();" aria-label="This menu shows a list of notes/messages for this instance"
        matTooltip="{{'NOTES' | translate}}">
        <div class="flex-row center h v">
            <mat-icon class="col-red">
                announcement
            </mat-icon>
        </div>
    </button>
    <mat-menu #notesMenu="matMenu">
        <app-notes-list [instance]="instance" [notes]="instance.notes"></app-notes-list>
    </mat-menu>

    <button mat-icon-button class="text-mid"
        *ngIf="instance.status === 'stopped' && (isAdmin || isPro || isInvestor || isTrader)"
        [disabled]="disabled || startStopDisabled" (click)="$event.stopPropagation(); onStart()"
        [matTooltip]="'PLAY'|translate">
        <mat-icon class="mat-icon-mid col-green">
            play_arrow
        </mat-icon>
    </button>

    <button mat-icon-button class="text-mid"
        *ngIf="instance.status === 'running' && (isAdmin || isInvestor || isPro || isTrader)"
        [disabled]="disabled || startStopDisabled" (click)="$event.stopPropagation(); onStop()"
        [matTooltip]="'STOP'|translate">
        <mat-icon class="mat-icon-mid col-red">
            stop
        </mat-icon>
    </button>

    <button mat-icon-button *ngIf="(instance.status != 'new' && isAdmin) || (isInvestor || isAdmin || isTrader || isPro)" [disabled]="disabled || editDisabled"
        (click)="$event.stopPropagation(); onEdit()" class="text-mid" [matTooltip]="'EDIT'|translate">
        <mat-icon class="mat-icon-mid">
            edit
        </mat-icon>
    </button>

    <button mat-icon-button *ngIf="(instance.status != 'new' && (isInvestor || isAdmin))" [disabled]="disabled || deleteDisabled"
        (click)="$event.stopPropagation(); onDelete()" class="text-mid" [matTooltip]="'DELETE'|translate">
        <mat-icon class="mat-icon-mid col-error">
            delete
        </mat-icon>
    </button>

    <button mat-icon-button *ngIf="isAdmin" [disabled]="disabled || deleteDisabled"
        (click)="$event.stopPropagation(); showState()" class="text-mid" [matTooltip]="'STATE'|translate">
        <mat-icon class="mat-icon-mid col-info">
            info
        </mat-icon>
    </button>

    <button mat-icon-button *ngIf="isAdmin" [disabled]="disabled || deleteDisabled"
        (click)="$event.stopPropagation(); showTradeList()" class="text-mid" [matTooltip]="'TRADES'|translate">
        <mat-icon class="mat-icon-mid col-info">
            list
        </mat-icon>
    </button>
</div>