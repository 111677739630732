import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from '../services/auth.service';
import { BaseService, DEFAULT_CONFIG } from '@qbitartifacts/caste-client-ng';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs/internal/observable/throwError';
import { countItems } from '../pipes/count-items';

export interface CrudServiceOptions {
  endpoint: string;
}

export const CRUD_ROLES = {
  public: 'public',
  admin: 'admin',
  none: '',
};

export const IGNORED_IRI_KEYS = ['@id', '@context'];

export abstract class CrudBaseService<
  T = any
> extends BaseService<CrudServiceOptions> {
  constructor(config: any, http: HttpClient, private auth: AuthService) {
    super(http, {
      ...DEFAULT_CONFIG,
      ...config,
      baseHeaders: {
        accept: 'application/ld+json',
      },
      url: environment.url,
    });
  }

  protected getToken() {
    return this.auth.session && this.auth.session.token;
  }

  protected createPathFromParts(userType: string, path: string) {
    return userType ? `/${userType}${path}` : path;
  }

  public create(data: Partial<T>, userType?: string): Observable<T> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}`
    );
    return this.post<T>(path, data);
  }

  public listAll(
    params?: { [key: string]: string },
    userType?: string
  ): Observable<T[]> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}`
    );
    return this.get<T[]>(path, params);
  }

  public getOne(id: string, userType?: string): Observable<T> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}/${id}`
    );
    return this.get<T>(path);
  }

  public update(
    id: string,
    data: Partial<T>,
    userType?: string
  ): Observable<T> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}/${id}`
    );
    return this.put<T>(path, data);
  }

  public remove(id: string, userType?: string): Observable<any> {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}/${id}`
    );
    return this.delete(path);
  }

  public getTotalItems(userType: string = ''): Observable<number> {
    return CrudBaseService.getTotalFromRequest(this.listAll({}, userType));
  }

  /* istanbul ignore next */
  public extractData(res: any) {
    // If response is a collection
    if (res && res['hydra:member']) {
      const data = res['hydra:member'];
      let mapping = [];

      if (res['hydra:search']) {
        mapping = res['hydra:search']['hydra:mapping'].map((el) => {
          if (el.variable.includes('order')) {
            el.type = 'order';
          } else if (!el.variable.includes('[]')) {
            el.type = 'search';
          }
          return el;
        });
      }

      return {
        data: data.map((el) => CrudBaseService.wrapIrisWithRequest(this, el)),
        search: mapping.filter((el) => el.type === 'search'),
        order: mapping.filter((el) => el.type === 'order'),
        total: res['hydra:totalItems'] ?? data.length,
      };
    }

    // If response is a single item
    return CrudBaseService.wrapIrisWithRequest(this, res);
  }

  /* istanbul ignore next */
  public handleError(err) {
    const error = err.originalError || err.error || err;

    if (error['hydra:description']) {
      error.message = error['hydra:description'];
    }

    return throwError(error);
  }

  public wrapIrisWithRequest(entry: object) {
    return CrudBaseService.wrapIrisWithRequest(this, entry);
  }

  static getTotalFromRequest(request: Observable<any>) {
    return request.pipe(countItems);
  }

  static isIRI(value: any) {
    const type = typeof value;
    if (type !== 'string') return false;

    // runner: "/admin/runners/787815ce-8da2-11eb-b745-02420a00052f"
    const IRI_REGEXP = /^[\/]([a-zA-Z0-9]+[\/]?)+([a-zA-Z0-9-]+)+$/gim;
    return IRI_REGEXP.test(value);
  }

  static wrapIrisWithRequest(
    service: CrudBaseService,
    entry: object,
    ignoreKeys: string[] = []
  ) {
    const newData = { ...entry };

    for (let key in newData) {
      if (IGNORED_IRI_KEYS.includes(key) || ignoreKeys.includes(key)) continue;

      const value = newData[key];
      const isIri = CrudBaseService.isIRI(value);

      if (!isIri) continue;

      newData[key] = service.get(value);
    }

    return newData;
  }
}
