import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import {
  CasteUserService,
  PermissionAdmin,
} from '@qbitartifacts/caste-client-ng';
import {
  DeleteDialogStatus,
  QSnackBar,
  QTableBase,
  QTableListHeaderOptions,
} from '@qbitartifacts/qbit-kit-ng';

import { Observable } from 'rxjs/internal/Observable';
import { TrashService } from 'src/app/services/CRUD/logic-traders/trash.service';
import { LoadableComponent } from 'src/app/base/loadable.page';
import { DialogsService } from 'src/app/services/dialogs.service';
import { QEventsService } from 'src/app/services/events.service';
import { AppService } from 'src/app/services/app.service';
import { ITrashItem, TrashItem } from 'src/app/entities/trash-items';
import { LogicTablePageBase } from 'src/app/base/logic.table-page.base';
import { TableSettingsService } from 'src/app/services/table-settings.service';

@Component({
  selector: 'app-trash-list',
  templateUrl: './trash-list.component.html',
  styleUrls: ['./trash-list.component.scss'],
})
export class TrashListComponent
  extends LogicTablePageBase<ITrashItem>
  implements LoadableComponent, OnInit
{
  @Input() allowAdd = false;

  public tableId: string = 'table:trash';
  public displayedColumns: string[] = ['type', 'name', 'id', 'options'];
  public addPermission = PermissionAdmin;
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: false,
  };

  constructor(
    public app$: AppService,
    public trash$: TrashService,
    public dialogs$: DialogsService,
    public events$: QEventsService,
    public router: Router,
    public route: ActivatedRoute,
    public snackbar: QSnackBar,
    public user$: CasteUserService,
    public settings: TableSettingsService
  ) {
    super(snackbar, events$, router, route, settings);
  }

  /* istanbul ignore next */
  ngOnInit() {
    this.isInvestor = this.user$.isInvestor();
    this.isTrader = this.user$.isTrader();
    this.isAdmin = this.user$.isAdmin();
    super.ngOnInit();
  }

  /* istanbul ignore next */
  public getSearchObservable(params) {
    return this.trash$.listAll(params, 'admin');
  }

  /* istanbul ignore next */
  public getRemoveItemObservable(_: string): Observable<any> {
    return null;
  }

  /* istanbul ignore next */
  public getRemoveItemDialog(_: string): MatDialogRef<any, any> {
    return null;
  }

  /* istanbul ignore next */
  public recoverItem(item: TrashItem) {
    this.dialogs$
      .openConfirmation({
        title: 'RECOVER_TRASH_TITLE',
        subtitle: 'RECOVER_TRASH_SUBTITLE',
        description: 'RECOVER_TRASH_DESCRIPTION',
        confirmButtonText: 'RECOVER_TRASH_ITEM',
      })
      .afterClosed()
      .subscribe({
        next: (status: DeleteDialogStatus) => {
          if (status === DeleteDialogStatus.DELETE) {
            this.setIsLoading(true);
            this.recoverTrashItem(item);
          }
        },
      });
  }

  /* istanbul ignore next */
  private recoverTrashItem(item: TrashItem) {
    this.trash$.update(item.id, { undelete: true }, 'admin').subscribe({
      next: () => this.restoredItem(item),
      error: this.restoreItemError.bind(this),
    });
  }

  /* istanbul ignore next */
  private restoredItem(item: TrashItem) {
    this.setIsLoading(false);
    this.onSearch();

    this.snackbar
      .open('RESTORED_ITEM', 'VIEW')
      .afterDismissed()
      .subscribe({
        next: () => this.goToRestoredItem(item),
      });
  }

  /* istanbul ignore next */
  private restoreItemError(err) {
    this.snackbar.open(err.message);
    this.setIsLoading(false);
  }

  /* istanbul ignore next */
  private goToRestoredItem(item: TrashItem) {
    this.router.navigate([item.payloadIri]);
  }

  /* istanbul ignore next */
  public getOwner(): string {
    return this.app$.getOwner();
  }
}
