<mat-button-toggle-group id="play-stop" name="playStop" aria-label="Font Style" [value]="state">
    <mat-button-toggle class="text-mid" id="play" value="play" (click)="changeState('play')">
        <mat-icon aria-hidden="false" aria-label="Play icon" style="padding: 0 10px;height:auto"
            [ngClass]="{'col-green': state == 'play'}">play_arrow
        </mat-icon>
        <span [ngClass]="{'col-green': state == 'play'}" class="marg-left-10">Start</span>
    </mat-button-toggle>
    <mat-button-toggle class="text-mid" id="stop" value="stop" (click)="changeState('stop')">
        <mat-icon aria-hidden="false" aria-label="Stop icon" style="padding: 0 10px;height:auto"
            [ngClass]="{'col-red': state == 'stop'}">stop</mat-icon>
        <span [ngClass]="{'col-red': state == 'stop'}" class="marg-left-10">Stop</span>
    </mat-button-toggle>
</mat-button-toggle-group>