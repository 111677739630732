import { Component, OnInit, AfterContentInit } from '@angular/core';
import { PageBaseComponent } from 'src/app/base/page.base';
import { Title } from '@angular/platform-browser';
import { AuthedGuard } from 'src/app/guards/authed.guard';
import { TranslateService } from '@ngx-translate/core';
import { QEventsService } from 'src/app/services/events.service';
import { CasteUserService } from '@qbitartifacts/caste-client-ng';
import { QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-support-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class SupportBillingComponent
  extends PageBaseComponent
  implements AfterContentInit {
  static guards: any[] = [AuthedGuard];
  public title = 'BILLING';
  public owner;

  constructor(
    title$: Title,
    public translate$: TranslateService,
    public events: QEventsService,
    public user$: CasteUserService,
    public breadcrumbs$: QBreadcrumbsService
  ) {
    super(title$, translate$, events, breadcrumbs$, user$);
    this.owner = this.user$.getAccountId();
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
  }
}
