import { NgModule } from '@angular/core';
import { AggregateComponent } from './aggregate/aggregate.component';
import { PlayStopComponent } from './play-stop/play-stop.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UserAvatarMenuComponent } from './user-avatar-menu/user-avatar-menu.component';
import { BotDetailsComponent } from './details/bot-details/bot-details.component';
import { UserDetailsComponent } from './details/user-details/user-details.component';
import { InstanceDetailsComponent } from './details/instance-details/instance-details.component';
import { KeyValueItem } from './kvp/kvp-item/kvp-item';
import { KeyValuePair } from './kvp/kvp-list/kvp-list';
import { StrategyListComponent } from './lists/strategies-list/strategies-list.component';
import { InstanceListComponent } from './lists/instance-list/instance-list.component';
import { DashChart } from './dash-chart/dash-chart.component';
import { SharedModule } from '../shared.module';
import { StrategySelectorComponent } from './selectors/strategy-selector/strategy-selector.component';
import { AccountsListComponent } from './lists/accounts-list/accounts-list.component';
import { BillingListComponent } from './lists/billing-list/billing-list.component';
import { RiskIndicatorComponent } from './risk-indicator/risk-indicator.component';
import { CurrencySelectorComponent } from './selectors/currency-selector/currency-selector.component';
import { DashboardStatsChartComponent } from './dashboard-stats-chart/dashboard-stats-chart.component';
import { CurrencyAggregateComponent } from './currency-aggregate/currency-aggregate.component';
import { OrdersListComponent } from './lists/orders-list/orders-list.component';
import { PositionsListComponent } from './lists/positions-list/positions-list.component';
import { AggregateWithPercentComponent } from './aggregate-with-percent/aggregate-with-percent.component';
import { CurrenciesListComponent } from './lists/currencies-list/currencies-list.component';
import { CreateOrderFormComponent } from './forms/create-order/create-order.form';
import { PermissionsListComponent } from './lists/permissions-list/permissions-list.component';
import { ImpersonationBannerComponent } from './impersonation-banner/impersonation-banner.component';
import { RunnersListComponent } from './lists/runners-list/runners-list.component';
import { RunnerSelectorComponent } from './selectors/runner-selector/runner-selector.component';
import { RiskChart } from './risk-chart/risk-chart.component';
import { ApiKeysListComponent } from './lists/api-keys-list/api-keys-list.component';
import { InstanceActionsComponent } from './actions/instance-actions/instance-actions.component';
import { AggregateGridComponent } from './aggregate-grid/aggregate-grid.component';
import { MoneyValueComponent } from './money-value/money-value.component';
import { InstanceSelectorComponent } from './selectors/instance-selector/instance-selector.component';
import { AccountManagerComponent } from './account-manager/account-manager.component';
import { PermissionsSelector } from './selectors/permissions-selector/permissions-selector.component';
import { ChangeControlComponent } from './actions/change-instance-control/change-control.component';
import { ProfitIndicatorComponent } from './profit-indicator/profit-indicator.component';
import { TrashListComponent } from './lists/trash-list/trash-list.component';
import { AccountDetailsComponent } from '../dialogs/account-details/account-details.component';
import { ImpersonationAccountComponent } from './impersonate-account/impersonate-account.component';
import { NotesListComponent } from './lists/notes-list/notes-list.component';
import { TableSettingsDialogButton } from './table-settings-button/table-settings-button.component';
import { CurrencyFormField } from './selectors/currency-form-field/currency-form-field.component';
import { InstanceTradeListComponent } from './lists/instance-trades-list/instance-trades-list.component';
import { InstanceOrderListComponent } from './lists/instance-orders-list/instance-orders-list.component';
import { GrafanaIframeComponent } from './grafana-iframe/grafana-iframe.component';
import { ConfigurationsListComponent } from './lists/configuration-list/configuration-list.component';

const components = [
  AggregateComponent,
  CurrencyAggregateComponent,
  PlayStopComponent,
  ToolbarComponent,
  UserAvatarMenuComponent,
  DashChart,
  RiskChart,
  RiskIndicatorComponent,
  DashboardStatsChartComponent,
  AggregateWithPercentComponent,
  ImpersonationBannerComponent,
  AggregateGridComponent,
  MoneyValueComponent,
  AccountManagerComponent,
  ProfitIndicatorComponent,
  ImpersonationAccountComponent,
  TableSettingsDialogButton,
  GrafanaIframeComponent,

  // Details Components
  BotDetailsComponent,
  InstanceDetailsComponent,
  UserDetailsComponent,
  AccountDetailsComponent,

  // Key value
  KeyValueItem,
  KeyValuePair,

  // Lists
  InstanceListComponent,
  StrategyListComponent,
  AccountsListComponent,
  BillingListComponent,
  OrdersListComponent,
  PositionsListComponent,
  CurrenciesListComponent,
  PermissionsListComponent,
  RunnersListComponent,
  ApiKeysListComponent,
  TrashListComponent,
  NotesListComponent,
  InstanceTradeListComponent,
  InstanceOrderListComponent,
  ConfigurationsListComponent,

  // Selectors
  StrategySelectorComponent,
  CurrencySelectorComponent,
  RunnerSelectorComponent,
  InstanceSelectorComponent,
  PermissionsSelector,
  CurrencyFormField,
  
  // Forms
  CreateOrderFormComponent,

  // Actions
  InstanceActionsComponent,
  ChangeControlComponent,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [SharedModule],
})
export class ComponentsModule {}
