import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { CasteUserService, Role } from '@qbitartifacts/caste-client-ng';

// This directive should manage wether certain components/elements are shouwn/disabled based on some permissions
@Directive({
  selector: '[hasRole]',
})
/* istanbul ignore class */
export class RoleDirective {
  public permitted = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private user$: CasteUserService
  ) {}

  /* istanbul ignore next */
  @Input('hasRole') set hasRole(role: Role) {
    if (this.user$.user.hasRole(role)) {
      this.permitted = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.permitted = false;
      this.viewContainer.clear();
    }
  }
}
