import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteDialogStatus } from '@qbitartifacts/qbit-kit-ng';
import { TableSettingsService } from 'src/app/services/table-settings.service';

export interface TableSettingsDialogData {
  /** string to identify this particular table settings instance, will be used to store data in localstorage */
  id: string;
}

@Component({
  selector: 'table-settings',
  templateUrl: './table-settings.component.html',
})
export class TableSettingsDialog {
  isRefreshEnabled = false;

  constructor(
    public service: TableSettingsService,
    public dialogRef: MatDialogRef<TableSettingsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: TableSettingsDialogData
  ) {}

  ngOnInit() {
    this.isRefreshEnabled = this.service.getSettingFor(
      this.data.id
    ).refreshEnabled;
  }

  refreshEnabledChanged(enabled: boolean) {
    this.isRefreshEnabled = enabled;
    this.service.setSettingFor(this.data.id, {
      id: this.data.id,
      refreshEnabled: this.isRefreshEnabled,
    });
  }

  close() {
    this.dialogRef.close();
  }
}
