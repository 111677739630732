import {
  QbitLocaleConfig,
  QbitLocaleMetadata,
  QBIT_LOCALES,
} from '@qbitartifacts/qbit-kit-ng';

const locales: QbitLocaleMetadata[] = [
  {
    name: 'es',
    abrev: 'es',
    icon: 'es',
  },
  {
    name: 'en',
    abrev: 'en',
    icon: 'gb',
  },
];

export const localeMap = {
  'es': 'es-es',
  'en': 'en-us',
};

export const qbitLocalesConfig: QbitLocaleConfig = {
  locales: locales,
  defaultLocale: locales[0],
};

export const qbitLocalesProvider = {
  provide: QBIT_LOCALES,
  useValue: qbitLocalesConfig,
};
