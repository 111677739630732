import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RunnersService } from 'src/app/services/CRUD/logic-traders/runners.service';

@Component({
  selector: 'app-runner-selector',
  templateUrl: './runner-selector.component.html',
  styleUrls: ['./runner-selector.component.scss'],
})
export class RunnerSelectorComponent implements OnInit {
  public runners: any[] = [];
  @Input() public runner: any = null;
  @Output() public runnerChange: EventEmitter<string> = new EventEmitter();
  @ViewChild('search', { static: false }) public searchElement: ElementRef;

  public query: string;

  constructor(public runners$: RunnersService) {}

  /* istanbul ignore next */
  selectRunner(runner) {
    this.runner = runner;
    this.runnerChange.emit(runner);
  }

  /* istanbul ignore next */
  public setSelectedRunner() {
    if (typeof this.runner === 'string') {
      const foundRunner = this.runners.find((el) => el.id === this.runner);
      this.selectRunner(foundRunner);
    }
  }

  /* istanbul ignore next */
  ngOnInit() {
    this.search();
  }

  /* istanbul ignore next */
  public search(query?: string) {
    this.runners$.listAll({}, 'admin').subscribe({
      next: (runners: any) => {
        this.runners = runners.data;
        this.setSelectedRunner();
      },
      error: (err) => {},
    });
  }

  /* istanbul ignore next */
  public setupDebouncedSearch(element) {
    fromEvent(element, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(250),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        this.search(text);
      });
  }
}
