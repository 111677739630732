<qbit-table-header title="POSITION" [options]="tableOptions"></qbit-table-header>

<div style="max-height: 200px; overflow-y:auto;">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> {{'AMOUNT'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                {{row.amount.representation}}
            </td>
        </ng-container>

        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> {{'PRICE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                {{row.price.representation}}
            </td>
        </ng-container>

        <ng-container matColumnDef="leverage">
            <th mat-header-cell *matHeaderCellDef> {{'LEVERAGE'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <code>{{row.leverage}}</code></td>
        </ng-container>

        <ng-container matColumnDef="liquidation">
            <th mat-header-cell *matHeaderCellDef> {{'LIQUIDATION'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                {{row.liquidation.representation}}
            </td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button class="col-error" (click)="openRemoveConfirmation(row)" *ngIf="!disableActions">
                    <mat-icon color="warn" class="col-error mat-icon material-icons mat-icon-small">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>
</div>
<div class="list-no-items" *ngIf="!positions.length">
    <p class="text-light">{{'NO_ITEMS'|translate}}</p>
</div>