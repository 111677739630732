import { Component, Input, OnInit } from '@angular/core';
import { Money } from 'src/app/entities/money.ent';
import { CurrenciesService } from 'src/app/services/CRUD/logic-traders/currencies.service';

@Component({
  selector: 'app-money-value',
  templateUrl: './money-value.component.html',
  styleUrls: ['./money-value.component.scss'],
})
export class MoneyValueComponent implements OnInit {
  @Input() value: Money;
  @Input() localTransform = false;
  @Input() onlyDecimals = false;

  public symbol = '';
  public amount = 0;

  constructor(public currencies$: CurrenciesService) {}

  /* istanbul ignore next */
  ngOnInit(): void {
    const transformer = this.currencies$.getTransformerForCurrency(
      this.value.currency.acronym
    );

    this.value = Money.fromJson(this.value);
    this.symbol = this.value.currency.symbol;

    if (this.localTransform) {
      this.amount = transformer(this.value.amount);
    } else if (this.onlyDecimals) {
      this.amount = this.value.currency.calculateDecimals(this.value.amount);
    } else {
      this.amount = this.value.currency.from(this.value.amount);
    }
  }

  /* istanbul ignore next */
  get decimalPipeOptions() {
    return `0.${this.value.currency.decimal_points || 2}-${
      this.value.currency.decimal_points || 2
    }`;
  }
}
