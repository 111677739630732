export interface ICurrency {
  acronym: string;
  created_at: string;
  decimal_points: number;
  exchange_rate: number;
  id: string;
  name: string;
  symbol: string;
  updated_at: string;
  iri: string;
}

/* istanbul ignore next */
export class Currency implements ICurrency {
  acronym: string;
  name: string;
  created_at: string;
  decimal_points: number;
  exchange_rate: number;
  id: string;
  acronymname: string;
  symbol: string;
  updated_at: string;
  iri: string;

  getScalar() {
    return Math.pow(10, this.decimal_points);
  }

  format(value) {
    const precisioned = value * Math.pow(10, -this.decimal_points);
    return this.addDecimalPoints(precisioned);
  }

  from(value) {
    const precisioned = value * Math.pow(10, -this.decimal_points);
    const scaled = precisioned * this.exchange_rate;

    return this.addDecimalPoints(scaled);
  }

  to(value) {
    const precisioned = value;
    const scaled = precisioned / this.exchange_rate;

    return this.addDecimalPoints(scaled);
  }

  addDecimalPoints(value): number {
    return Number((value ?? 0).toFixed(this.decimal_points));
  }

  calculateDecimals(value) {
    const precisioned = value * Math.pow(10, -this.decimal_points);
    
    return this.addDecimalPoints(precisioned);
  }

  /* istanbul ignore next */
  static fromJson(obj: ICurrency | Currency): Currency {
    const bot = new Currency();
    bot.id = obj.id;
    bot.name = obj.name;
    bot.exchange_rate = obj.exchange_rate;
    bot.decimal_points = obj.decimal_points;
    bot.acronym = obj.acronym;
    bot.symbol = obj.symbol;
    bot.updated_at = obj.updated_at;
    bot.created_at = obj.created_at;
    bot.iri = obj['@id'];
    return bot;
  }
}
