import { Observable } from 'rxjs';
import { Component, OnChanges, OnInit } from '@angular/core';
import { DialogsService } from 'src/app/services/dialogs.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  DeleteDialogStatus,
  QSnackBar,
  QTableBase,
  QTableFiltersOptions,
  QTableListHeaderOptions,
} from '@qbitartifacts/qbit-kit-ng';
import { MatDialogRef } from '@angular/material/dialog';
import { Account } from '@qbitartifacts/caste-client-ng';

import { QEventsService } from 'src/app/services/events.service';
import { AppService } from 'src/app/services/app.service';
import { AccountDetailsService } from 'src/app/services/CRUD/logic-traders/account_details.service';
import { AccountsService } from 'src/app/services/overrides/accounts.service';
import { TableSettingsService } from 'src/app/services/table-settings.service';
import { LogicTablePageBase } from 'src/app/base/logic.table-page.base';

@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.scss'],
})
export class AccountsListComponent
  extends LogicTablePageBase<Account>
  implements OnInit, OnChanges
{
  public tableId = 'table:accounts';
  public displayedColumns: string[] = [
    'name',
    'application',
    'reference',
    'options',
  ];
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: false,
  };
  public tableFilterOptions: QTableFiltersOptions = {};

  constructor(
    public snackbar: QSnackBar,
    public events: QEventsService,
    public dialogs$: DialogsService,
    public accounts$: AccountsService,
    public app$: AppService,
    public router: Router,
    public route: ActivatedRoute,
    public accountDetails: AccountDetailsService,
    public settings: TableSettingsService
  ) {
    super(snackbar, events, router, route, settings);
  }

  /* istanbul ignore next */
  public getRemoveItemObservable(id: string): Observable<any> {
    return this.accounts$.remove(id);
  }

  /* istanbul ignore next */
  public getSearchObservable(params) {
    return this.accounts$.listAll(params, 'admin');
  }

  /* istanbul ignore next */
  public impersonateAccount(account: Account) {
    this.dialogs$
      .openConfirmation({
        title: 'IMPERSONATE',
        subtitle: 'IMPERSONATE_CONFIRM',
        description: 'IMPERSONATE_CONFIRM_DESC',
        confirmButtonText: 'IMPERSONATE',
      })
      .afterClosed()
      .subscribe((resp) => {
        if (resp === DeleteDialogStatus.DELETE) {
          this.app$.impersonateAccount(account);
        }
      });
  }

  public getRemoveItemDialog(id: string): MatDialogRef<any, any> {
    return this.dialogs$.openConfirmDelete();
  }

  public getOwner(): string {
    return this.app$.getOwner();
  }
}
