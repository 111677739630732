import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { QSnackBar, QTableListHeaderOptions } from '@qbitartifacts/qbit-kit-ng';
import {
  CasteUserService,
  PermissionUser,
} from '@qbitartifacts/caste-client-ng';
import { Observable } from 'rxjs/internal/Observable';
import { DialogsService } from 'src/app/services/dialogs.service';
import { QEventsService } from 'src/app/services/events.service';
import { AppService } from 'src/app/services/app.service';
import { LoadableComponent } from 'src/app/base/loadable.page';
import { LogicTablePageBase } from 'src/app/base/logic.table-page.base';
import { TableSettingsService } from 'src/app/services/table-settings.service';
import { ConfigurationService } from 'src/app/services/CRUD/logic-traders/configuration.service';
import { IConfiguration } from 'src/app/entities/configuration.ent';

@Component({
  selector: 'app-configuration-list',
  templateUrl: './configuration-list.component.html',
  styleUrls: ['./configuration-list.component.scss'],
})
export class ConfigurationsListComponent
  extends LogicTablePageBase<any>
  implements LoadableComponent
{
  public tableId: string = 'table:configs';
  public displayedColumns: string[] = ['name', 'enabled', 'options'];
  public addPermission = PermissionUser;
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: false,
  };

  constructor(
    public app$: AppService,
    public configuration$: ConfigurationService,
    public dialogs$: DialogsService,
    public events: QEventsService,
    public router: Router,
    public route: ActivatedRoute,
    public snackbar: QSnackBar,
    public user$: CasteUserService,
    public settings: TableSettingsService
  ) {
    super(snackbar, events, router, route, settings);
  }

  /* istanbul ignore next */
  public getRemoveItemObservable(id: string, _): Observable<any> {
    return this.configuration$.remove(id, 'admin');
  }

  public getSearchObservable(params, _): Observable<any> {
    return this.configuration$.listAll(params, 'admin');
  }

  /* istanbul ignore next */
  add() {
    this.dialogs$
      .openCreateConfiguration()
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.query);
      });
  }

  edit(configuration: IConfiguration) {
    this.dialogs$
      .openCreateConfiguration(configuration)
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.query);
      });
  }

  public getRemoveItemDialog(id: string): MatDialogRef<any, any> {
    return this.dialogs$.openConfirmDelete();
  }

  public getOwner(): string {
    return this.app$.getOwner();
  }
}
