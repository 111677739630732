import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-profit-indicator',
  templateUrl: './profit-indicator.component.html',
  styleUrls: ['./profit-indicator.component.scss'],
})
export class ProfitIndicatorComponent implements OnInit {
  @Input() profit = 50;

  public leftOffset = 50;
  public rightOffset = 50;

  getColorForProfit(profit) {
    if (profit > 0) {
      return 'success';
    }
    if (profit > -50 && profit < 0) {
      return 'warning';
    }
    if (profit < -50 && profit < 0) {
      return 'error';
    }

    return '';
  }

  /* istanbul ignore next */
  ngOnChanges() {
    this.calculateOffsets();
  }

  ngOnInit() {
    this.calculateOffsets();
  }

  private calculateOffsets() {
    if (this.profit > 0) {
      this.leftOffset = 50;
      this.rightOffset = 50 - (this.profit * 50) / 100;
      return;
    }
    if (this.profit < 0) {
      this.rightOffset = 50;
      this.leftOffset = 50 + (this.profit * 50) / 100;
      return;
    }
    if (this.profit === 0) {
      this.rightOffset = 50;
      this.leftOffset = 50;
      return;
    }
  }
}
