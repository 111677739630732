import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudBaseService } from '../../../base/crud.base.service';
import { AuthService } from '../../auth.service';
import { Bill } from 'src/app/entities/bill';

@Injectable({
  providedIn: 'root',
})
export class BillingService extends CrudBaseService<Bill> {
  constructor(http: HttpClient, auth: AuthService) {
    super(
      {
        endpoint: 'bills',
      },
      http,
      auth
    );
  }
}
