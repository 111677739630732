<qbit-dialog-header title="{{'NEW_CURRENCY' | translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="currencyForm" (ngSubmit)="addInstance()">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-top-10">
                    {{'NEW_CURRENCY' | translate}}
                </h4>
                <p class="text-mutted marg-bottom-15">{{'NEW_CURRENCY_DESC' | translate}}</p>

                <mat-form-field>
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input type="text" id="name" matInput placeholder="Enter currency name" formControlName="name">
                    <mat-error *ngIf="name.invalid && (name.dirty) && name.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'SYMBOL' | translate}}</mat-label>
                    <input type="text" id="symbol" matInput placeholder="Enter currency symbol"
                        formControlName="symbol">
                    <mat-error *ngIf="symbol.invalid && (symbol.dirty) && symbol.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'ACRONYM' | translate}}</mat-label>
                    <input type="text" id="acronym" matInput placeholder="Enter currency acronym"
                        formControlName="acronym">
                    <mat-error *ngIf="acronym.invalid && (acronym.dirty) && acronym.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'DECIMAL_POINTS' | translate}}</mat-label>
                    <input type="number" min="0" id="decimal_points" matInput
                        placeholder="Enter currency decimal points" formControlName="decimal_points">
                    <mat-error
                        *ngIf="decimal_points.invalid && (decimal_points.dirty) && decimal_points.errors.required">
                        {{'REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'createInstance'" [disabled]="currencyForm.invalid"
                        [loading]="isLoading">
                        {{(isLoading ? 'ADDING': 'ADD') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>