import { Component, Input } from '@angular/core';
import { QEventsService } from 'src/app/services/events.service';
import { CurrenciesService } from 'src/app/services/CRUD/logic-traders/currencies.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-currency-form-field',
  templateUrl: './currency-form-field.component.html',
})
export class CurrencyFormField {
  @Input() public disabled: boolean = false;
  @Input() public isNullable = false;
  @Input() public required = false;
  @Input() public selectFirstByDefault = false;
  @Input() public control: FormControl;

  constructor(
    public currencies$: CurrenciesService,
    public events: QEventsService
  ) {}

  /* istanbul ignore next */
  public setSelected() {
    if (this.control.value && 'id' in this.control.value) {
      const foundRunner = this.currencies$.currencies.find(
        (el) => el.id === this.control.value.id
      );
      this.control.setValue(foundRunner);
    } else if (!this.control.value && this.selectFirstByDefault) {
      this.control.setValue(this.currencies$.currencies[0]);
    }
  }

  ngOnInit() {
    this.setSelected();
  }
}
