import { map } from 'rxjs/internal/operators/map';

/* istanbul ignore next */
export const mapStatsArray = (data) => {
  if (!data) {
    return [];
  }

  const arr = [];
  // tslint:disable-next-line: forin
  for (const key in data) {
    arr.push({
      x: new Date(key).getTime(),
      y: data[key],
    });
  }

  return arr.reverse();
};

/* istanbul ignore next */
const getLast2Items = (stats) => {
  const current = stats[stats.length - 1] ? stats[stats.length - 1].y : 0;
  const last = stats[stats.length - 2] ? stats[stats.length - 2].y : 0;

  return [current, last];
};

/* istanbul ignore next */
export const createProfitAggregates = (data) => {
  const [currentMonth, lastMonth] = getLast2Items(data.last_12_months);
  const [currentYear, lastYear] = getLast2Items(data.last_10_years);
  const [currentDay, lastDay] = getLast2Items(data.last_30_days);

  const aggregates = {
    current_day_profit: 0,
    current_month_profit: 0,
    current_year_profit: 0,
    total_balance: 0,
  };
  aggregates.current_month_profit = currentMonth - lastMonth;
  aggregates.current_year_profit = currentYear - lastYear;
  aggregates.current_day_profit = currentDay - lastDay;

  return aggregates;
};

/* istanbul ignore next */
const getMappedStats = (data: any = {}) => {
  const dataProfitLoss = data.profit_and_loss || [];
  dataProfitLoss.last_10_years = mapStatsArray(dataProfitLoss.last_10_years);
  dataProfitLoss.last_12_months = mapStatsArray(dataProfitLoss.last_12_months);
  dataProfitLoss.last_30_days = mapStatsArray(dataProfitLoss.last_30_days);

  const dataBalance = data.balance || [];
  dataBalance.last_10_years = mapStatsArray(dataBalance.last_10_years);
  dataBalance.last_12_months = mapStatsArray(dataBalance.last_12_months);
  dataBalance.last_30_days = mapStatsArray(dataBalance.last_30_days);

  const dataFunding = data.funding || [];
  dataFunding.last_10_years = mapStatsArray(dataFunding.last_10_years);
  dataFunding.last_12_months = mapStatsArray(dataFunding.last_12_months);
  dataFunding.last_30_days = mapStatsArray(dataFunding.last_30_days);

  const aggregates = createProfitAggregates(dataProfitLoss);

  const [lastBalance] = getLast2Items(dataBalance.last_30_days);
  aggregates.total_balance = lastBalance;

  return {
    ...data,
    profit_and_loss: dataProfitLoss,
    balance: dataBalance,
    funding: dataFunding,
    aggregates,
  };
};

/* istanbul ignore next */
export const mapStatistics = map((resp: any) => {
  const { profit_and_loss, balance, funding, aggregates } = getMappedStats(
    resp[0]
  );

  return {
    ...resp[0],
    profit_and_loss,
    balance,
    funding,
    ...aggregates,
  };
});

/* istanbul ignore next */
export const mapInstanceStatistics = map((data: any) => {
  const { profit_and_loss, balance, funding, aggregates } = getMappedStats(
    data
  );

  return {
    ...data,
    profit_and_loss,
    balance,
    funding,
    ...aggregates,
  };
});
