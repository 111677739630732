<mat-card class="no-padding">
    <qbit-table-header title="ACCOUNTS" [options]="tableOptions" [isLoading]="isLoading">
        <table-settings-button (settingsChanged)="settingsChanged()" [tableId]="tableId"></table-settings-button>
        <button mat-icon-button (click)="onSearch(searchParams)">
            <mat-icon>refresh</mat-icon>
        </button>
    </qbit-table-header>

    <qbit-table-filters *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        (filtersChanged)="searchParamsChanged($event)" [filters]="searchParams" [options]="tableFilterOptions"></qbit-table-filters>

    <table mat-table [dataSource]="dataSource" matSort class="hoverable" (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" [trackBy]="trackById">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NAME'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="application">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'APPLICATION'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                {{row.application.name}}
            </td>
        </ng-container>

        <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'REFERENCE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <code *ngIf="row.details | asyncp as details">{{details.reference}}</code>
            </td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item options menu"
                    (click)="$event.preventDefault(); $event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="col-warning" color="primary" (click)="impersonateAccount(row)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">perm_identity</mat-icon>
                        <span>{{'IMPERSONATE' | translate}}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" routerLink="/admin/accounts/{{row.id}}">
        </tr>
    </table>

    <qbit-list-loading *ngIf="isLoading && !hasData"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>

    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>