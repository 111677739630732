import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/base/loadable.page';

import { CurrenciesService } from 'src/app/services/CRUD/logic-traders/currencies.service';
import { Currency } from 'src/app/entities/currency';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-create-currency',
  templateUrl: './create-currency.component.html',
  styleUrls: ['./create-currency.component.scss'],
})
export class CreateCurrencyComponent implements OnInit, LoadableComponent {
  public currencyForm: FormGroup;
  public isLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<CreateCurrencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    private currencies$: CurrenciesService
  ) { }

  /* istanbul ignore next */
  addInstance() {
    if (this.currencyForm.invalid) {
      return false;
    }

    this.setIsLoading(true);
    this.dialogRef.disableClose = true;

    const data: Partial<Currency> = {
      name: this.name.value,
      symbol: this.symbol.value,
      acronym: this.acronym.value,
      decimal_points: this.decimal_points.value,
    };

    this.currencies$.create(data, 'admin').subscribe(
      (resp) => {
        this.snackbar.open('ADDED_CURRENCY_OK');
        this.close(true);
      },
      (err) => {
        this.snackbar.open(err.message || err.detail);
        this.setIsLoading(false);
        this.dialogRef.disableClose = false;
      }
    );
  }

  ngOnInit() {
    this.currencyForm = this.formBuilder.group({
      name: [null, Validators.required],
      symbol: [null, Validators.required],
      acronym: [null, Validators.required],
      decimal_points: [null, Validators.required],
    });
  }

  get name() {
    return this.currencyForm.get('name');
  }

  get symbol() {
    return this.currencyForm.get('symbol');
  }

  get acronym() {
    return this.currencyForm.get('acronym');
  }

  get decimal_points() {
    return this.currencyForm.get('decimal_points');
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
