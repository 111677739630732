import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionAdmin } from '@qbitartifacts/caste-client-ng';
import { QSnackBar, QTableListHeaderOptions } from '@qbitartifacts/qbit-kit-ng';
import { Observable } from 'rxjs';
import { LogicTablePageBase } from 'src/app/base/logic.table-page.base';
import { IStrategy, Strategy } from 'src/app/entities/strategy';
import { AppService } from 'src/app/services/app.service';
import { StrategiesService } from 'src/app/services/CRUD/logic-traders/strategies.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import { QEventsService } from 'src/app/services/events.service';
import { TableSettingsService } from 'src/app/services/table-settings.service';


@Component({
  selector: 'app-strategies-list',
  templateUrl: './strategies-list.component.html',
  styleUrls: ['./strategies-list.component.scss'],
})
export class StrategyListComponent extends LogicTablePageBase<IStrategy> {
  public tableId: string = 'table:strategies';
  public addPermission = PermissionAdmin;
  public displayedColumns: string[] = [
    'name',
    'description',
    'currency',
    'updated',
    'indicators',
    'options',
  ];
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: false,
  };

  constructor(
    public app$: AppService,
    public dialogs$: DialogsService,
    public events: QEventsService,
    public strategies$: StrategiesService,
    public snackbar: QSnackBar,
    public router: Router,
    public route: ActivatedRoute,
    public settings: TableSettingsService
  ) {
    super(snackbar, events, router, route, settings);
  }

  /* istanbul ignore next */
  public addStrategy() {
    this.dialogs$
      .openCreateStrategy()
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.onSearch(this.query);
        }
      });
  }

  /* istanbul ignore next */
  public editStrategy(strategy: Strategy) {
    this.dialogs$
      .openEditStrategy({ strategy })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.onSearch(this.query);
        }
      });
  }

  public getSearchObservable(params, userType) {
    return this.strategies$.listAll(params, userType);
  }

  /* istanbul ignore next */
  public getRemoveItemObservable(id: string): Observable<any> {
    return this.strategies$.remove(id, 'admin');
  }

  public getRemoveItemDialog(id: string): MatDialogRef<any, any> {
    return this.dialogs$.openConfirmDelete();
  }

  public getOwner(): string {
    return this.app$.getOwner();
  }
}
