<mat-toolbar class="toolbar toolbar-white mat-elevation-z1">
    <mat-toolbar-row>
        <button id="sidemenu-toggle" (click)="toggleSidemenu()" mat-icon-button
            aria-label="Sidemenu toggle">
            <mat-icon>menu</mat-icon>
        </button>

        <img *ngIf="app$.darkThemeEnabled" [src]="environment.brand.logo_dark"
            class="marg-left-10" style="height: 45px">
        <img *ngIf="!app$.darkThemeEnabled" [src]="environment.brand.logo_light"
            class="marg-left-10" style="height: 45px">

        <span class="marg-left-10">
            {{environment.brand.title}}
        </span>

        <div style="margin-left: 15px">
            <ng-content></ng-content>
        </div>

        <span class="spacer"></span>

        <div class="float-center-center flex-row center h v"
            *ngIf="app$.isImpersonating">
            <mat-icon
                class="mat-icon material-icons mat-icon-small marg-right-10 col-warning">
                warning</mat-icon>
            <span
                class="text-small marg-right-10">{{'IMPERSONATING' | translate}}
            </span> <code
                class="text-small">{{app$.getImpersonationName()}}</code>

            <button mat-icon-button (click)="removeImpersonation()">
                <mat-icon class="mat-icon material-icons mat-icon-small">close
                </mat-icon>
            </button>
        </div>

        <!-- Right -->
        <qbit-lang-selector></qbit-lang-selector>
        <app-user-avatar-menu></app-user-avatar-menu>
    </mat-toolbar-row>
</mat-toolbar>