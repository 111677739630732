<button [matMenuTriggerFor]="langMenu" [disabled]="disabled" type="button" mat-button class="mat-button-menu"
    style="text-align: left; margin: 0!important; width: auto; min-width: 1px;line-height: 28px!important; margin-right: 10px!important">
    <span *ngIf="currentCurrency">({{currentCurrency.symbol}}) {{currentCurrency.name}}</span>
    <span *ngIf="!currentCurrency">{{'CURRENCY'|translate}}</span>
    <i class="fa fa-caret-down"></i>
</button>

<mat-menu #langMenu="matMenu" y-position="below" overlapTrigger="false">
    <button (click)="selectCurrency(currency)" mat-menu-item *ngFor="let currency of currencies$.currencies"
        style="padding: 5px auto" [ngClass]="{'bg-gray-light': currentCurrency && currentCurrency.id == currency.id}">
        <span><b>({{currency.symbol}})</b> {{currency.name}}</span>
    </button>
</mat-menu>