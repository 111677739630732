<div class="profit-indicator flex-row center">
    <div class="profit-indicator-bar">
        <div class="profit-indicator-labels">
            <span
                class="text-mutted profit-indicator-label profit-indicator-labels-min col-{{getColorForProfit(-100)}}-light">
                -100%
            </span>
            <span class="text-mutted profit-indicator-label profit-indicator-labels-center">
                0
            </span>
            <span
                class="text-mutted profit-indicator-label profit-indicator-labels-max col-{{getColorForProfit(100)}}-light">
                +100%
            </span>
        </div>

        <div class="profit-indicator-bar-fill bg-{{getColorForProfit(profit)}}-light" [style.left]="leftOffset + '%'"
            [style.right]="rightOffset + '%'" [ngClass]="{
                'is-negative': profit < 0, 
                'is-positive': profit > 0
            }">
        </div>

        <div class="profit-indicator-value">
            <span class="profit-indicator-value-label text-mutted col-{{getColorForProfit(profit)}}-light">
                {{profit|number}}%
            </span>
        </div>
    </div>
</div>