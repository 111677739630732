import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/base/loadable.page';

import { OrdersService } from 'src/app/services/CRUD/logic-traders/orders.service';
import { PermissionAdmin } from '@qbitartifacts/caste-client-ng';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { bitmexAmountValidator } from 'src/app/validations/bitmex-amount';
import { Instance } from 'src/app/entities/instance';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss'],
})
export class CreateOrderComponent implements OnInit, LoadableComponent {
  public orderForm: FormGroup;
  public isLoading: boolean;
  public changeAccountPermission = PermissionAdmin;
  public instance: Instance;

  public priceType = 'market';
  public priceTypes = ['market', 'limit'];

  constructor(
    public dialogRef: MatDialogRef<CreateOrderComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { instance: Instance; instanceId: string },
    public snackbar: QSnackBar,
    private formBuilder: FormBuilder,
    private orders$: OrdersService
  ) {}

  /* istanbul ignore next */
  addOrder() {
    if (this.orderForm.invalid) {
      return false;
    }

    this.setIsLoading(true);
    this.dialogRef.disableClose = true;

    const orderData: any = {
      amount: this.amount.value,
      instance: `/instances/${this.data.instance}`,
    };
    if (this.priceType === 'limit') {
      orderData.price = this.price.value;
    }

    this.orders$.create(orderData).subscribe(
      (resp) => {
        this.snackbar.open('ADDED_ORDER_OK');
        this.close(true);
      },
      (err) => {
        this.snackbar.open(err.message || err.detail);
        this.setIsLoading(false);
        this.dialogRef.disableClose = false;
      }
    );
  }

  ngOnInit() {
    this.orderForm = this.formBuilder.group({
      amount: [
        null,
        Validators.compose([Validators.required, bitmexAmountValidator]),
      ],
      price: [null],
    });

    this.changePriceType('market');
  }

  get amount() {
    return this.orderForm.get('amount');
  }

  get price() {
    return this.orderForm.get('price');
  }

  get isMarket() {
    return this.priceType === 'market';
  }

  /* istanbul ignore next */
  changePriceType(type: string) {
    this.priceType = type;

    if (this.priceType === 'market') {
      this.price.disable();
    } else {
      this.price.enable();
    }
  }

  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
