<!-- The following components only render if user is logged in and has User Permissions -->
<div *ngIf="user$.hasUser() && currencies$.currenciesLoaded" style="height: calc(100vh - 50px)"
    [ngClass]="{'dark-theme': app$.darkThemeEnabled}">
    <app-toolbar *appPermissions="permissionUser">
        <qbit-breadcrumbs></qbit-breadcrumbs>
    </app-toolbar>
    <qbit-sidemenu *appPermissions="permissionUser">
        <div class="padd-h-15" header>
            <sa-current-account-selector></sa-current-account-selector>
        </div>
        <router-outlet></router-outlet>
    </qbit-sidemenu>
</div>

<!-- This router outlet only used when user is not logged in (for public routes) -->
<div *ngIf="!user$.hasUser()" [ngClass]="{'dark-theme': app$.darkThemeEnabled}">
    <ng-container>
        <router-outlet></router-outlet>
    </ng-container>
</div>