import { CreateBillComponent } from './create-bill/create-bill.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { InstanceConfigComponent } from './instance/instance-config/instance-config.component';
import { IdleNotificationComponent } from './idle-notification/idle.dia';
import { ComponentsModule } from '../components/components.module';

import { CreateInstanceComponent } from './instance/create-instance/create-instance.component';
import { CreateStrategyComponent } from './create-strategy/create-strategy.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { CreateCurrencyComponent } from './create-currency/create-currency.component';
import { EditInstanceComponent } from './instance/edit-instance/edit-instance.component';
import { CreateRunnerComponent } from './runner/create-runner/create-runner.component';
import { CreateApiKeysComponent } from './create-api-keys/create-api-keys.component';
import { BillDetailsComponent } from './bill-details/bill-details.component';
import { AskAccountDetailsComponent } from './ask-account-details/ask-account-details.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ClosePositionDialog } from './close-position/close-position.component';
import { TableSettingsDialog } from './table-settings/table-settings.component';
import { ShowJsonDialog } from './show-json/show-json.component';
import { TradeListComponent } from './trades-list/trades-list.component';
import { CreateConfigurationComponent } from './create-configuration/create-configuration.component';
import { CreateNoteComponent } from './create-note/create-note.component';

const dialogs = [
  IdleNotificationComponent,
  InstanceConfigComponent,
  DeleteConfirmationComponent,
  ChangePasswordComponent,
  ShowJsonDialog,

  // Creation Dialogs
  CreateInstanceComponent,
  CreateStrategyComponent,
  CreateBillComponent,
  CreateOrderComponent,
  CreateCurrencyComponent,
  CreateRunnerComponent,
  CreateApiKeysComponent,
  AskAccountDetailsComponent,
  CreateConfigurationComponent,
  CreateNoteComponent,

  // Edit Dialogs
  EditInstanceComponent,

  // Details
  BillDetailsComponent,

  // Other
  ClosePositionDialog,
  TableSettingsDialog,
  TradeListComponent,
];

@NgModule({
  declarations: dialogs,
  exports: dialogs,
  entryComponents: dialogs,
  imports: [SharedModule, ComponentsModule],
})
export class DialogsModule {}
