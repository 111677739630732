<div class="bootstrap-wrapper padd-v-20">
    <mat-card class="marg-bottom-15">
        <div class="centered" style="width: 100%;" *ngIf="itemData.name">
            <h4 class="title-lg">{{itemData.name}}</h4>
            <p class="text-mutted no-margin">{{itemData.application.name}}</p>
        </div>

        <div class="abs-right-center">
            <app-impersonate-account [account]="itemData"></app-impersonate-account>
        </div>
    </mat-card>

    <mat-card class="marg-bottom-15 no-padding">
        <app-permissions-list *ngIf="itemData.permissions" #permissions [permissions]="itemData.permissions">
        </app-permissions-list>
    </mat-card>
</div>