import { Component, Input } from '@angular/core';
import {
  CasteAccountsService,
  CastePermissionsService,
  CasteUserService,
} from '@qbitartifacts/caste-client-ng';
import { DeleteDialogStatus, QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { AccountDetailsService } from 'src/app/services/CRUD/logic-traders/account_details.service';
import { DialogsService } from 'src/app/services/dialogs.service';

@Component({
  selector: 'app-account-manager',
  templateUrl: './account-manager.component.html',
})
export class AccountManagerComponent {
  @Input() public accountId = '';
  public account: any;

  public isAdmin = false;
  public isManager = false;

  public accountDetails: any;
  public isLoading: boolean;
  public addNewUser = false;
  public newUserGrants: any[];
  public newUserEmail = '';

  constructor(
    public accounts: CasteAccountsService,
    public user$: CasteUserService,
    public dialogs: DialogsService,
    public permissions: CastePermissionsService,
    public snackbar: QSnackBar,
    public accountDetails$: AccountDetailsService
  ) {
    this.isAdmin = user$.isAdmin();
  }

  ngOnInit() {
    this.isManager = this.user$.isManagerForAccount(this.accountId);
    this.getAccount();
  }

  /* istanbul ignore next */
  cancelAddUser() {
    this.addNewUser = false;
    this.newUserGrants = [];
    this.newUserEmail = '';
  }

  /* istanbul ignore next */
  protected getAccount() {
    this.accounts
      .getOne(this.accountId)
      .subscribe((res) => (this.account = res));

    this.accountDetails$
      .listAll({ account_id: this.accountId })
      .subscribe((resp: any) => {
        this.accountDetails = resp.data.length ? resp.data[0] : null;
      });
  }

  /* istanbul ignore next */
  addPermission() {
    this.isLoading = false;
    this.permissions
      .create({
        account: `/user/accounts/${this.accountId}`,
        username: this.newUserEmail,
        grants: this.newUserGrants,
      } as any)
      .subscribe(
        (resp) => {
          this.snackbar.open('ADDED_ITEM');
          this.getAccount();
          this.isLoading = false;
        },
        (err) => {
          this.snackbar.open(err.message);
          this.isLoading = false;
        }
      );
  }

  /* istanbul ignore next */
  setNewUserGrants(grants) {
    this.newUserGrants = grants;
  }

  /* istanbul ignore next */
  updatePermission(perm: any, grants) {
    this.isLoading = true;
    this.permissions.update(perm.id, { grants: grants } as any).subscribe(
      (resp) => {
        this.snackbar.open('UPDATED_ITEM');
        this.getAccount();
        this.isLoading = false;
      },
      (err) => {
        this.snackbar.open(err.message);
        this.isLoading = false;
      }
    );
  }

  /* istanbul ignore next */
  openRemovePermissionConfirmation(permission: any) {
    this.dialogs
      .openConfirmDelete()
      .afterClosed()
      .subscribe((resp) => {
        if (resp === DeleteDialogStatus.DELETE) {
          this.removeItem(permission.id);
        }
      });
  }

  /* istanbul ignore next */
  public removeItem(id) {
    this.permissions.remove(id).subscribe(
      (resp) => {
        this.snackbar.open('REMOVED_ITEM');
        this.getAccount();
      },
      (err) => {
        this.snackbar.open(err.message);
      }
    );
  }
}
