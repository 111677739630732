import { InstancesComponent } from '../pages/instances/instances.component';
import { ForgotPassComponent } from '../pages/forgot-pass/forgot-pass.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { Routes } from '@angular/router';
import { LoginComponent } from '../pages/login/login.component';
import { ProfileComponent } from '../pages/profile/profile.component';
import { InstanceComponent } from '../pages/instance/instance.component';
import { AdminStrategiesComponent } from '../pages/admin/strategies/strategies.component';
import { SignupComponent } from '../pages/signup/signup.component';
import { AccountsComponent } from '../pages/accounts/accounts.component';
import { AdminInstancesComponent } from '../pages/admin/instances/admin-instances.component';
import { BillingComponent } from '../pages/billing/billing.component';
import { StrategiesComponent } from '../pages/strategies/strategies.component';
import { AdminDashboardComponent } from '../pages/admin/dashboard/dashboard.component';
import { AdminBillingComponent } from '../pages/admin/billing/billing.component';
import { AdminCurrenciesComponent } from '../pages/admin/currencies/admin-currencies.component';
import { AccountComponent } from '../pages/account/account.component';
import { VerifyComponent } from '../pages/verify/verify.component';
import { RecoverComponent } from '../pages/recover/recover.component';
import { AdminRunnersComponent } from '../pages/admin/runners/admin-runners.component';
import { AdminApiKeysComponent } from '../pages/admin/api-keys/api-keys.component';
import { AdminTrashComponent } from '../pages/admin/trash/trash.component';
import { AdminConfigurationComponent } from '../pages/admin/configuration/configuration.component';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: LoginComponent.guards,
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: SignupComponent.guards,
  },
  {
    path: 'verify/:user_id',
    component: VerifyComponent,
    canActivate: VerifyComponent.guards,
  },
  {
    path: 'recover/:user_id',
    component: RecoverComponent,
    canActivate: RecoverComponent.guards,
  },
  {
    path: 'forgot-password',
    component: ForgotPassComponent,
    canActivate: ForgotPassComponent.guards,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: DashboardComponent.guards,
  },
  {
    path: 'instances',
    component: InstancesComponent,
    canActivate: InstancesComponent.guards,
    data: {
      breadcrumb: 'Instances',
    },
    children: [
      {
        path: ':id',
        component: InstanceComponent,
        canActivate: InstanceComponent.guards,
        data: {
          breadcrumb: 'Instance',
        },
      },
    ],
  },
  {
    path: 'strategies',
    component: StrategiesComponent,
    canActivate: StrategiesComponent.guards,
    data: {
      breadcrumb: 'Strategies',
    },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: ProfileComponent.guards,
    data: {
      breadcrumb: 'Profile',
    },
  },
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: BillingComponent.guards,
    data: {
      breadcrumb: 'Billing',
    },
  },
  // Admin Routes
  {
    path: 'admin/dashboard',
    component: AdminDashboardComponent,
    canActivate: AdminDashboardComponent.guards,
    data: {
      breadcrumb: 'Admin Dashboard',
    },
  },

  {
    path: 'admin/accounts',
    component: AccountsComponent,
    canActivate: AccountsComponent.guards,
    data: {
      breadcrumb: 'Accounts',
    },
    children: [
      {
        path: ':id',
        component: AccountComponent,
        canActivate: AccountComponent.guards,
        data: {
          breadcrumb: 'Account',
        },
      },
    ],
  },
  {
    path: 'admin/strategies',
    component: AdminStrategiesComponent,
    canActivate: AdminStrategiesComponent.guards,
    data: {
      breadcrumb: 'Admin Strategies',
    },
  },
  {
    path: 'admin/instances',
    component: AdminInstancesComponent,
    canActivate: AdminInstancesComponent.guards,
    data: {
      breadcrumb: 'Admin Instances',
    },
    children: [
      {
        path: ':id',
        component: InstanceComponent,
        canActivate: InstanceComponent.guards,
        data: {
          breadcrumb: 'Instance',
        },
      },
    ],
  },
  {
    path: 'admin/billing',
    component: AdminBillingComponent,
    canActivate: AdminBillingComponent.guards,
    data: {
      breadcrumb: 'Admin Billing',
    },
  },
  {
    path: 'admin/currencies',
    component: AdminCurrenciesComponent,
    canActivate: AdminCurrenciesComponent.guards,
    data: {
      breadcrumb: 'Admin Currencies',
    },
  },
  {
    path: 'admin/runners',
    component: AdminRunnersComponent,
    canActivate: AdminRunnersComponent.guards,
    data: {
      breadcrumb: 'Admin Runners',
    },
  },
  {
    path: 'admin/api_keys',
    component: AdminApiKeysComponent,
    canActivate: AdminApiKeysComponent.guards,
    data: {
      breadcrumb: 'Admin API Keys',
    },
  },
  {
    path: 'admin/trash',
    component: AdminTrashComponent,
    canActivate: AdminTrashComponent.guards,
    data: {
      breadcrumb: 'Admin Trash',
    },
  },
  {
    path: 'admin/config',
    component: AdminConfigurationComponent,
    canActivate: AdminConfigurationComponent.guards,
    data: {
      breadcrumb: 'Admin Configuration',
    },
  },
  // Support routes
  {
    path: 'support/instances',
    component: AdminInstancesComponent,
    canActivate: AdminInstancesComponent.guards,
    data: {
      breadcrumb: 'Support Instances',
    },
    children: [
      {
        path: ':id',
        component: InstanceComponent,
        canActivate: InstanceComponent.guards,
        data: {
          breadcrumb: 'Instance',
        },
      },
    ],
  },
  {
    path: 'support/billing',
    component: AdminBillingComponent,
    canActivate: AdminBillingComponent.guards,
    data: {
      breadcrumb: 'Support Billing',
    },
  },
];
