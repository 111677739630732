import { Injectable } from '@angular/core';

export interface TableSettingsEntry {
  id: string;
  refreshEnabled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TableSettingsService {
  static defaultSetting: TableSettingsEntry = {
    id: null,
    refreshEnabled: false,
  };
  public settings: { [key: string]: TableSettingsEntry } = {};

  public getSettingFor(key) {
    // get from memory if available
    if (key in this.settings) this.settings[key];

    return this.readFromLS(key);
  }

  public setSettingFor(key: string, value: TableSettingsEntry) {
    this.settings[key] = value;
    this.writeToLS(key, value);
  }

  private readFromLS(key: string): TableSettingsEntry {
    const lsSetting = localStorage.getItem(key);
    if (lsSetting) return JSON.parse(lsSetting);

    return TableSettingsService.defaultSetting;
  }

  private writeToLS(key: string, value: TableSettingsEntry) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
