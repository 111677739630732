<mat-card class="no-padding" style="height: 100%;">
    <mat-card-header>
        <mat-card-title style="margin: 0 0 0 15px;">
            <div class="flex-row center">
                <mat-form-field class="no-padding marg-right-20">
                    <mat-select (selectionChange)="statsTypeChanged($event)" [value]="statType">
                        <mat-option value="profit_and_loss" selected>{{'PROFIT_LOSS'|translate}}</mat-option>
                        <mat-option value="balance">{{'BALANCE'|translate}}</mat-option>
                        <mat-option value="funding">{{'FUNDING'|translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card-title>
        <div class="abs-right-center flex-row center marg-right-15">
            <mat-form-field class="no-padding">
                <mat-select (selectionChange)="chartRangeChanged($event)" [value]="statRange">
                    <mat-option value="last_30_days">{{'LAST_30_DAYS'|translate}}</mat-option>
                    <mat-option value="last_12_months">{{'LAST_12_MONTHS'|translate}}</mat-option>
                    <mat-option value="last_10_years">{{'LAST_10_YEARS'|translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div style="height: 300px;" class="padd-h-15">
            <dash-chart id="txChartEarnings" [currency]="currency" height="300px" type="spline" #txChartEarnings>
            </dash-chart>
        </div>
    </mat-card-content>
</mat-card>