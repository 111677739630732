import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CasteUserService } from '@qbitartifacts/caste-client-ng';
import { Instance } from 'src/app/entities/instance';
import { Note } from 'src/app/entities/note';
import { DialogsService } from 'src/app/services/dialogs.service';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
})
export class NotesListComponent implements OnInit {
  @Input() instance: Instance;
  @Input() notes: Note[];

  @Output() noteAdded: EventEmitter<Note> = new EventEmitter();

  constructor(
    private dialogs: DialogsService,
    private user$: CasteUserService
  ) {}

  ngOnInit(): void {}

  addNote() {
    this.dialogs
      .openCreateNote({
        instance: this.instance,
        author: this.user$.user.username,
      })
      .afterClosed()
      .subscribe((newNote) => {
        this.notes.unshift(newNote);

        if (newNote) {
          this.noteAdded.emit(newNote);
        }
      });
  }
}
