<mat-card class="no-padding mat-elevation-z0">
    <table style="width: 100%" mat-table [dataSource]="dataSource" matSort class="hoverable"
        (matSortChange)="sortChanged($event)" [matSortDirection]="sortDir" [matSortActive]="sortId"
        [trackBy]="trackById">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> {{'id'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> {{'TYPE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                {{row.type}}
            </td>
        </ng-container>

        <ng-container matColumnDef="side">
            <th mat-header-cell *matHeaderCellDef> {{'SIDE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                {{row.side}}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{'STATUS'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <span class="status-{{row.status}}">{{row.status}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> {{'AMOUNT'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <code>{{row.amount}}</code>
            </td>
        </ng-container>

        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> {{'PRICE'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <code>{{row.price}}</code>
            </td>
        </ng-container>

        <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef> {{'SYMBOL'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                {{row.symbol}}
            </td>
        </ng-container>

        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef> {{'TIMESTAMP'|translate}} </th>
            <td mat-cell *matCellDef="let row">
                <i>{{row.timestamp | date:'medium'}}</i>
            </td>
        </ng-container>
        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <qbit-list-loading *ngIf="isLoading"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>

    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>