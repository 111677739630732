<qbit-dialog-header title="{{'NEW_ORDER'|translate}}" (click)="close()"></qbit-dialog-header>

<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="orderForm" (ngSubmit)="addOrder()">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-top-10">
                    {{'NEW_ORDER' | translate}}
                </h4>
                <p class="text-mutted marg-bottom-15">{{'NEW_ORDER_DESC'| translate}}</p>

                <mat-form-field>
                    <mat-label>{{'AMOUNT' | translate}}</mat-label>
                    <input type="number" id="amount" matInput placeholder="Enter your amount" formControlName="amount">
                    <mat-error *ngIf="amount.invalid && (amount.dirty) && amount.errors.required">
                        {{'AMOUNT_REQUIRED' | translate}}
                    </mat-error>
                </mat-form-field>

                <div class="row">
                    <div class="col-lg-9">
                        <mat-form-field>
                            <mat-label>{{'PRICE' | translate}}</mat-label>
                            <input type="number" id="price" matInput placeholder="Enter your price"
                                formControlName="price">
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-button-toggle-group [ngModel]="priceType" (ngModelChange)="changePriceType($event)"
                            [ngModelOptions]="{standalone: true}" aria-label="Price Type">
                            <mat-button-toggle *ngFor="let type of priceTypes" [value]="type" class="text-small">
                                {{type|translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                <p *ngIf="isMarket" class="text-mid">{{'MARKET_DESC' | translate}}</p>
                <p *ngIf="!isMarket" class="text-mid">{{'LIMIT_DESC' | translate}}</p>


                <div class="flex-row end">
                    <qbit-loadable-button [id]="'createInstance'" [disabled]="orderForm.invalid || isLoading" [loading]="isLoading">
                        {{(isLoading ? 'ADDING': 'ADD') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>