import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AdminDashboardComponent } from './admin/dashboard/dashboard.component';
import { SharedModule } from '../shared.module';
import { UserComponent } from './user/user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';
import { InstanceComponent } from './instance/instance.component';
import { InstancesComponent } from './instances/instances.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { ComponentsModule } from '../components/components.module';
import { AccountsComponent } from './accounts/accounts.component';
import { AdminStrategiesComponent } from './admin/strategies/strategies.component';
import { AdminInstancesComponent } from './admin/instances/admin-instances.component';
import { BillingComponent } from './billing/billing.component';
import { StrategiesComponent } from './strategies/strategies.component';
import { AdminBillingComponent } from './admin/billing/billing.component';
import { AdminCurrenciesComponent } from './admin/currencies/admin-currencies.component';
import { AccountComponent } from './account/account.component';
import { VerifyComponent } from './verify/verify.component';
import { RecoverComponent } from './recover/recover.component';
import { AdminRunnersComponent } from './admin/runners/admin-runners.component';
import { AdminApiKeysComponent } from './admin/api-keys/api-keys.component';
import { SupportInstancesComponent } from './support/instances/support-instances.component';
import { SupportBillingComponent } from './support/billing/billing.component';
import { AdminTrashComponent } from './admin/trash/trash.component';
import { AdminConfigurationComponent } from './admin/configuration/configuration.component';

const pages = [
  DashboardComponent,
  ForgotPassComponent,
  InstanceComponent,
  InstancesComponent,
  LoginComponent,
  ProfileComponent,
  SignupComponent,
  UserComponent,
  AccountsComponent,
  BillingComponent,
  StrategiesComponent,
  VerifyComponent,
  RecoverComponent,
  AccountComponent,

  // Admin pages
  AdminStrategiesComponent,
  AdminInstancesComponent,
  AdminDashboardComponent,
  AdminBillingComponent,
  AdminCurrenciesComponent,
  AdminRunnersComponent,
  AdminApiKeysComponent,
  AdminTrashComponent,
  AdminConfigurationComponent,

  // Support
  SupportInstancesComponent,
  SupportBillingComponent,
];

@NgModule({
  declarations: pages,
  exports: pages,
  imports: [SharedModule, ComponentsModule, TranslateModule],
})
export class PagesModule {}
