<!-- TODO: add [canClose]="!isLoading"  when in kit -->
<qbit-dialog-header title="{{'CHANGE_PASSWORD'|translate}}" (close)="close()">
</qbit-dialog-header>


<div class="padd-h-15 padd-v-15">
    <h4 class="text-title align-center">
        {{'CHANGE_PASSWORD'|translate}}
    </h4>
    <p class="text-light align-center marg-bottom-20" [innerHTML]="'CHANGE_PASSWORD_DESC'|translate:({email: email})">
    </p>

    <br>
    <div class="centered flex-row marg-top-20">
        <button mat-button [disabled]="isLoading">
            {{'CANCEL'|translate}}
        </button>

        <button mat-raised-button (close)="close()" [disabled]="isLoading" color="primary" (click)="changePassword()">
            {{'CHANGE'|translate}}
        </button>
    </div>
</div>