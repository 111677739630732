<qbit-dialog-header title="{{'CREATE_API_KEYS'|translate}}" (click)="close()"></qbit-dialog-header>


<div class="bootstrap-wrapper">
    <form class="padd-20" [formGroup]="keysForm" (ngSubmit)="addKeys()">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="title-lg marg-top-10">
                    New Api Key
                </h4>
                <p class="text-mutted marg-bottom-15">Here you can change Api Keys details</p>

                <div *appPermissions="changeAccountPermission">
                    <sa-admin-account-selector (accountSelected)="accountSelected($event);">
                    </sa-admin-account-selector>
                </div>

                <mat-form-field>
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input id="name" matInput placeholder="Enter the key name" formControlName="name" required>
                    <mat-error *ngIf="name.invalid && (name.dirty) && name.errors.required">
                        {{'NAME_REQUIRED' | translate}}
                    </mat-error>
                    <mat-hint>
                        {{'NAME_HINT' | translate}}
                    </mat-hint>
                </mat-form-field>

                <div class="flex-row end">
                    <qbit-loadable-button [id]="'createInstance'" [disabled]="keysForm.invalid" [loading]="isLoading">
                        {{(isLoading ? 'ADDING': 'ADD') |translate}}
                    </qbit-loadable-button>
                </div>
            </div>
        </div>
    </form>
</div>