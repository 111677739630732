import { Router } from '@angular/router';
import { Component, AfterContentInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PageBaseComponent } from 'src/app/base/page.base';
import { LoadablePageComponent } from 'src/app/base/loadable.page';
import { TranslateService } from '@ngx-translate/core';
import {
  CasteAuthService,
  CasteUserService,
  SignupResponse,
} from '@qbitartifacts/caste-client-ng';
import { QEventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';

import { PublicGuard } from 'src/app/guards/public.guard';
import { AuthService } from 'src/app/services/auth.service';
import { QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent
  extends PageBaseComponent
  implements AfterContentInit, LoadablePageComponent {
  static guards: any[] = [PublicGuard];
  public title = 'Signup';
  public hidePass = true;
  public isLoading = false;

  public signUpForm: FormGroup;
  public submitted = false;
  public signUpSuccess: boolean = null;
  public environment = environment;

  constructor(
    title: Title,
    private formBuilder: FormBuilder,
    public translate$: TranslateService,
    public auth$: AuthService,
    public router: Router,
    public qbitAuth: CasteAuthService,
    public events: QEventsService,
    public user$: CasteUserService,
    public breadcrumbs$: QBreadcrumbsService
  ) {
    super(title, translate$, events, breadcrumbs$, user$);
  }

  public ngAfterContentInit() {
    super.ngAfterContentInit();

    this.signUpForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get email() {
    return this.signUpForm.get('email');
  }

  get password() {
    return this.signUpForm.get('password');
  }

  /* istanbul ignore next */
  public setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  /* istanbul ignore next */
  public onSubmit(): boolean {
    this.submitted = true;

    if (this.signUpForm.invalid) {
      return false;
    }

    this.setIsLoading(true);

    this.qbitAuth
      .signUp({
        username: this.email.value,
        password: this.password.value,
      })
      .subscribe({
        next: this.signupSuccess.bind(this),
        error: this.signupError.bind(this),
      });

    return true;
  }

  /* istanbul ignore next */
  private signupSuccess(resp: SignupResponse) {
    this.signUpSuccess = true;
  }

  /* istanbul ignore next */
  private signupError(error) {
    this.signUpForm.setErrors({
      invalidCredentials: error.detail || error.message,
    });
    this.setIsLoading(false);
  }
}
