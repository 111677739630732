import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadableComponent } from 'src/app/base/loadable.page';
import { StrategiesService } from 'src/app/services/CRUD/logic-traders/strategies.service';
import { EXCHANGES } from 'src/app/config/consts';

import { Strategy } from 'src/app/entities/strategy';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';

interface KeyValue {
  key: string;
  value: string;
}

@Component({
  selector: 'app-create-strategy',
  templateUrl: './create-strategy.component.html',
  styleUrls: ['./create-strategy.component.scss'],
})
export class CreateStrategyComponent implements OnInit, LoadableComponent {
  public strategyDetailsForm: FormGroup;
  public isLoading: boolean;
  public exchanges = EXCHANGES;
  public env: KeyValue[] = [
    {
      key: '',
      value: '',
    },
  ];
  public envTouched = false;
  public reviewStrategy = false;

  public isEdit = false;
  public item: Strategy = null;
  public title = 'CREATE_STRATEGY';
  private titles = {
    create: 'CREATE_STRATEGY',
    edit: 'EDIT_STRATEGY',
    review: 'REVIEW_CHANGES',
  };

  constructor(
    public dialogRef: MatDialogRef<CreateStrategyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private strategies$: StrategiesService,
    private snackbar: QSnackBar
  ) {
    if (data.isEdit) {
      this.isEdit = true;
      this.item = data.item;
    }

    this.title = this.isEdit ? this.titles.edit : this.titles.create;
  }

  /* istanbul ignore next */
  ngOnInit() {
    if (this.isEdit) {
      this.strategyDetailsForm = this.formBuilder.group({
        name: [this.item.name, Validators.required],
        description: [this.item.description],
        exchange: [this.item.exchange, Validators.required],
        risk: [
          this.item.indicators ? this.item.indicators.risk : null,
          Validators.max(1),
        ],
        profit: [
          this.item.indicators ? this.item.indicators.profit : null,
          Validators.max(1),
        ],
        directory: [
          this.item.parameters ? this.item.parameters.directory : null,
        ],
        tv_symbol: [
          this.item.parameters ? this.item.parameters.tv_symbol : null,
        ],
        currency: [this.item.currency, Validators.required],
      });

      if (this.item.parameters && this.item.parameters.env) {
        this.env = [];
        for (let key in this.item.parameters.env) {
          this.env.push({ key, value: this.item.parameters.env[key] });
        }
      }
    } else {
      this.strategyDetailsForm = this.formBuilder.group({
        name: ['', Validators.required],
        description: [''],
        exchange: ['', Validators.required],
        risk: [null, Validators.max(1)],
        profit: [null, Validators.max(1)],
        directory: [null],
        tv_symbol: [null],
        currency: [null, Validators.required],
      });
    }
  }

  /* istanbul ignore next */
  public onSubmit() {
    const hasError = this.checkErrors();
    if (hasError) {
      return;
    }

    this.cleanEnv();
    this.title = this.titles.review;
    this.reviewStrategy = true;
  }

  /* istanbul ignore next */
  public cancelReview() {
    this.title = this.isEdit ? this.titles.edit : this.titles.create;
    this.reviewStrategy = false;
  }

  /* istanbul ignore next */
  public proceed() {
    this.setIsLoading(true);
    this.dialogRef.disableClose = true;

    this.getAction().subscribe(
      (resp) => {
        this.snackbar.open(this.isEdit ? 'ADDED_ITEM_OK' : 'EDITED_ITEM_OK');
        this.close(true);
      },
      (err) => {
        this.setIsLoading(false);
        this.dialogRef.disableClose = false;
        this.snackbar.open(err.message || err.description);
      }
    );
  }

  get name() {
    return this.strategyDetailsForm.get('name');
  }

  get exchange() {
    return this.strategyDetailsForm.get('exchange');
  }

  get description() {
    return this.strategyDetailsForm.get('description');
  }
  get currency() {
    return this.strategyDetailsForm.get('currency');
  }
  get risk() {
    return this.strategyDetailsForm.get('risk');
  }

  get profit() {
    return this.strategyDetailsForm.get('profit');
  }

  get directory() {
    return this.strategyDetailsForm.get('directory');
  }

  get tv_symbol() {
    return this.strategyDetailsForm.get('tv_symbol');
  }

  /* istanbul ignore next */
  private cleanEnv() {
    this.env = this.env.filter((e) => e.key !== '' && e.value !== '');
  }

  /* istanbul ignore next */
  private getAction() {
    if (this.isEdit) {
      const data = this.getEditData();
      return this.strategies$.update(this.item.id, data, 'admin');
    } else {
      const data = this.getData();
      return this.strategies$.create(data, 'admin');
    }
  }

  /* istanbul ignore next */
  private getEditData() {
    const data: any = {};

    const addIfExists = (key) => {
      if (this[key] && this[key].touched) {
        data[key] = this[key].value;
      }
    };

    addIfExists('name');
    addIfExists('description');
    addIfExists('exchange');

    if (this.currency.touched) {
      data['currency'] = this.currency.value.iri;
    }

    if (this.profit.touched || this.risk.touched) {
      data['indicators'] = data['indicators']
        ? {
            ...data['indicators'],
            profit: this.profit.value,
            risk: this.risk.value,
          }
        : {
            profit: this.profit.value,
            risk: this.risk.value,
          };
    }

    if (this.directory.touched || this.envTouched) {
      data['parameters'] = data['parameters']
        ? {
            ...data['parameters'],
            directory: this.directory.value,
            tv_symbol: this.tv_symbol.value,
            env: this.getEnvObject(),
          }
        : {
            directory: this.directory.value,
            tv_symbol: this.tv_symbol.value,
            env: this.getEnvObject(),
          };
    }

    if (this.tv_symbol.touched || this.envTouched) {
      data['parameters'] = data['parameters']
        ? {
            ...data['parameters'],
            directory: this.directory.value,
            tv_symbol: this.tv_symbol.value,
            env: this.getEnvObject(),
          }
        : {
            directory: this.directory.value,
            tv_symbol: this.tv_symbol.value,
            env: this.getEnvObject(),
          };
    }

    console.log('data', data);
    return data;
  }

  /* istanbul ignore next */
  private getData() {
    const data: any = {
      name: this.name.value,
      description: this.description.value,
      exchange: this.exchange.value,
      currency: this.currency.value.iri,
    };

    if (this.risk.value && this.profit.value) {
      data.indicators = {
        risk: this.risk.value,
        profit: this.profit.value,
      };
    }

    if (this.directory.value || this.env.length > 1) {
      data.parameters = {
        directory: this.directory.value,
        env: this.getEnvObject(),
      };
    }

    return data;
  }

  /* istanbul ignore next */
  private getEnvObject() {
    const env = {};
    this.env.forEach((e) => (env[e.key] = e.value));
    return env;
  }

  /* istanbul ignore next */
  private hasEmptyRow(): boolean {
    return this.env.find((el) => el.key == '' && el.value == '') != null;
  }

  /* istanbul ignore next */
  private checkErrors() {
    const hasRisk = this.risk.value != null;
    const hasProfit = this.profit.value != null;

    if (hasRisk && !hasProfit) {
      this.profit.setErrors({
        required: true,
      });
      return true;
    } else if (hasProfit && !hasRisk) {
      this.risk.setErrors({
        required: true,
      });
      return true;
    }

    if (this.strategyDetailsForm.invalid) {
      return true;
    }

    return false;
  }

  /* istanbul ignore next */
  anyEnvSet() {
    return this.env.find((el) => el.key != '' && el.value != '') != null;
  }

  /* istanbul ignore next */
  removeEnv(index: number) {
    if (this.env.length == 1) {
      this.env[0] = { key: '', value: '' };
    } else {
      this.env.splice(index, 1);
    }
    this.envTouched = true;
  }

  /* istanbul ignore next */
  addNewRow() {
    if (!this.hasEmptyRow()) {
      this.env.push({ key: '', value: '' });
    }
    this.envTouched = true;
  }

  /* istanbul ignore next */
  updateKey(index: number, event: any) {
    if (this.env[index]) this.env[index].key = event.target.value;
    this.envTouched = true;
  }

  /* istanbul ignore next */
  updateValue(index: number, event: any) {
    if (this.env[index]) this.env[index].value = event.target.value;
    this.envTouched = true;
  }

  /* istanbul ignore next */
  profitChanged() {
    if (this.profit.value > 1) {
      this.profit.setValue(1);
    }
    if (this.profit.value < 0) {
      this.profit.setValue(0);
    }
  }

  /* istanbul ignore next */
  riskChanged() {
    if (this.risk.value > 1) {
      this.risk.setValue(1);
    }
    if (this.risk.value < 0) {
      this.risk.setValue(0);
    }
  }

  /* istanbul ignore next */
  setIsLoading(loading: boolean): void {
    this.isLoading = loading;
  }

  close(val = false) {
    this.dialogRef.close(val);
  }
}
