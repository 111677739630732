import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

import { Bill } from 'src/app/entities/bill';
import { LoadableComponent } from 'src/app/base/loadable.page';
import { DialogsService } from 'src/app/services/dialogs.service';

import { BillingService } from 'src/app/services/CRUD/logic-traders/billing.service';
import { QEventsService } from 'src/app/services/events.service';
import { AppService } from 'src/app/services/app.service';
import {
  CasteUserService,
  PermissionAdmin,
} from '@qbitartifacts/caste-client-ng';
import {
  QSnackBar,
  QTableBase,
  QTableListHeaderOptions,
} from '@qbitartifacts/qbit-kit-ng';
import { IInstance } from 'src/app/entities/instance';
import { MatDialogRef } from '@angular/material/dialog';
import { LogicTablePageBase } from 'src/app/base/logic.table-page.base';
import { TableSettingsService } from 'src/app/services/table-settings.service';

@Component({
  selector: 'app-billing-list',
  templateUrl: './billing-list.component.html',
  styleUrls: ['./billing-list.component.scss'],
})
export class BillingListComponent
  extends LogicTablePageBase<IInstance>
  implements LoadableComponent
{
  public tableId: string = 'table:billing';

  @Input() allowAdd = false;

  public displayedColumns: string[] = [
    'name',
    'amount',
    'status',
    'instance',
    'strategy',
    'options',
  ];
  public addPermission = PermissionAdmin;
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: false,
  };

  constructor(
    public app$: AppService,
    public billing$: BillingService,
    public dialogs$: DialogsService,
    public events: QEventsService,
    public router: Router,
    public route: ActivatedRoute,
    public snackbar: QSnackBar,
    public user$: CasteUserService,
    public settings: TableSettingsService
  ) {
    super(snackbar, events, router, route, settings);
  }

  ngOnInit() {
    this.isInvestor = this.user$.isInvestor();
    this.isTrader = this.user$.isTrader();
    this.isAdmin = this.user$.isAdmin();
    super.ngOnInit();
  }

  /* istanbul ignore next */
  public getSearchObservable(params) {
    return this.billing$.listAll(params);
  }

  /* istanbul ignore next */
  public getRemoveItemObservable(id: string): Observable<any> {
    return this.billing$.remove(id, 'admin');
  }

  /* istanbul ignore next */
  public addBill() {
    this.dialogs$
      .openCreateBill()
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.query);
      });
  }

  /* istanbul ignore next */
  public editBill(item: Bill) {
    this.dialogs$
      .openCreateBill({ isEdit: true, item })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.query);
      });
  }

  public getRemoveItemDialog(id: string): MatDialogRef<any, any> {
    return this.dialogs$.openConfirmDelete();
  }

  public getOwner(): string {
    return this.app$.getOwner();
  }

  /* istanbul ignore next */
  public viewBill(item: Bill) {
    this.dialogs$.openViewBill(item);
  }
}
