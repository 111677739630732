<div>
    <div *ngIf="!addNewUser">
        <div class="flex-row center">
            <h4 class="no-padding no-margin">{{'USERS'|translate}}</h4>

            <button mat-button (click)="addNewUser = true" class="marg-left-15"
                *ngIf="(isAdmin || isManager) && !addNewUser">
                <span class="text-small">
                    + {{'USER'|translate}}
                </span>
            </button>
        </div>
        <p class="text-mutted" style="margin-bottom: 0">
            {{'USERS_DESC'|translate}}
        </p>
    </div>

    <div *ngIf="addNewUser" class="marg-top-15 padd-h-10">
        <mat-divider></mat-divider>
        <div class="flex-row center">
            <input matInput placeholder="{{'NEW_USER'|translate}}" [(ngModel)]="newUserEmail">

            <div class="flex-row" style="margin-right: 0; margin-left: auto">
                <permissions-selector [disabled]="isLoading" (permissionsChange)="setNewUserGrants($event)">
                </permissions-selector>

                <button mat-icon-button (click)="addPermission()" [disabled]="!newUserEmail || isLoading">
                    <mat-icon>check</mat-icon>
                </button>
                <button mat-icon-button (click)="cancelAddUser()" [disabled]="isLoading">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div>

    <mat-list dense role="list" *ngIf="account">
        <mat-list-item *ngFor="let perm of account.permissions" role="listitem" class="hoverable">
            <button *ngIf="isAdmin || isManager" [disabled]="isLoading || (perm.user.id == user$.user.id)"
                (click)="openRemovePermissionConfirmation(perm)" mat-icon-button color="danger" class="marg-right-10">
                <mat-icon>close</mat-icon>
            </button>
            <span class="text-mid">{{perm.user.username}}</span>

            <div style="margin-right: 0; margin-left: auto">
                <permissions-selector [disabled]="isLoading" [permission]="perm"
                    (permissionsChange)="updatePermission(perm, $event)"></permissions-selector>
            </div>
        </mat-list-item>
    </mat-list>
</div>

<div class="padd-v-20" *ngIf="accountDetails">
    <mat-divider></mat-divider>
</div>

<div class="marg-top-15" *ngIf="accountDetails">
    <h4 class="no-padding no-margin">{{'ACCOUNT_SETTINGS'|translate}}</h4>
    <p class="text-mutted" style="margin-bottom: 15px">
        {{'ACCOUNT_INFO_DESC'|translate}}
    </p>

    <div>
        <div class="column">
            <b class="text-mid marg-bottom-10">{{'TELEGRAM_CODE'|translate}}</b>
            <span class="text-mutted">{{accountDetails.reference}}</span>
        </div>
    </div>
</div>