import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateStrategyComponent } from '../create-strategy/create-strategy.component';
import { AccountDetailsService } from 'src/app/services/CRUD/logic-traders/account_details.service';

import {
  CasteUserService,
  CasteUsersService,
} from '@qbitartifacts/caste-client-ng';
import { AuthService } from 'src/app/services/auth.service';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit {
  public isLoading: boolean = false;
  public email: string = '';

  constructor(
    public dialogRef: MatDialogRef<CreateStrategyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public accountDetails: AccountDetailsService,
    public user$: CasteUserService,
    public users$: CasteUsersService,
    public snackbar: QSnackBar,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.email = this.user$.user.username;
  }

  /* istanbul ignore next */
  changePassword() {
    this.isLoading = true;
    this.dialogRef.disableClose = true;

    this.users$.requestPasswordRecovery(this.email).subscribe(
      (res) => {
        this.snackbar.open('RECOVERY_MAIL_SENT');
        this.close();
        this.auth.logout();
      },
      (err) => {
        this.snackbar.open(err.message || 'UKNOWN_ERROR');
        this.isLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  /* istanbul ignore next */
  close(val = false) {
    this.dialogRef.close(val);
  }
}
