import { Component, Input, OnInit } from '@angular/core';
import { InstancesService } from 'src/app/services/CRUD/logic-traders/instances.service';
import { countItems } from 'src/app/pipes/count-items';
import { BillingService } from 'src/app/services/CRUD/logic-traders/billing.service';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
})
export class AccountDetailsComponent implements OnInit {
  @Input() public account: any;
  public instanceCount = 0;
  public billCount = 0;

  constructor(
    public instances$: InstancesService,
    public bills$: BillingService,
    public snackbar$: QSnackBar
  ) {}

  /* istanbul ignore next */
  ngOnInit() {
    if (this.account == null) {
      throw new Error('AccountDetails `account` is required');
    }

    this.getInstancesCount();
    this.getBillCount();
  }

  /* istanbul ignore next */
  public getInstancesCount() {
    this.instances$
      .listAll({ account_id: this.account.id })
      .pipe(countItems)
      .subscribe({
        next: (resp) => {
          this.instanceCount = resp;
        },
      });
  }

  /* istanbul ignore next */
  public getBillCount() {
    this.bills$
      .listAll({ account_id: this.account.id })
      .pipe(countItems)
      .subscribe((resp) => {
        this.billCount = resp;
      });
  }

  /* istanbul ignore next */
  public copied($e) {
    this.snackbar$.open('COPIED_INTERNAL_ID');
  }
}
