import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CrudBaseService } from 'src/app/base/crud.base.service';
import { AuthService } from 'src/app/services/auth.service';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RequestCacheService } from '@qbitartifacts/qbit-kit-ng';

@Injectable({
  providedIn: 'root',
})
export class AccountDetailsService extends CrudBaseService<any> {
  constructor(
    http: HttpClient,
    auth: AuthService,
    private cache: RequestCacheService
  ) {
    super(
      {
        endpoint: 'account_details',
      },
      http,
      auth
    );
  }

  /* istanbul ignore next */
  public listAll(params?: { [key: string]: string }, userType?: string) {
    const path: string = this.createPathFromParts(
      userType,
      `/${this.opts.endpoint}`
    );

    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => searchParams.append(key, params[key]));

    const urlWithParams = `${path}?${searchParams.toString()}`;
    const cached = this.cache.get(urlWithParams);
    return cached
      ? of(cached.data)
      : this.get(path, params).pipe(
          tap((event) => {
            if (event instanceof HttpResponse || event['data']) {
              this.cache.set(urlWithParams, event);
            }
          })
        );
  }
}
