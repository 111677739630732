import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CasteAuthModule,
  CASTE_AUTH_CONFIG,
} from '@qbitartifacts/caste-client-ng';
import {
  QBIT_SIDEMENU_ITEMS,
  QSidemenuModule,
} from '@qbitartifacts/qbit-kit-ng';
import { AvatarModule } from 'ngx-avatar';
import { QEventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ItemPageBaseComponent } from './base/item.page.base';
import { PageBaseComponent } from './base/page.base';
import { ComponentsModule } from './components/components.module';
import { qbitLocalesProvider } from './config/locales';
import { SIDEMENU_ITEMS } from './config/sidemenu.items';
import { DialogsModule } from './dialogs/dialogs.module';
import { AuthedGuard } from './guards/authed.guard';
import { PublicGuard } from './guards/public.guard';
import { CustomMatPaginatorIntl } from './md-i18n';
import { PagesModule } from './pages/pages.module';
import { AccountDetailsService } from './services/CRUD/logic-traders/account_details.service';
import { ApiKeysService } from './services/CRUD/logic-traders/api-keys.service';
import { BillingService } from './services/CRUD/logic-traders/billing.service';
import { ConfigurationService } from './services/CRUD/logic-traders/configuration.service';
import { CurrenciesService } from './services/CRUD/logic-traders/currencies.service';
import { InstancesHistoryService } from './services/CRUD/logic-traders/instances-history.service';
import { InstancesService } from './services/CRUD/logic-traders/instances.service';
import { NotesService } from './services/CRUD/logic-traders/notes.service';
import { OrdersService } from './services/CRUD/logic-traders/orders.service';
import { StrategiesService } from './services/CRUD/logic-traders/strategies.service';
import { DialogsService } from './services/dialogs.service';
import { HttpAuthInterceptor } from './services/http-interceptor.service';
import { SessionCheckerService } from './services/session-checker.service';
import { StatisticsService } from './services/statistics.service';
import { TableSettingsService } from './services/table-settings.service';
import { SharedModule } from './shared.module';
import { TranslationsModule } from './translations.module';

registerLocaleData(localeEn);
registerLocaleData(localeEs);

const modules = [
  SharedModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  ReactiveFormsModule,
  HttpClientModule,
  CasteAuthModule,
  ComponentsModule,
  PagesModule,
  TranslationsModule,
  DialogsModule,
  AvatarModule.forRoot(),
  CommonModule,
  QSidemenuModule,
];

const qbitAuthConfigProvider = {
  provide: CASTE_AUTH_CONFIG,
  useValue: {
    realm: environment.realm,
    url: environment.casteUrl,
    baseHeaders: {
      accept: 'application/ld+json',
    },
  },
};

@NgModule({
  declarations: [
    AppComponent,
    PageBaseComponent as any,
    ItemPageBaseComponent as any,
  ],
  imports: modules,
  providers: [
    PublicGuard,
    AuthedGuard,
    QEventsService,
    SessionCheckerService,
    DialogsService,
    StrategiesService,
    InstancesService,
    StatisticsService,
    BillingService,
    CurrenciesService,
    InstancesHistoryService,
    OrdersService,
    ApiKeysService,
    AccountDetailsService,
    TableSettingsService,
    ConfigurationService,
    NotesService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    {
      provide: QBIT_SIDEMENU_ITEMS,
      useValue: SIDEMENU_ITEMS,
    },
    qbitAuthConfigProvider,
    qbitLocalesProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
