<mat-card class="no-padding">
    <qbit-table-header title="CURRENCIES" [options]="tableOptions" [isLoading]="isLoading">
        <qbit-add-button (add)="addCurrency()" *appPermissions="addPermission"></qbit-add-button>

        <table-settings-button (settingsChanged)="settingsChanged()" [tableId]="tableId"></table-settings-button>
        <button mat-icon-button (click)="onSearch(searchParams)">
            <mat-icon>refresh</mat-icon>
        </button>
    </qbit-table-header>

    <qbit-table-filters *ngIf="searchMapping && searchMapping.length" [searchMapping]="searchMapping"
        (filtersChanged)="searchParamsChanged($event)" [filters]="searchParams"></qbit-table-filters>

    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChanged($event)"
        [matSortDirection]="sortDir" [matSortActive]="sortId" [trackBy]="trackById">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'NAME'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'SYMBOL'|translate}} </th>
            <td mat-cell *matCellDef="let row"> <b>{{row.symbol}}</b> </td>
        </ng-container>

        <ng-container matColumnDef="updated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'UPDATED'|translate}} </th>
            <td mat-cell *matCellDef="let row" title="{{row.updated_at}}"> <i>{{row.updated_at | date}}</i></td>
        </ng-container>

        <ng-container matColumnDef="options" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Item options menu"
                    (click)="$event.preventDefault(); $event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="col-error" (click)="openRemoveConfirmation(row.id)">
                        <mat-icon class="mat-icon material-icons mat-icon-small">delete</mat-icon>
                        <span>{{'REMOVE'|translate}}</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>


        <tr class="tr-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <qbit-list-loading *ngIf="isLoading && !hasData"></qbit-list-loading>

    <div class="list-no-items" *ngIf="!hasData && !isLoading">
        <p class="text-light">{{'NO_ITEMS'|translate}}</p>
    </div>

    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"></mat-paginator>
</mat-card>