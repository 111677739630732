import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QSnackBar } from '@qbitartifacts/qbit-kit-ng';
import { Instance } from 'src/app/entities/instance';
import { InstancesService } from 'src/app/services/CRUD/logic-traders/instances.service';


@Component({
  templateUrl: './change-control.component.html',
  selector: 'change-control',
})
export class ChangeControlComponent {
  @Input() instance: Instance;
  @Output() changed: EventEmitter<string> = new EventEmitter<string>();

  public controls = Instance.CONTROLS;
  public control = '';
  public disabled = false;

  constructor(
    public instances$: InstancesService,
    public snackbar: QSnackBar
  ) {}

  ngOnInit() {
    this.control = this.instance
      ? this.instance.control
      : Instance.CONTROL_MANUAL;
  }

  /* istanbul ignore next */
  ngOnChanges() {
    this.ngOnInit();
  }

  /* istanbul ignore next */
  selectionChange($event, control) {
    if (control != this.control) {
      this.editInstance(control);
    }
  }

  /* istanbul ignore next */
  private editInstance(control) {
    this.disabled = true;
    this.instances$.update(this.instance.id, { control }).subscribe(
      (resp) => {
        this.control = control;
        this.snackbar.open('EDITED_ITEM_OK');
        this.disabled = false;
        this.changed.emit(this.control);
      },
      (err) => {
        this.snackbar.open(err.message || err.detail);
        this.disabled = false;
      }
    );
  }
}
