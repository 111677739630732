<mat-card [class.mat-elevation-z0]="!hasShadow" *ngIf="!stacked">
    <div class="aggregate {{size}} flex-row space-between" [ngClass]="{'compact': compact, 'default': !compact}">
        <div class="flex-row center">
            <mat-icon *ngIf="icon" class="mat-icon material-icons mat-icon-small marg-right-10 col-{{iconColor}}">
                {{icon}}
            </mat-icon>
            <span class="aggregate-title col-{{titleColor}} text-mid">{{title|translate}}</span>
        </div>
        <h4 *ngIf="showDecimals" class="aggregate-value col-{{valueColor}}">
            <b>{{symbol}}</b> {{(value || 0) | number: decimalPipeOptions}}</h4>
        <h4 *ngIf="!showDecimals" class="aggregate-value col-{{valueColor}}">
            <b>{{symbol}}</b> {{(value || 0) | number}}</h4>
    </div>
</mat-card>

<mat-card [class.mat-elevation-z0]="!hasShadow" *ngIf="stacked">
    <div class="aggregate {{size}} stacked" [ngClass]="{'compact': compact, 'default': !compact}">
        <div class="flex-row center">
            <mat-icon *ngIf="icon" class="mat-icon material-icons mat-icon-small marg-right-10 col-{{iconColor}}">
                {{icon}}
            </mat-icon>
            <span class="aggregate-title col-{{titleColor}} text-mid">{{title|translate}}</span>
        </div>
        <h4 *ngIf="showDecimals" class="aggregate-value col-{{valueColor}}">
            <b>{{symbol}}</b> {{(value || 0) | number: decimalPipeOptions}}</h4>
        <h4 *ngIf="!showDecimals" class="aggregate-value col-{{valueColor}}">
            <b>{{symbol}}</b> {{(value || 0) | number}}</h4>
    </div>
</mat-card>