export interface IConfiguration {
  id?: string;
  iri?: string;
  name: string;
  graphics_admin_dashboard_url: string;
  graphics_admin_instance_url: string;
  graphics_admin_kraken_instance_url: string;
  graphics_admin_bitmex_instance_url: string;

  graphics_user_account_url: string;
  graphics_user_instance_url: string;
  graphics_user_kraken_instance_url: string;
  graphics_user_bitmex_instance_url: string;

  enabled: boolean;
  created_at?: string;
  updated_at?: string;
}

/* istanbul ignore next */
export class Configuration implements IConfiguration {
  id: string;
  iri: string;
  name: string;
  graphics_admin_dashboard_url: string;
  graphics_admin_instance_url: string;
  graphics_user_account_url: string;
  graphics_user_instance_url: string;
  graphics_admin_kraken_instance_url: string;
  graphics_admin_bitmex_instance_url: string;
  graphics_user_kraken_instance_url: string;
  graphics_user_bitmex_instance_url: string;
  enabled: boolean;
  created_at: string;
  updated_at: string;

  getConfigurationForInstance(isAdmin: boolean, exchange: string) {
    if (isAdmin) return this.getConfigurationForAdminInstance(exchange);
    return this.getConfigurationForUserInstance(exchange);
  }

  getConfigurationForAdminInstance(exchange: string) {
    switch (exchange) {
      case 'kraken':
        return this.graphics_admin_kraken_instance_url;
      case 'bitmex':
        return this.graphics_admin_bitmex_instance_url;
    }

    return this.graphics_admin_instance_url;
  }

  getConfigurationForUserInstance(exchange: string) {
    switch (exchange) {
      case 'kraken':
        return this.graphics_user_kraken_instance_url;
      case 'bitmex':
        return this.graphics_user_bitmex_instance_url;
    }

    return this.graphics_user_instance_url;
  }

  /* istanbul ignore next */
  static fromJson(obj: IConfiguration | Configuration): Configuration {
    const config = new Configuration();

    config.id = obj.id;
    config.name = obj.name;
    config.enabled = obj.enabled;
    config.updated_at = obj.updated_at;
    config.created_at = obj.created_at;
    config.iri = obj['@id'];
    config.graphics_admin_dashboard_url = obj.graphics_admin_dashboard_url;
    config.graphics_admin_instance_url = obj.graphics_admin_instance_url;
    config.graphics_admin_kraken_instance_url =
      obj.graphics_admin_kraken_instance_url;
    config.graphics_admin_bitmex_instance_url =
      obj.graphics_admin_bitmex_instance_url;

    config.graphics_user_account_url = obj.graphics_user_account_url;
    config.graphics_user_instance_url = obj.graphics_user_instance_url;
    config.graphics_user_kraken_instance_url =
      obj.graphics_user_kraken_instance_url;
    config.graphics_user_bitmex_instance_url =
      obj.graphics_user_bitmex_instance_url;

    return config;
  }
}
