import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

import { LoadableComponent } from 'src/app/base/loadable.page';
import { DialogsService } from 'src/app/services/dialogs.service';

import { QEventsService } from 'src/app/services/events.service';
import { AppService } from 'src/app/services/app.service';
import {
  CasteUserService,
  PermissionAdmin,
} from '@qbitartifacts/caste-client-ng';
import { QSnackBar, QTableListHeaderOptions } from '@qbitartifacts/qbit-kit-ng';
import { IInstance } from 'src/app/entities/instance';
import { MatDialogRef } from '@angular/material/dialog';
import { LogicTablePageBase } from 'src/app/base/logic.table-page.base';
import { TableSettingsService } from 'src/app/services/table-settings.service';
import { tap } from 'rxjs/operators';
import { InstancesHistoryService } from 'src/app/services/CRUD/logic-traders/instances-history.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-instance-trades-list',
  templateUrl: './instance-trades-list.component.html',
})
export class InstanceTradeListComponent
  extends LogicTablePageBase<IInstance>
  implements LoadableComponent
{
  public tableId: string = 'table:instance:trades';

  @Input() allowAdd = false;
  @Input() instanceId: any;

  public displayedColumns: string[] = [
    'id',
    'amount',
    'cost',
    'price',
    'symbol',
    'type',
    'side',
    'timestamp',
  ];
  public addPermission = PermissionAdmin;
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: true,
  };

  constructor(
    public app$: AppService,
    public instances$: InstancesHistoryService,
    public dialogs$: DialogsService,
    public events: QEventsService,
    public router: Router,
    public route: ActivatedRoute,
    public snackbar: QSnackBar,
    public user$: CasteUserService,
    public settings: TableSettingsService
  ) {
    super(snackbar, events, router, route, settings);
  }

  ngOnInit() {
    this.isInvestor = this.user$.isInvestor();
    this.isTrader = this.user$.isTrader();
    this.isAdmin = this.user$.isAdmin();
    super.ngOnInit();
  }

  /* istanbul ignore next */
  public getSearchObservable(params) {
    return this.instances$.getTradeHistory(this.instanceId).pipe(
      tap((data) => {
        console.log('hiostory', data);
      })
    );
  }

  /* istanbul ignore next */
  public getRemoveItemObservable(id: string): Observable<any> {
    return of(null);
  }

  public getRemoveItemDialog(id: string): MatDialogRef<any, any> {
    return this.dialogs$.openConfirmDelete();
  }

  public getOwner(): string {
    return this.app$.getOwner();
  }

  public processSearchMapping(resp) {
    if (!resp.search) {
      return [];
    }

    return super.processSearchMapping(resp);
  }

}
