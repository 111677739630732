export interface Bill {
  id?: string;
  status?: string;
  name: string;
  amount: number;
  currency: string;
  account_id: string;
  instance?: any;
  applied_fee?: number;

  period_start?: string;
  period_end?: string;

  created_at?: string;
  updated_at?: string;
}

export const BILL_STATUSES = ['draft', 'unpaid', 'paid'];