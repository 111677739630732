import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { PageBaseComponent } from './page.base';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { QEventsService } from 'src/app/services/events.service';
import { CasteUserService } from '@qbitartifacts/caste-client-ng';
import { QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';

@Component({ template: '' })
export abstract class ItemPageBaseComponent extends PageBaseComponent {
  public itemId = null;
  public itemData: any = {};

  constructor(
    public title$: Title,
    public translate$: TranslateService,
    public route: ActivatedRoute,
    public events: QEventsService,
    public user$: CasteUserService,
    public breadcrumbs$: QBreadcrumbsService
  ) {
    super(title$, translate$, events, breadcrumbs$, user$);
    this.route.params.subscribe((params) => {
      this.itemId = params.id;
    });
  }
}
