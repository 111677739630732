import { Component, OnInit, AfterContentInit } from '@angular/core';
import { PageBaseComponent } from 'src/app/base/page.base';
import { Title } from '@angular/platform-browser';
import { AuthedGuard } from 'src/app/guards/authed.guard';
import { TranslateService } from '@ngx-translate/core';
import { QEventsService } from 'src/app/services/events.service';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute } from '@angular/router';

import { CasteUserService } from '@qbitartifacts/caste-client-ng';
import { QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';

@Component({
  selector: 'app-instances',
  templateUrl: './instances.component.html',
  styleUrls: ['./instances.component.scss'],
})
export class InstancesComponent
  extends PageBaseComponent
  implements AfterContentInit {
  static guards: any[] = [AuthedGuard];
  public title = 'INSTANCES';

  constructor(
    title: Title,
    public translate$: TranslateService,
    public user$: CasteUserService,
    public events: QEventsService,
    public app$: AppService,
    public route: ActivatedRoute,
    public breadcrumbs$: QBreadcrumbsService
  ) {
    super(title, translate$, events, breadcrumbs$, user$);
  }

  ngOnInit() {
    this.updateBreadcrumb(this.user$.selectedAccount.name);
  }

  ngAfterContentInit() {
    super.ngAfterContentInit();
  }
}
