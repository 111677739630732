import { EventEmitter, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCALES } from '../config/consts';
import { QEventsService } from './events.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  BaseService,
  CasteUserService,
  Account,
  DEFAULT_CONFIG,
} from '@qbitartifacts/caste-client-ng';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AppService extends BaseService {
  public lang = LOCALES.es;
  public publicRoute = false;
  public darkThemeEnabled = true;
  public impersonateActive = false;
  public impersonatedAccount: Account;
  public hideWrapper = false;
  public themeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public translate$: TranslateService,
    public user$: CasteUserService,
    public events: QEventsService,
    public router: Router,
    public http: HttpClient,
    private auth: AuthService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(http, {
      ...DEFAULT_CONFIG,
      url: environment.url,
    });
    this.lang = localStorage.getItem(LOCALES.storageKey) || LOCALES.default;
    const darkThemeEnabled = localStorage.getItem('DARK_THEME')
      ? localStorage.getItem('DARK_THEME') === 'true'
      : true;

    this.setDarkThemeEnabled(darkThemeEnabled);
  }

  /* istanbul ignore next */
  protected getToken() {
    return this.auth.session && this.auth.session.token;
  }

  public addClassToBody(className: string) {
    this.document.body.classList.add(className);
  }

  public removeClassFromBody(className: string) {
    this.document.body.classList.remove(className);
  }

  public toggleClassFromBody(className: string) {
    this.document.body.classList.toggle(className);
  }

  /* istanbul ignore next */
  public setDarkThemeEnabled(enabled) {
    this.darkThemeEnabled = enabled;
    localStorage.setItem('DARK_THEME', enabled);

    if (this.darkThemeEnabled) this.addClassToBody('dark-theme');
    else this.removeClassFromBody('dark-theme');

    this.themeChanged.emit(this.darkThemeEnabled);
  }

  public setUpLang() {
    this.translate$.setDefaultLang(LOCALES.default);
    this.translate$.use(this.lang);
  }

  public hasSavedImpersonation(): boolean {
    return localStorage.getItem('impersonateActive') === 'true';
  }

  /* istanbul ignore next */
  public recoverImpersonation() {
    if (this.hasSavedImpersonation()) {
      const impersonatedAccount = localStorage.getItem('impersonatedAccount');
      this.impersonatedAccount = Account.fromJson(
        JSON.parse(impersonatedAccount)
      );
      this.impersonateActive = true;
    }
  }

  /* istanbul ignore next */
  public impersonateAccount(account: Account) {
    this.impersonatedAccount = account;
    this.impersonateActive = true;
    localStorage.setItem('impersonateActive', 'true');
    localStorage.setItem('impersonatedAccount', JSON.stringify(account));
    this.router.navigate(['/dashboard']);
  }

  /* istanbul ignore next */
  removeImpersonation() {
    this.impersonatedAccount = null;
    this.impersonateActive = false;
    localStorage.removeItem('impersonateActive');
    localStorage.removeItem('impersonatedAccount');
  }

  /* istanbul ignore next */
  get isImpersonating() {
    return this.impersonateActive && this.impersonatedAccount;
  }

  /* istanbul ignore next */
  public getImpersonationId() {
    if (this.isImpersonating) {
      return this.impersonatedAccount.id;
    }
  }

  /* istanbul ignore next */
  public getImpersonationName() {
    if (this.isImpersonating) {
      return this.impersonatedAccount.name;
    }
  }

  /* istanbul ignore next */
  public getOwner() {
    if (this.isImpersonating) {
      return this.getImpersonationId();
    } else {
      return (
        localStorage.getItem('selected_account') || this.user$.getAccountId()
      );
    }
  }

  /* istanbul ignore next */
  public getOwnerFromSelectedAccount() {
    return this.user$.getAccountId();
  }

  // Public calls
  public getApiVersion() {
    return this.get('/public/versions/current');
  }
}
