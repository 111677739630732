import { LoadableComponent } from 'src/app/base/loadable.page';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { DialogsService } from 'src/app/services/dialogs.service';

import { throwError } from 'rxjs';
import { QEventsService } from 'src/app/services/events.service';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IApiKey } from 'src/app/entities/api-key.ent';
import { ApiKeysService } from 'src/app/services/CRUD/logic-traders/api-keys.service';
import {
  CasteUserService,
  PermissionAdmin,
} from '@qbitartifacts/caste-client-ng';
import {
  QSnackBar,
  QTableBase,
  QTableListHeaderOptions,
} from '@qbitartifacts/qbit-kit-ng';
import { MatDialogRef } from '@angular/material/dialog';
import { LogicTablePageBase } from 'src/app/base/logic.table-page.base';
import { TableSettingsService } from 'src/app/services/table-settings.service';

@Component({
  selector: 'app-api-keys-list',
  templateUrl: './api-keys-list.component.html',
  styleUrls: ['./api-keys-list.component.scss'],
})
export class ApiKeysListComponent
  extends LogicTablePageBase<IApiKey>
  implements LoadableComponent
{
  public tableId: string = 'table:api-keys';
  public displayedColumns: string[] = ['name', 'updated', 'options'];

  public addPermission = PermissionAdmin;
  public tableOptions: QTableListHeaderOptions = {
    showLoading: true,
    showBreadcrumbs: false,
  };

  @Input() allowAdd = false;

  constructor(
    public dialogs: DialogsService,
    public snackbar: QSnackBar,
    public apiKeys$: ApiKeysService,
    public user$: CasteUserService,
    public app$: AppService,
    public events: QEventsService,
    public router: Router,
    public route: ActivatedRoute,
    public settings: TableSettingsService
  ) {
    super(snackbar, events, router, route, settings);
  }

  public getSearchObservable(params) {
    return this.apiKeys$.listAll(params, 'admin');
  }

  /* istanbul ignore next */
  public getRemoveItemObservable(id: string): Observable<any> {
    return throwError('This can not be removed');
  }

  public getRemoveItemDialog(id: string): MatDialogRef<any, any> {
    throw new Error('Method not implemented.');
  }

  public getOwner(): string {
    return this.app$.getOwner();
  }

  /* istanbul ignore next */
  public addKeys() {
    this.dialogs
      .openCreateApiKeys()
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.onSearch(this.query);
      });
  }
}
