<ng-template #fetching>
    {{'FETCHING'|translate}}...
</ng-template>

<div class="bootstrap-wrapper">
    <div class="row">
        <div class="col-12" style="margin-bottom: 10px;">
            <mat-card>
                <div class="flex-row" style="align-items: center; justify-content: space-between">
                    <mat-card class="no-padding">
                        <app-instance-actions *ngIf="itemData && itemData.control"
                            (loadStart)="isLoadingInstance = true" (loadEnd)="isLoadingInstance = false"
                            (onAction)="onAction($event)" [instance]="itemData" [isInvestor]="isInvestor"
                            [isAdmin]="isAdmin" [isTrader]="isTrader" [isPro]="isPro">
                        </app-instance-actions>
                    </mat-card>

                    <div class="flex-row center padd-h-10">
                        <mat-chip-list>
                            <mat-chip class="mat-chip mat-primary mat-standard-chip"
                                matTooltip="{{'ACCOUNT'|translate}}">
                                <div *ngIf="account | asyncp as account; else fetching">
                                    <button mat-button [matMenuTriggerFor]="accountDetails"
                                        aria-label="This menu shows detail about the owner of this instance">
                                        <div class="flex-row center h v">
                                            <ngx-avatar size="20" [name]="account ? account.name: ''"></ngx-avatar>
                                            <p class="marg-left-10 no-margin text-mid">{{account ? account.name: ''}}
                                            </p>
                                        </div>
                                    </button>
                                    <mat-menu #accountDetails="matMenu">
                                        <app-account-details  [account]="account"></app-account-details>
                                    </mat-menu>
                                </div>
                            </mat-chip>

                            <mat-chip *ngIf="itemData.currency" class="mat-chip mat-primary mat-standard-chip"
                                matTooltip="{{'CURRENCY'|translate}}">
                                <span>{{itemData.currency.symbol}}</span>
                                <p class="marg-left-10 no-margin text-mid">{{itemData.currency.acronym}}</p>
                            </mat-chip>

                            <mat-chip class="mat-chip mat-primary mat-standard-chip"
                                matTooltip="{{'STRATEGY'|translate}}">
                                <mat-icon class="mat-icon-small">alt_route</mat-icon>
                                <p class="marg-left-10 no-margin text-mid"
                                    *ngIf="itemData.strategy | asyncp as strategy; else fetching">{{strategy.name}}</p>
                            </mat-chip>

                            <mat-chip class="mat-chip mat-primary mat-standard-chip"
                                matTooltip="{{'EXCHANGE'|translate}}">
                                <ng-container *ngIf="itemData.strategy | asyncp as strategy; else fetching">
                                    <div *ngIf="strategy.exchange | asyncp as exchange; else fetching"
                                        class="flex-row center h v">
                                        <img width="24" src="../../../assets/icons/{{strategy.exchange}}.png">
                                        <p class="marg-left-10 no-margin text-mid">{{strategy.exchange}}</p>
                                    </div>
                                </ng-container>
                            </mat-chip>

                            <mat-chip class="mat-chip mat-primary mat-standard-chip" *ngIf="isAdmin || isTrader"
                                matTooltip="{{'CONTROL'|translate}}">
                                <change-control (changed)="onChange()" *ngIf="itemData && itemData.control"
                                    [instance]="itemData"></change-control>
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div *ngIf="!isStatusNew; else new_status_loading">
        <div *ngIf="hasGrafanaUrl;" style="height: 425px;margin-bottom: 10px;">
            <app-grafana-iframe [url]="grafanaUrl">
            </app-grafana-iframe>
        </div>

        <mat-card *ngIf="!hasGrafanaUrl;" style="height: 425px;margin-bottom: 10px;">
            <div class="centered h v" style="height: 100%">
                <mat-icon>info</mat-icon>
                {{'NO_DATA_YET'|translate}}
            </div>
        </mat-card>

        <div class="row" *ngIf="isTrader || isAdmin">
            <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: 10px;">
                <mat-card class="no-padding" style="height: auto; overflow-y: auto;">
                    <app-positions-list *ngIf="!showAddOrder && positions.length" #positionsList [instance]="itemId"
                        (listChanged)="ordersChanged()" [currency]="itemData.currency" [positions]="positions"
                        [disableActions]="itemData.control === 'automatic'">
                    </app-positions-list>
                </mat-card>

                <mat-card class="no-padding marg-top-10" style="height: auto; overflow-y: auto;">
                    <app-orders-list *ngIf="!showAddOrder" #ordersList (listChanged)="ordersChanged()"
                        [instance]="itemId" [currency]="itemData.currency" (toggleOrderForm)="toggleOrderForm($event)"
                        [showAddOrder]="showAddOrder" [disableActions]="itemData.control === 'automatic'"
                        [orders]="orders">
                    </app-orders-list>

                    <app-create-order-form *ngIf="showAddOrder" [instance]="itemData" (closed)="closeAddOrder()">
                    </app-create-order-form>
                </mat-card>
            </div>
        </div>

        <div class="row" *ngIf="isTrader || isAdmin">
            <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: 10px;">
                <mat-card class="tradingview-widget-container no-padding"
                    style="height: 450px; width: 100%; max-height: 450px; position: relative;">
                    <div id="tradingview_491c3" style="height: 450px; width: 100%; position: relative;"></div>
                </mat-card>
            </div>
        </div>
    </div>

    <ng-template #new_status_loading>
        <mat-card class="centered" style="height: 350px">
            <mat-progress-spinner [diameter]="65" color="primary" mode="indeterminate"></mat-progress-spinner>
            <h3>{{'INSTANCE_IS_SETTING_UP' | translate}}</h3>
            <p>{{'INSTANCE_IS_SETTING_UP_DESC' | translate}}</p>
        </mat-card>
    </ng-template>
</div>