import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {
  CasteUserService,
  PermissionAdmin,
} from '@qbitartifacts/caste-client-ng';
import { createTimer, QBreadcrumbsService } from '@qbitartifacts/qbit-kit-ng';
import { Subject } from 'rxjs/internal/Subject';
import { PageBaseComponent } from 'src/app/base/page.base';
import { localeMap } from 'src/app/config/locales';
import { AuthedGuard } from 'src/app/guards/authed.guard';
import { mapStatistics } from 'src/app/pipes/map-statistics';
import { AppService } from 'src/app/services/app.service';
import { ConfigurationService } from 'src/app/services/CRUD/logic-traders/configuration.service';
import { CurrenciesService } from 'src/app/services/CRUD/logic-traders/currencies.service';
import { StrategiesService } from 'src/app/services/CRUD/logic-traders/strategies.service';
import { QEventsService } from 'src/app/services/events.service';
import { StatisticsService } from 'src/app/services/statistics.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class AdminDashboardComponent extends PageBaseComponent {
  static guards: any[] = [AuthedGuard];
  public title = 'DASHBOARD';
  public permission = PermissionAdmin;
  public stats: any = {};
  private stopPolling = new Subject();
  public grafanaChartUrl: string;
  public hasGrafanaUrl = false;

  constructor(
    title: Title,
    public translate$: TranslateService,
    public strategies: StrategiesService,
    public app$: AppService,
    public user$: CasteUserService,
    public currencies$: CurrenciesService,
    public events: QEventsService,
    public breadcrumbs$: QBreadcrumbsService,
    public configuration$: ConfigurationService,
    public statistics$: StatisticsService
  ) {
    super(title, translate$, events, breadcrumbs$, user$);
  }

  get grafanaUrl() {
    const searchParams = new URLSearchParams();
    searchParams.append('var-locale', localeMap[this.app$.lang]);

    const url =
      this.configuration$.currentConfiguration.graphics_admin_dashboard_url;

    const fullURL = url + `&${searchParams.toString()}`;
    return fullURL;
  }

  /* istanbul ignore next */
  public onCurrencyChange() {
    this.getStats().subscribe({
      next: (resp) => this.onGotStats(resp),
    });
  }

  /* istanbul ignore next */
  public onGotStats(resp) {
    this.stats = resp;

    const isAdmin = this.user$.isAdmin();

    if (!isAdmin && this.stats.graphics_url != this.grafanaChartUrl) {
      this.grafanaChartUrl = this.stats.graphics_url;
    }

    if (isAdmin && this.stats.admin_graphics_url != this.grafanaChartUrl) {
      this.grafanaChartUrl = this.stats.admin_graphics_url;
    }

    this.hasGrafanaUrl = !!this.grafanaChartUrl;
  }

  /* istanbul ignore next */
  ngOnInit() {
    this.getStatsData();
  }

  ngOnDestroy() {
    this.stopPolling.next();
  }

  /* istanbul ignore next */
  public getStatsData() {
    createTimer(this.getStats.bind(this), this.stopPolling).subscribe((resp) =>
      this.onGotStats(resp)
    );
  }

  /* istanbul ignore next */
  public getStats() {
    return this.statistics$.getAdminStatistics().pipe(mapStatistics);
  }
}
