<mat-form-field>
    <mat-label>Choose an instance</mat-label>
    <mat-select [ngModel]="instance" (ngModelChange)="selectInstance($event)">
        <mat-select-trigger>
            {{instance ? instance.name : 'None'}}
        </mat-select-trigger>

        <mat-option *ngFor="let instance of instances" [value]="instance" style="height: auto!important">
            <div class="flex-row column padd-v-10">
                <span class="marg-bottom-10 text-small">{{instance.name}}</span>
                <span class="text-mutted">{{instance.description}}</span>
            </div>
        </mat-option>
    </mat-select>
</mat-form-field>