<mat-form-field>
    <mat-label>
        {{'CURRENCY'|translate}}
    </mat-label>
    <mat-select [formControl]="control" [required]="required">
        <mat-select-trigger>
            <span *ngIf="control.value">({{control.value.symbol}}) {{control.value.name}}</span>
            <span *ngIf="!control.value">{{'CURRENCY'|translate}}</span>
        </mat-select-trigger>

        <mat-option *ngIf="isNullable" [value]="null">
            {{'NONE' | translate}}
        </mat-option>

        <mat-option *ngFor="let currency of currencies$.currencies" [value]="currency" style="height: auto!important">
            <div class="flex-row column padd-v-10">
                <span class="marg-bottom-10 text-small">({{currency.symbol}}) {{currency.name}}</span>
            </div>
        </mat-option>
    </mat-select>

    <mat-error *ngIf="control.invalid && (control.dirty) && control.errors.required">
        {{'CURRENCY_REQUIRED' | translate}}
    </mat-error>
</mat-form-field>