import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to check that two fields match
export function bitmexAmountValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const valueValid = value % 100 == 0;
    console.log('value', value);
    console.log('valueValid', valueValid);

    return !valueValid ? { bitmexAmount: true } : null;
  };
}
