<qbit-dialog-header title="{{'BILL'|translate}} ({{bill.name}})" (close)="close()">
    <span class="marg-left-15 status-{{bill.status}}">{{bill.status}}</span>
</qbit-dialog-header>

<div class="padd-h-15 padd-v-15">
    <div class="column marg-bottom-15" *ngIf="bill.instance">
        <b class="text-subtitle marg-bottom-10">{{'INSTANCE'|translate}}: </b>
        <a routerLink="/instances/{{bill.instance.id}}" class="text-mid">{{bill.instance.name}}</a>
    </div>

    <div class="column marg-bottom-15">
        <b class="text-subtitle marg-bottom-10">{{'AMOUNT'|translate}}: </b>
        <span class="text-mid">
            <app-money-value [value]="bill.amount" [onlyDecimals]="true" [localTransform]="false"></app-money-value>
        </span>
    </div>

    <div class="column marg-bottom-15">
        <b class="text-subtitle marg-bottom-10">{{'FEE'|translate}}: </b>
        <code class="text-mid">{{bill.applied_fee | number}} %</code>
    </div>

    <div class="column marg-bottom-15">
        <b class="text-subtitle marg-bottom-10">{{'DATE_RANGE'|translate}}: </b>
        <div title="{{bill.period_start}} to {{bill.period_end}}" class="text-mid">
            <i>{{bill.period_start}}</i> - <i>{{bill.period_end}}</i>
        </div>
    </div>
</div>