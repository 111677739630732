import { Component, Input } from '@angular/core';
import { CurrenciesService } from 'src/app/services/CRUD/logic-traders/currencies.service';

@Component({
  selector: 'app-aggregate-grid',
  templateUrl: './aggregate-grid.component.html',
})
export class AggregateGridComponent {
  @Input() balance = 0;
  @Input() funding = 0;
  @Input() totalEarnings = 0;

  @Input() yearProfit = 0;
  @Input() yearProfitPercent = 0;

  @Input() monthProfit = 0;
  @Input() monthProfitPercent = 0;

  @Input() dayProfit = 0;
  @Input() dayProfitPercent = 0;

  selectedCurrency;

  constructor(public currencies$: CurrenciesService) {
    this.selectedCurrency = currencies$.selectedCurrency ;
  }
}
