import { Component, Input, OnInit } from '@angular/core';
import { Account } from '@qbitartifacts/caste-client-ng';
import { DeleteDialogStatus } from '@qbitartifacts/qbit-kit-ng';
import { AppService } from 'src/app/services/app.service';
import { DialogsService } from 'src/app/services/dialogs.service';

@Component({
  selector: 'app-impersonate-account',
  templateUrl: './impersonate-account.component.html',
})
export class ImpersonationAccountComponent {
  @Input() account: Account;
  constructor(public dialogs$: DialogsService, public app$: AppService) {}

  ngOnInit() {
    if (!this.account) {
      throw new Error('ImpersonationAccountComponent `account` is required');
    }
  }

  /* istanbul ignore next */
  public impersonateAccount() {
    this.dialogs$
      .openConfirmation({
        title: 'IMPERSONATE',
        subtitle: 'IMPERSONATE_CONFIRM',
        description: 'IMPERSONATE_CONFIRM_DESC',
        confirmButtonText: 'IMPERSONATE',
      })
      .afterClosed()
      .subscribe((resp) => {
        if (resp === DeleteDialogStatus.DELETE) {
          this.app$.impersonateAccount(Account.fromJson(this.account));
        }
      });
  }
}
