import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { AppService } from '../services/app.service';
import { CasteUserService } from '@qbitartifacts/caste-client-ng';

@Injectable()
export class AuthedGuard implements CanActivate {
  constructor(
    private user$: CasteUserService,
    public auth$: AuthService,
    public app$: AppService,
    public router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const userIsPresent = this.user$.hasUser();
    const hasSession = this.auth$.hasSession();
    const sessionActive = this.auth$.sessionActive();

    if (hasSession && sessionActive && userIsPresent) {
      this.app$.hideWrapper = false;
      return true;
    } else {
      this.auth$.redirectUrl = state.url;
      return this.router.parseUrl('login');
    }
  }
}
