import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteDialogStatus } from '@qbitartifacts/qbit-kit-ng';

export interface ConfirmationDialogData {
  title: string;
  subtitle: string;
  description: string;
  confirmButtonText: string;
}

@Component({
  selector: 'show-json',
  templateUrl: './show-json.component.html',
})
export class ShowJsonDialog {
  public title = 'JSON_PREVIEW';

  constructor(
    public dialogRef: MatDialogRef<ShowJsonDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { json: object, title?: string }
  ) {
    this.setIfPresent(data, 'title');
  }

  /* istanbul ignore next */
  private setIfPresent(data: any, key: string) {
    if (data[key] !== undefined) {
      this[key] = data[key];
    }
  }

  close() {
    this.dialogRef.close();
  }

  proceed() {
    this.close();
  }
}
